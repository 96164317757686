import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';

import { VerifyContact, Next, Breadcrumb, Input, InputPhoneNumber } from '@components';
import { PATH_ACCOUNT_CONTACT_VERIFY } from '@router';
import { adminApi } from '@api';
import {
  useAppDispatch,
  useAppSelector,
  selectUser,
  setLoading,
  selectRecoverEmail,
  openToast,
  setIsVerify,
} from '@redux';
import { IAccountContact } from '@interfaces';
import { telegramClient } from '@/configs';

export function AccountContact() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const recoverEmail = useAppSelector(selectRecoverEmail);

  const phoneRegExp =
    // eslint-disable-next-line no-useless-escape
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

  const phoneSchema = Yup.object().shape({
    phone: Yup.string()
      .trim()
      .required('The Mobile number field is required')
      .transform((value, originalValue) => {
        return originalValue === '' ? '' : `+${value}`;
      })
      .min(8, 'The Mobile number format is invalid')
      .matches(phoneRegExp, 'The Mobile number format is invalid'),
  });

  const {
    handleSubmit: handleSubmitPhone,
    clearErrors: clearErrorsPhone,
    setValue: setValuePhone,
    watch: watchPhone,
    formState: { errors: errorsPhone },
  } = useForm<{ phone: string }>({
    mode: 'onChange',
    resolver: yupResolver(phoneSchema),
  });

  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('The Email field is required')
      .email('The Email format is invalid'),
  });

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail },
    clearErrors: clearErrorsEmail,
    setValue: setValueEmail,
    watch: watchEmail,
  } = useForm<{ email: string }>({
    mode: 'onChange',
    resolver: yupResolver(emailSchema),
  });

  const [isVerifyPhone, setIsVerifyPhone] = useState<boolean>(false);

  const [isVerifyEmail, setIsVerifyEmail] = useState<boolean>(false);

  useEffect(() => {
    getAccountContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountContact = async () => {
    try {
      dispatch(setLoading(true));
      const res = await adminApi.getListAccountContact(user.user_tele_id);
      console.log(res?.data?.data);
      const listAccountContact: Array<IAccountContact> = res?.data?.data;
      let is_verify = false;
      listAccountContact.forEach((item: IAccountContact) => {
        if (item?.type === 'PHONE') {
          setValuePhone('phone', item?.contact.replaceAll('+', ''));
          setIsVerifyPhone(item?.is_verify);
          if (!is_verify && item?.is_verify) is_verify = true;
        } else if (item?.type === 'EMAIL') {
          setValueEmail('email', item?.contact);
          setShrinkEmail(true);
          setIsVerifyEmail(item?.is_verify);
          if (!is_verify && item?.is_verify) is_verify = true;
        }
      });
      dispatch(setIsVerify(is_verify));
    } catch (error: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const addAccountContact = async (contact: string, type: 'PHONE' | 'EMAIL') => {
    try {
      dispatch(setLoading(true));
      let is_verify = false;
      if (type === 'PHONE') {
        is_verify = contact === user?.phone_number;
      } else if (type === 'EMAIL') {
        is_verify = contact === recoverEmail;
      }
      await adminApi.addAccountContact({
        type,
        contact,
        user_tele_id: Number(user?.user_tele_id),
        is_verify,
        is_tele_contact: is_verify,

        username: telegramClient.WebApp?.initDataUnsafe?.user?.username
          ? // eslint-disable-next-line prefer-template
            '@' + telegramClient.WebApp?.initDataUnsafe?.user?.username
          : '',
        full_name: `${telegramClient.WebApp?.initDataUnsafe?.user?.first_name ?? ''} ${
          telegramClient.WebApp?.initDataUnsafe?.user?.last_name ?? ''
        }`,
      });

      await getAccountContact();

      dispatch(
        openToast({
          message:
            type === 'PHONE'
              ? 'Your mobile number was saved successfully.'
              : 'Your email address was saved successfully.',
          type: 'success',
        })
      );
    } catch (error: any) {
      dispatch(
        openToast({
          message:
            type === 'PHONE'
              ? 'We were unable to save your mobile number, please try again.'
              : 'We were unable to save your email address, please try again.',
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onSubmitPhone = async ({ phone }: any) => {
    console.log(phone);
    if (phone.startsWith('+')) {
      addAccountContact(phone, 'PHONE');
    } else {
      addAccountContact(`+${phone}`, 'PHONE');
    }
  };

  const onSubmitEmail = async ({ email }: any) => {
    addAccountContact(email, 'EMAIL');
  };

  const handleUseTelegramPhone = () => {
    clearErrorsPhone();
    setValuePhone('phone', user?.phone_number.replaceAll('+', ''));

    // addAccountContact(user?.phone_number, "PHONE");
  };

  const handleUseTelegramEmail = () => {
    clearErrorsEmail();
    setValueEmail('email', recoverEmail);
    setShrinkEmail(true);
    // addAccountContact(recoverEmail, "EMAIL");
  };

  // const getListCountry = async () => {
  //   const apiId = 13736201;
  //   const apiHash = 'b40b251b37f9a89aba52587c0e12d83e';
  //   const newSession = new StringSession(user.session);
  //   const client = new TelegramClient(newSession, apiId, apiHash, {
  //     connectionRetries: 5,
  //   });
  //   console.log('client cp: ', client); // for debug

  //   await client.connect();
  //   const result = await client.invoke(
  //     new Api.help.GetCountriesList({
  //       // @ts-ignore
  //       langCode: telegramClient.WebApp?.initDataUnsafe?.user?.language_code,
  //       hash: 0,
  //     })
  //   );
  //   console.log('listCountry', result); // prints the result
  // };

  const [shrinkEmail, setShrinkEmail] = useState<boolean>();

  return (
    <StyledAccountContact>
      <Breadcrumb />
      <form className="field-block" onSubmit={handleSubmitPhone(onSubmitPhone)}>
        <h2>Mobile Number</h2>
        {!isVerifyPhone ? (
          <>
            <div className="field-block-sub">
              <div className="input_wrapper">
                {/* <Input
                  label="Mobile number"
                  className="field-block-sub-input"
                  register={registerPhone('phone')}
                  error={errorsPhone.phone}
                  name="phone"
                  shrink={shrinkPhone}
                /> */}
                <InputPhoneNumber
                  value={watchPhone('phone')}
                  onChange={(phone) => {
                    setValuePhone('phone', phone, { shouldValidate: true });
                  }}
                  error={errorsPhone?.phone}
                  specialLabel="Mobile number"
                />
              </div>
              <Button variant="contained" className="btn-blue" type="submit">
                Save
              </Button>
            </div>
            <p className="field-block-desc" onClick={handleUseTelegramPhone}>
              Use Telegram mobile number
            </p>
          </>
        ) : (
          <>
            <div className="verified">
              <div className="text">
                +{watchPhone('phone')}
                <span>(Verified)</span>
              </div>
            </div>
            <p
              className={classnames({
                'field-block-desc': true,
              })}
              onClick={() => {
                setIsVerifyPhone(false);
                setValuePhone('phone', '');
              }}
            >
              Update number
            </p>
          </>
        )}
      </form>

      <form className="field-block" onSubmit={handleSubmitEmail(onSubmitEmail)}>
        <h2>Email</h2>
        {!isVerifyEmail ? (
          <>
            <div className="field-block-sub">
              <div className="input_wrapper">
                <Input
                  label="Email"
                  className="field-block-sub-input"
                  register={registerEmail('email')}
                  error={errorsEmail.email}
                  name="email"
                  shrink={shrinkEmail}
                />
              </div>
              <Button variant="contained" className="btn-blue" type="submit">
                Save
              </Button>
            </div>
            <p
              className={classnames({
                'field-block-desc': true,
                ' disabled': !recoverEmail,
              })}
              onClick={recoverEmail ? handleUseTelegramEmail : undefined}
            >
              Use Telegram 2-Factor Auth Recovery Email
            </p>
          </>
        ) : (
          <>
            <div className="verified">
              <div className="text">
                {watchEmail('email')}
                <span>(Verified)</span>
              </div>
            </div>
            <p
              className={classnames({
                'field-block-desc': true,
              })}
              onClick={() => {
                setIsVerifyEmail(false);
                setValueEmail('email', '');
              }}
            >
              Update email
            </p>
          </>
        )}
      </form>
      {(!isVerifyEmail || !isVerifyPhone) && (
        <div className="verify-contact" onClick={() => navigate(`${PATH_ACCOUNT_CONTACT_VERIFY}`)}>
          <div className="verify-contact-sub">
            <VerifyContact />
            <p>Verify Contact</p>
          </div>
          <Next />
        </div>
      )}
    </StyledAccountContact>
  );
}

const StyledAccountContact = styled.div`
  padding: 0px 16px 0 16px;
  .field-block {
    width: 100%;
    padding-top: 20px;
    border-bottom: 1px solid #eeedf5;
    padding-bottom: 24px;
    &:last-child {
      border-bottom: none;
    }
    h2 {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
    .field-block-desc {
      margin-top: 16px;
      color: #0a84ff;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      cursor: pointer;
      margin: 0;
      margin-top: 16px;
      &.disabled {
        color: #8f8f8f;
      }
    }
    .field-block-sub {
      display: grid;
      /* grid-row-gap: 24px; */
      grid-column-gap: 10px;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;

      .input_wrapper {
        grid-column: span 3 / span 3;
      }
    }
  }
  .verify-contact {
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 10px;
    margin-top: 24px;
    border: none;
    outline: none;
    width: 100%;
    padding: 5px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .verify-contact-sub {
      display: flex;
      align-items: center;
      p {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        margin-left: 7px;
      }
    }
  }
  .verified {
    background-color: rgba(10, 132, 255, 0.11);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    padding-left: 18px;
    .text {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      span {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #0a84ff;
        margin-left: 6px;
      }
    }
  }
`;
