import React, { forwardRef } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Input } from '@components';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: '24px',
    // marginTop: '137px',
  },
  subtitle: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '160%',
    textAlign: 'center',
    marginBottom: '30px',
    opacity: '0.7',
    '& .recover_email': {
      fontWeight: 600,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputCode: {
    width: '100%',
  },
  descCode: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '160%',
    textAlign: 'center',
    opacity: '0.7',
    marginTop: '35px',
  },
  resendCode: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 0,
    color: '#0A84FF',
    border: 'none',
    outline: 'none',
    background: 'transparent',
  },
  buttonWrapper: {
    left: 0,
    bottom: 0,
    right: 0,
    position: 'fixed',
    backgroundColor: '#F6F6F6',
    boxShadow: '0px -0.33px 0px #D8D8D8',
    padding: '8px',
  },
  sendButton: {
    width: '100%',
    backgroundColor: '#0A84FF',
    borderRadius: '10px',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&.MuiButton-containedSizeLarge': {
      padding: '13px',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#303f9f',
    },
  },
  step: {
    height: '0',
    visibility: 'hidden',
    '&.active': {
      visibility: 'visible',
      height: 'auto',
    },
  },
}));

type IProps = {
  register: any;
  errors: any;
  isActive: boolean;
  emailAddress: string;
  code?: any;
};

export const CPStepThree = forwardRef(
  (props: IProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const classes = useStyles();

    const { register, errors, isActive, emailAddress, code } = props;

    return (
      <div className={`${classes.step} ${isActive ? 'active' : ''}`}>
        <div className={classes.title}>Verification Code</div>
        <div className={classes.subtitle}>
          Please enter the code we sent to <br />
          <span className="recover_email">{emailAddress}</span>
        </div>
        <div className={classes.form}>
          <Input
            label="Enter the code"
            name="code"
            error={errors.code}
            type="number"
            register={register('code')}
            className={classes.inputCode}
          />

          <p className={classes.descCode}>Didn’t receive code?</p>
          <button className={classes.resendCode} type="submit">
            Resend code
          </button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.sendButton}
            size="large"
            type="button"
            variant="contained"
            ref={ref}
            disabled={!code}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }
);
