import React from 'react';

export function IconQRCode() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#8591FF" />
      <path
        d="M9.6665 16.125C9.32484 16.125 9.0415 15.8417 9.0415 15.5V13.4167C9.0415 11 11.0082 9.0417 13.4165 9.0417H15.4998C15.8415 9.0417 16.1248 9.32504 16.1248 9.6667C16.1248 10.0084 15.8415 10.2917 15.4998 10.2917H13.4165C11.6915 10.2917 10.2915 11.6917 10.2915 13.4167V15.5C10.2915 15.8417 10.0082 16.125 9.6665 16.125Z"
        fill="white"
      />
      <path
        d="M26.3331 16.125C25.9914 16.125 25.7081 15.8417 25.7081 15.5V13.4167C25.7081 11.6917 24.3081 10.2917 22.5831 10.2917H20.4998C20.1581 10.2917 19.8748 10.0084 19.8748 9.6667C19.8748 9.32504 20.1581 9.0417 20.4998 9.0417H22.5831C24.9914 9.0417 26.9581 11 26.9581 13.4167V15.5C26.9581 15.8417 26.6748 16.125 26.3331 16.125Z"
        fill="white"
      />
      <path
        d="M22.583 26.9584H21.333C20.9913 26.9584 20.708 26.675 20.708 26.3334C20.708 25.9917 20.9913 25.7084 21.333 25.7084H22.583C24.308 25.7084 25.708 24.3084 25.708 22.5834V21.3334C25.708 20.9917 25.9913 20.7084 26.333 20.7084C26.6747 20.7084 26.958 20.9917 26.958 21.3334V22.5834C26.958 25 24.9913 26.9584 22.583 26.9584Z"
        fill="white"
      />
      <path
        d="M15.4998 26.9584H13.4165C11.0082 26.9584 9.0415 25 9.0415 22.5834V20.5C9.0415 20.1584 9.32484 19.875 9.6665 19.875C10.0082 19.875 10.2915 20.1584 10.2915 20.5V22.5834C10.2915 24.3084 11.6915 25.7084 13.4165 25.7084H15.4998C15.8415 25.7084 16.1248 25.9917 16.1248 26.3334C16.1248 26.675 15.8415 26.9584 15.4998 26.9584Z"
        fill="white"
      />
      <path
        d="M15.4998 12.375H13.8331C12.8831 12.375 12.3748 12.875 12.3748 13.8334V15.5C12.3748 16.4584 12.8831 16.9584 13.8331 16.9584H15.4998C16.4498 16.9584 16.9581 16.4584 16.9581 15.5V13.8334C16.9581 12.875 16.4498 12.375 15.4998 12.375Z"
        fill="white"
      />
      <path
        d="M22.1665 12.375H20.4998C19.5498 12.375 19.0415 12.875 19.0415 13.8334V15.5C19.0415 16.4584 19.5498 16.9584 20.4998 16.9584H22.1665C23.1165 16.9584 23.6248 16.4584 23.6248 15.5V13.8334C23.6248 12.875 23.1165 12.375 22.1665 12.375Z"
        fill="white"
      />
      <path
        d="M15.4998 19.0417H13.8331C12.8831 19.0417 12.3748 19.5417 12.3748 20.5V22.1667C12.3748 23.125 12.8831 23.625 13.8331 23.625H15.4998C16.4498 23.625 16.9581 23.125 16.9581 22.1667V20.5C16.9581 19.5417 16.4498 19.0417 15.4998 19.0417Z"
        fill="white"
      />
      <path
        d="M22.1665 19.0417H20.4998C19.5498 19.0417 19.0415 19.5417 19.0415 20.5V22.1667C19.0415 23.125 19.5498 23.625 20.4998 23.625H22.1665C23.1165 23.625 23.6248 23.125 23.6248 22.1667V20.5C23.6248 19.5417 23.1165 19.0417 22.1665 19.0417Z"
        fill="white"
      />
    </svg>
  );
}

export const IconQRCodeLite = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 10.25C2.09 10.25 1.75 9.91 1.75 9.5V7C1.75 4.1 4.11 1.75 7 1.75H9.5C9.91 1.75 10.25 2.09 10.25 2.5C10.25 2.91 9.91 3.25 9.5 3.25H7C4.93 3.25 3.25 4.93 3.25 7V9.5C3.25 9.91 2.91 10.25 2.5 10.25Z"
        fill="white"
      />
      <path
        d="M22.5 10.25C22.09 10.25 21.75 9.91 21.75 9.5V7C21.75 4.93 20.07 3.25 18 3.25H15.5C15.09 3.25 14.75 2.91 14.75 2.5C14.75 2.09 15.09 1.75 15.5 1.75H18C20.89 1.75 23.25 4.1 23.25 7V9.5C23.25 9.91 22.91 10.25 22.5 10.25Z"
        fill="white"
      />
      <path
        d="M18 23.25H16.5C16.09 23.25 15.75 22.91 15.75 22.5C15.75 22.09 16.09 21.75 16.5 21.75H18C20.07 21.75 21.75 20.07 21.75 18V16.5C21.75 16.09 22.09 15.75 22.5 15.75C22.91 15.75 23.25 16.09 23.25 16.5V18C23.25 20.9 20.89 23.25 18 23.25Z"
        fill="white"
      />
      <path
        d="M9.5 23.25H7C4.11 23.25 1.75 20.9 1.75 18V15.5C1.75 15.09 2.09 14.75 2.5 14.75C2.91 14.75 3.25 15.09 3.25 15.5V18C3.25 20.07 4.93 21.75 7 21.75H9.5C9.91 21.75 10.25 22.09 10.25 22.5C10.25 22.91 9.91 23.25 9.5 23.25Z"
        fill="white"
      />
      <path
        d="M9.5 5.75H7.5C6.36 5.75 5.75 6.35 5.75 7.5V9.5C5.75 10.65 6.36 11.25 7.5 11.25H9.5C10.64 11.25 11.25 10.65 11.25 9.5V7.5C11.25 6.35 10.64 5.75 9.5 5.75Z"
        fill="white"
      />
      <path
        d="M17.5 5.75H15.5C14.36 5.75 13.75 6.35 13.75 7.5V9.5C13.75 10.65 14.36 11.25 15.5 11.25H17.5C18.64 11.25 19.25 10.65 19.25 9.5V7.5C19.25 6.35 18.64 5.75 17.5 5.75Z"
        fill="white"
      />
      <path
        d="M9.5 13.75H7.5C6.36 13.75 5.75 14.35 5.75 15.5V17.5C5.75 18.65 6.36 19.25 7.5 19.25H9.5C10.64 19.25 11.25 18.65 11.25 17.5V15.5C11.25 14.35 10.64 13.75 9.5 13.75Z"
        fill="white"
      />
      <path
        d="M17.5 13.75H15.5C14.36 13.75 13.75 14.35 13.75 15.5V17.5C13.75 18.65 14.36 19.25 15.5 19.25H17.5C18.64 19.25 19.25 18.65 19.25 17.5V15.5C19.25 14.35 18.64 13.75 17.5 13.75Z"
        fill="white"
      />
    </svg>
  );
};
