export function convertError(axiosError: any) {
  let errMessage = '';
  if (Array.isArray(axiosError?.errors)) {
    errMessage = axiosError?.errors[0];
  } else {
    errMessage = axiosError?.errors || axiosError?.errors?.message;
  }

  return errMessage;
}

export const formatCurrency = (value: number): string => {
  const numberString = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  return `$${numberString}`;
};
