import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  sendButton: {
    width: '100%',
    backgroundColor: '#0A84FF',
    borderRadius: '10px',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&.MuiButton-containedSizeLarge': {
      padding: '13px',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
    },
  },
  buttonWrapper: {
    left: 0,
    bottom: 0,
    right: 0,
    position: 'fixed',
    backgroundColor: '#F6F6F6',
    boxShadow: '0px -0.33px 0px #D8D8D8',
    padding: '8px',
    zIndex: 10000,
  },
  iconWrapper: {
    marginRight: '10px',
  },
}));

export type IProps = {
  onClick?(): void;
  type: 'button' | 'submit';
  text: string;
  disabled?: boolean;
  preIcon?: React.ReactElement;
};

export function MainButton(props: IProps) {
  const classes = useStyles();

  const { onClick, type, text, disabled = false, preIcon } = props;

  return (
    <div className={classes.buttonWrapper}>
      <Button
        className={classes.sendButton}
        onClick={onClick}
        size="large"
        type={type}
        variant="contained"
        color="primary"
        disabled={disabled}
        startIcon={preIcon}
      >
        {text}
      </Button>
    </div>
  );
}
