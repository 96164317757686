export const encryptblob = async (blob: Blob) => {
  // @ts-ignore
  // const iv = new Uint8Array(iv_fixed.split(','));
  const iv = crypto.getRandomValues(new Uint8Array(12));
  console.log('iv: ', iv); // for debug

  const algorithm = {
    name: 'AES-GCM',
    iv,
  };

  const key = await crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt']
  );
  console.log('k: ', key); // for debug

  const data = await blob.arrayBuffer();

  const result = await crypto.subtle.encrypt(algorithm, key, data);

  const exportedkey = await crypto.subtle.exportKey('jwk', key);
  console.log('exportedkey: ', JSON.stringify(exportedkey)); // for debug

  // return data;
  // return new Blob(result);
  return [new Blob([result]), iv.toString(), exportedkey];
};

export const decryptblob = async (encblob: any, ivdata: any, exportedkey: any) => {
  const key = await crypto.subtle.importKey('jwk', exportedkey, { name: 'AES-GCM' }, true, [
    'encrypt',
    'decrypt',
  ]);

  const iv = new Uint8Array(ivdata.split(','));

  const algorithm = {
    name: 'AES-GCM',
    iv,
  };

  const data = await encblob.arrayBuffer();

  const decryptedData = await crypto.subtle.decrypt(algorithm, key, data);

  return new Blob([decryptedData]);
};

export const _arrayBufferToBase64 = (buffer: Buffer | ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
};

export const convertTZ = (timeStamp?: string) => {
  try {
    if (!timeStamp) throw new Error('No time stamp to convert!');

    const today = new Date(timeStamp);
    const todayInDB = new Date(
      (typeof today === 'string' ? new Date(today) : today).toLocaleString('en-US', {
        timeZone: 'Pacific/Kosrae',
      })
    );
    const h = String(todayInDB.getHours()).padStart(2, '0');
    const m = String(todayInDB.getMinutes()).padStart(2, '0');
    const dd = String(todayInDB.getDate()).padStart(2, '0');
    const mm = String(todayInDB.getMonth() + 1).padStart(2, '0');
    const yyyy = todayInDB.getFullYear();

    return `${yyyy}/${mm}/${dd}-${h}:${m}`;
  } catch (error: any) {
    console.log('error when convert time: ', timeStamp, error); // for debug

    return '--.--';
  }
};

export function handleDecimalPrice(x: number | string) {
  x = Number(x);

  return Number(x.toFixed(8));
}
