import React from 'react';

export const IconWithdraw = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2416 1.66699H7.25829C4.22496 1.66699 2.41663 3.47533 2.41663 6.50866V13.4837C2.41663 16.5253 4.22496 18.3337 7.25829 18.3337H14.2333C17.2666 18.3337 19.075 16.5253 19.075 13.492V6.50866C19.0833 3.47533 17.275 1.66699 14.2416 1.66699ZM15.125 10.2753C15.125 10.617 14.8416 10.9003 14.5 10.9003C14.1583 10.9003 13.875 10.617 13.875 10.2753V7.75866L7.44163 14.192C7.31663 14.317 7.15829 14.3753 6.99996 14.3753C6.84163 14.3753 6.68329 14.317 6.55829 14.192C6.31663 13.9503 6.31663 13.5503 6.55829 13.3087L12.9916 6.87533H10.475C10.1333 6.87533 9.84996 6.59199 9.84996 6.25033C9.84996 5.90866 10.1333 5.62533 10.475 5.62533H14.5C14.8416 5.62533 15.125 5.90866 15.125 6.25033V10.2753Z"
        fill="white"
      />
    </svg>
  );
};
