import React, { useState } from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';

import { Breadcrumb } from '@components';

export function QRSharePage() {
  const [hashData, setHashData] = useState<string>('0e8a457f');

  // main return
  return (
    <StyledQRSharePage>
      <Breadcrumb />
      <div className="qr_code_wrapper">
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={hashData}
          viewBox="0 0 256 256"
        />
      </div>
    </StyledQRSharePage>
  );
}

const StyledQRSharePage = styled.div`
  padding: 0px 16px 0 16px;
  .qr_code_wrapper {
    margin-top: 90px;
    padding: 0 35px;
  }
`;
