import { Breadcrumbs, Link as StyledLink } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useMemo } from 'react';
import { selectStep, setStep, useAppDispatch, useAppSelector } from '@/redux';
import { PATH_PAY_ANYONE } from '@/router';

export function BreadcrumbStep() {
  const dispatch = useAppDispatch();

  const step = useAppSelector(selectStep);

  const { pathname } = useLocation();

  const breadcrumbName = useMemo(() => {
    switch (pathname) {
      case `/${PATH_PAY_ANYONE}`:
        return {
          0: 'Transfer',
          1: 'Add Receiver',
          2: 'Pay',
        };
      default:
        return {
          0: 'Payment Request',
          1: 'Add Payer',
          2: 'Send Request',
        };
    }
  }, [pathname]);

  const stepSnippets = useMemo(() => {
    switch (step) {
      case 1:
        return [0, 1];
      case 2:
        return [0, 1, 2];
      default:
        return [0];
    }
  }, [step]);

  const extraBreadcrumbItems = stepSnippets.map((step, index) => {
    // @ts-ignore
    if (breadcrumbName[step]) {
      return (
        <StyledLink key={step}>
          {index + 1 === stepSnippets.length ? (
            // @ts-ignore
            <div className="item active">{breadcrumbName[step]}</div>
          ) : (
            <div className="item" onClick={() => dispatch(setStep(step))}>
              {/* @ts-ignore */}
              {breadcrumbName[step]}
            </div>
          )}
        </StyledLink>
      );
    }

    return <></>;
  });

  // const breadcrumbItems = [
  //   <StyledLink key="dashboard" className="breadcrumb-item">
  //     <div className="item" onClick={() => navigate(`/${PATH_ADMIN_HOME}`, { replace: false })}>
  //       Admin
  //     </div>
  //   </StyledLink>,
  //   // @ts-ignore
  // ].concat(extraBreadcrumbItems);

  return (
    <StyledBreadcrumb>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        {extraBreadcrumbItems}
      </Breadcrumbs>
    </StyledBreadcrumb>
  );
}

const StyledBreadcrumb = styled.div`
  margin-bottom: 24px;
  .item {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-decoration: none;
    color: #8e8e93;
    opacity: 0.8;
    &.active {
      opacity: 1;
      color: #171717;
      cursor: default;
    }
    cursor: pointer;
  }
  .MuiTypography-colorTextSecondary {
    color: #a5a5a5;
  }
  .MuiLink-underlineHover:hover {
    text-decoration: none;
  }
  .MuiBreadcrumbs-separator {
    font-size: 24px;
    line-height: 24px;
  }
`;
