import React from 'react';

export const IconQRPay = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#6369FF" />
      <path
        d="M10.3084 16.3337C9.95008 16.3337 9.66675 16.0503 9.66675 15.692V13.767C9.66675 11.5087 11.5084 9.66699 13.7667 9.66699H15.6917C16.0501 9.66699 16.3334 9.95033 16.3334 10.3087C16.3334 10.667 16.0501 10.9503 15.6917 10.9503H13.7667C12.2084 10.9503 10.9501 12.217 10.9501 13.767V15.692C10.9501 16.0503 10.6584 16.3337 10.3084 16.3337Z"
        fill="white"
      />
      <path
        d="M25.6917 16.3337C25.3417 16.3337 25.0501 16.0503 25.0501 15.692V13.767C25.0501 12.2087 23.7834 10.9503 22.2334 10.9503H20.3084C19.9501 10.9503 19.6667 10.6587 19.6667 10.3087C19.6667 9.95866 19.9501 9.66699 20.3084 9.66699H22.2334C24.4917 9.66699 26.3334 11.5087 26.3334 13.767V15.692C26.3334 16.0503 26.0501 16.3337 25.6917 16.3337Z"
        fill="white"
      />
      <path
        d="M22.2333 26.3331H21.075C20.725 26.3331 20.4333 26.0497 20.4333 25.6914C20.4333 25.3414 20.7167 25.0497 21.075 25.0497H22.2333C23.7917 25.0497 25.05 23.7831 25.05 22.2331V21.0831C25.05 20.7331 25.3333 20.4414 25.6917 20.4414C26.0417 20.4414 26.3334 20.7247 26.3334 21.0831V22.2331C26.3334 24.4914 24.4917 26.3331 22.2333 26.3331Z"
        fill="white"
      />
      <path
        d="M15.6917 26.3337H13.7667C11.5084 26.3337 9.66675 24.492 9.66675 22.2337V20.3087C9.66675 19.9503 9.95008 19.667 10.3084 19.667C10.6667 19.667 10.9501 19.9503 10.9501 20.3087V22.2337C10.9501 23.792 12.2167 25.0503 13.7667 25.0503H15.6917C16.0417 25.0503 16.3334 25.3337 16.3334 25.692C16.3334 26.0503 16.0501 26.3337 15.6917 26.3337Z"
        fill="white"
      />
      <path
        d="M23.3834 17.3584H22.2501H13.7501H12.6168C12.2584 17.3584 11.9751 17.6501 11.9751 18.0001C11.9751 18.3501 12.2584 18.6417 12.6168 18.6417H13.7501H22.2501H23.3834C23.7418 18.6417 24.0251 18.3501 24.0251 18.0001C24.0251 17.6501 23.7418 17.3584 23.3834 17.3584Z"
        fill="white"
      />
      <path
        d="M13.75 19.6164V19.8914C13.75 21.2747 14.8667 22.3914 16.25 22.3914H19.75C21.1333 22.3914 22.25 21.2747 22.25 19.8914V19.6164C22.25 19.5164 22.175 19.4414 22.075 19.4414H13.925C13.825 19.4414 13.75 19.5164 13.75 19.6164Z"
        fill="white"
      />
      <path
        d="M13.75 16.3834V16.1084C13.75 14.7251 14.8667 13.6084 16.25 13.6084H19.75C21.1333 13.6084 22.25 14.7251 22.25 16.1084V16.3834C22.25 16.4834 22.175 16.5584 22.075 16.5584H13.925C13.825 16.5584 13.75 16.4834 13.75 16.3834Z"
        fill="white"
      />
    </svg>
  );
};
