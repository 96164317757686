import { QrReader } from '@blackbox-vision/react-qr-reader';
import { useEffect, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { Api } from 'telegram';

import { IconArrowLeft } from '@/components';
import { useViewPort } from '@/hooks';
import { StyledQRScanner } from '@/pages';
import {
  openToast,
  selectTelegramClient,
  selectUser,
  setLoading,
  setStep,
  useAppDispatch,
  useAppSelector,
} from '@/redux';

type IProps = {
  isActive: boolean;
  // setValue: UseFormSetValue<IRequestPayment>;
  setValue: UseFormSetValue<any>;
  feature: 'RP' | 'PA'; // request payment OR pay anyone
};

export const StepThreeQR = (props: IProps) => {
  const { isActive, setValue, feature } = props;

  const streamRef = useRef(null);
  const dispatch = useAppDispatch();

  const { height } = useViewPort();

  const [userPhone, setUserPhone] = useState<string>();

  const handleScan = (decoded?: string) => {
    console.log('data scan', decoded);
    if (decoded) {
      const phone = decoded.split('/').pop();
      setUserPhone(phone);
    }
  };

  // console.log('viewPortHeight', height);

  // redux states
  const session = useAppSelector(selectUser)?.session;
  const client = useAppSelector(selectTelegramClient);

  useEffect(() => {
    if (userPhone) {
      let param = userPhone;
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(userPhone)) param = `+${userPhone}`;
      console.log('userPhone', param);
      (async () => {
        try {
          if (!client) {
            console.log('no client!');

            return;
          }

          dispatch(setLoading(true));
          const userId = await client.getPeerId(param);
          // setValue('payer_user_id', userId);
          // // set form value based on feature (request payment | pay anyone)
          // feature === 'RP'
          //   ? setValue('payer_user_id', userId) // set form value for payer_user_id
          //   : setValue('receiver_user_id', userId); // set form value for receiver_user_id
          console.log('userId', userId);
          setValue('selected_contact_id', Number(userId));
          const result = await client.invoke(
            new Api.users.GetUsers({
              id: [userId],
            })
          );
          console.log('userInfo', result);
          // @ts-ignore
          if (result[0]?.username) setValue('contact_name', result[0]?.username);
          // @ts-ignore
          else
            setValue(
              'contact_name',
              // @ts-ignore
              `${result[0]?.firstName ? result[0]?.firstName : ''} ${
                // @ts-ignore
                result[0]?.lastName ? result[0]?.lastName : ''
              }`?.trim()
            );
        } catch (error: any) {
          console.log(error);
          dispatch(
            openToast({
              message: 'Contact not found, please try again',
              type: 'error',
              autoHideDuration: 3500,
            })
          );
        } finally {
          dispatch(setLoading(false));
          // clear userPhone to re-scan
          setUserPhone('');
          // back to step two
          dispatch(setStep(1));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPhone]);

  return (
    <div className={isActive ? 'active' : 'inactive'}>
      <StyledQRScanner>
        {isActive && (
          <QrReader
            showViewFinder={false}
            resolution={480}
            facingMode="environment"
            onLoad={({ stream }: any) => {
              streamRef.current = stream;
            }}
            onScan={handleScan}
            onError={(err: any) => dispatch(setStep(1))}
          />
        )}
        <div className="qr_zone" />
        {/* <div onClick={toggle} className="flash_btn">
          <IconFlashlight />
        </div> */}
        <div className="back_btn" onClick={() => dispatch(setStep(1))}>
          <IconArrowLeft />
        </div>
        <div className="screen_title">QR Pay</div>
        <div className="scanner_note">
          {height >= 645 && <div className="note_title">Scan QR Code</div>}
          {height >= 770 && (
            <div className="note_sub_title">
              Place your camera over the entire QR code / Barcode to start scanning.
            </div>
          )}
        </div>
      </StyledQRScanner>
    </div>
  );
};
