import React from 'react';

export function IconHelp() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#CAD0DA" />
      <path
        d="M22.1665 10.025H13.8332C11.3332 10.025 9.6665 11.6917 9.6665 14.1917V19.1917C9.6665 21.6917 11.3332 23.3584 13.8332 23.3584V25.1334C13.8332 25.8 14.5748 26.2 15.1248 25.825L18.8332 23.3584H22.1665C24.6665 23.3584 26.3332 21.6917 26.3332 19.1917V14.1917C26.3332 11.6917 24.6665 10.025 22.1665 10.025ZM17.9998 20.1667C17.6498 20.1667 17.3748 19.8834 17.3748 19.5417C17.3748 19.2 17.6498 18.9167 17.9998 18.9167C18.3498 18.9167 18.6248 19.2 18.6248 19.5417C18.6248 19.8834 18.3498 20.1667 17.9998 20.1667ZM19.0498 16.7084C18.7248 16.925 18.6248 17.0667 18.6248 17.3V17.475C18.6248 17.8167 18.3415 18.1 17.9998 18.1C17.6582 18.1 17.3748 17.8167 17.3748 17.475V17.3C17.3748 16.3334 18.0832 15.8584 18.3498 15.675C18.6582 15.4667 18.7582 15.325 18.7582 15.1084C18.7582 14.6917 18.4165 14.35 17.9998 14.35C17.5832 14.35 17.2415 14.6917 17.2415 15.1084C17.2415 15.45 16.9582 15.7334 16.6165 15.7334C16.2748 15.7334 15.9915 15.45 15.9915 15.1084C15.9915 14 16.8915 13.1 17.9998 13.1C19.1082 13.1 20.0082 14 20.0082 15.1084C20.0082 16.0584 19.3082 16.5334 19.0498 16.7084Z"
        fill="white"
      />
    </svg>
  );
}
