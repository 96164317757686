import React from 'react';

export const IconTopUp = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7416 1.66699H6.75829C3.72496 1.66699 1.91663 3.47533 1.91663 6.50866V13.4837C1.91663 16.5253 3.72496 18.3337 6.75829 18.3337H13.7333C16.7666 18.3337 18.575 16.5253 18.575 13.492V6.50866C18.5833 3.47533 16.775 1.66699 13.7416 1.66699ZM7.30829 9.15033C7.54996 8.90866 7.94996 8.90866 8.19163 9.15033L9.62496 10.5837V5.42533C9.62496 5.08366 9.90829 4.80033 10.25 4.80033C10.5916 4.80033 10.875 5.08366 10.875 5.42533V10.5837L12.3083 9.15033C12.55 8.90866 12.95 8.90866 13.1916 9.15033C13.4333 9.39199 13.4333 9.79199 13.1916 10.0337L10.6916 12.5337C10.6333 12.592 10.5666 12.6337 10.4916 12.667C10.4166 12.7003 10.3333 12.717 10.25 12.717C10.1666 12.717 10.0916 12.7003 10.0083 12.667C9.93329 12.6337 9.86663 12.592 9.80829 12.5337L7.30829 10.0337C7.06663 9.79199 7.06663 9.40033 7.30829 9.15033ZM15.45 14.3503C13.775 14.9087 12.0166 15.192 10.25 15.192C8.48329 15.192 6.72496 14.9087 5.04996 14.3503C4.72496 14.242 4.54996 13.8837 4.65829 13.5587C4.76663 13.2337 5.11663 13.0503 5.44996 13.167C8.54996 14.2003 11.9583 14.2003 15.0583 13.167C15.3833 13.0587 15.7416 13.2337 15.85 13.5587C15.95 13.892 15.775 14.242 15.45 14.3503Z"
        fill="white"
      />
    </svg>
  );
};
