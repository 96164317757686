import React from 'react';
import styled from 'styled-components';
import { Input } from '@components';

export const InputNumbericOnly = () => {
  return (
    <StyledInputNumber>
      <Input type="tel" />
    </StyledInputNumber>
  );
};

const StyledInputNumber = styled.div``;
