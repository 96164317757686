import { useRef } from 'react';

type IProps = {
  children: React.ReactElement;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  accept?: string;
};

export function InputFile(props: IProps) {
  // props
  const { children, onChange, accept } = props;

  const inputFile = useRef<HTMLInputElement>(null);

  const handleClickChooseFile = () => {
    console.log('click');

    if (inputFile.current) inputFile.current.click();
  };

  return (
    <div className="w-full h-full">
      <input type="file" hidden ref={inputFile} onChange={onChange} accept={accept} />
      <div
        className="w-full h-full cursor-pointer flex flex-col items-center justify-center"
        onClick={handleClickChooseFile}
      >
        {children}
      </div>
    </div>
  );
}
