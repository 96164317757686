import React from 'react';

export const IconTrash = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0466 3.48683C12.9733 3.38016 11.8999 3.30016 10.8199 3.24016V3.2335L10.6733 2.36683C10.5733 1.7535 10.4266 0.833496 8.86661 0.833496H7.11994C5.56661 0.833496 5.41994 1.7135 5.31328 2.36016L5.17328 3.2135C4.55328 3.2535 3.93328 3.2935 3.31328 3.3535L1.95328 3.48683C1.67328 3.5135 1.47328 3.76016 1.49994 4.0335C1.52661 4.30683 1.76661 4.50683 2.04661 4.48016L3.40661 4.34683C6.89994 4.00016 10.4199 4.1335 13.9533 4.48683C13.9733 4.48683 13.9866 4.48683 14.0066 4.48683C14.2599 4.48683 14.4799 4.2935 14.5066 4.0335C14.5266 3.76016 14.3266 3.5135 14.0466 3.48683Z"
        fill="#B9B9B9"
      />
      <path
        d="M12.8199 5.4265C12.6599 5.25984 12.4399 5.1665 12.2132 5.1665H3.78658C3.55991 5.1665 3.33325 5.25984 3.17991 5.4265C3.02658 5.59317 2.93991 5.81984 2.95325 6.05317L3.36658 12.8932C3.43991 13.9065 3.53325 15.1732 5.85991 15.1732H10.1399C12.4666 15.1732 12.5599 13.9132 12.6332 12.8932L13.0466 6.05984C13.0599 5.81984 12.9732 5.59317 12.8199 5.4265ZM9.10658 11.8332H6.88658C6.61325 11.8332 6.38658 11.6065 6.38658 11.3332C6.38658 11.0598 6.61325 10.8332 6.88658 10.8332H9.10658C9.37991 10.8332 9.60658 11.0598 9.60658 11.3332C9.60658 11.6065 9.37991 11.8332 9.10658 11.8332ZM9.66658 9.1665H6.33325C6.05991 9.1665 5.83325 8.93984 5.83325 8.6665C5.83325 8.39317 6.05991 8.1665 6.33325 8.1665H9.66658C9.93991 8.1665 10.1666 8.39317 10.1666 8.6665C10.1666 8.93984 9.93991 9.1665 9.66658 9.1665Z"
        fill="#B9B9B9"
      />
    </svg>
  );
};
