import styled from 'styled-components';
import { ArrowDown, ArrowUp } from '@/components';
import { DirectionTrxnEnum } from '@/configs';
import { IItemTrxnHistory } from '@/interfaces';
import { convertTZ } from '@/utils';

type IProps = {
  item: IItemTrxnHistory;
};

export const ItemTrxnHistory = (props: IProps) => {
  const { item } = props;

  // functions
  const renderValue = (value: number) => {
    if (!value) return 0;
    try {
      if (value > 0) {
        return (
          <div className="content_data_value_container">
            <p className="content_data_value_up">{`+$${value?.toFixed(2)}`}</p>
            <ArrowUp />
          </div>
        );
      }
      if (value < 0) {
        return (
          <div className="content_data_value_container">
            <p className="content_data_value_down">{`-$${-value?.toFixed(2)}`}</p>
            <ArrowDown />
          </div>
        );
      }
    } catch (error) {
      console.log(error);
    }

    return 0;
  };

  // main return
  return (
    <StyleItemTrxnHistory>
      <div className="content">
        <p className="content_date">{convertTZ(item?.updated_at)}</p>
        <p className="content_desc">{item?.title}</p>
        <div className="content_info">
          <p className="content_info_text">Description:</p>
          <p className="content_info_text">{item?.description}</p>
        </div>
        <div className="content_info">
          <p className="content_info_text">My trxn ref:</p>
          <p className="content_info_text">{item?.my_trxn_ref}</p>
        </div>
        <div className="content_info">
          <p className="content_info_text">Their trxn ref:</p>
          <p className="content_info_text">{item?.their_trxn_ref || '--.--'}</p>
        </div>
        <div className="content_info">
          <p className="content_info_text">My number:</p>
          <p className="content_info_text">{item?.my_number}</p>
        </div>
        <div className="content_info">
          <p className="content_info_text">Their_number:</p>
          <p className="content_info_text">{item?.their_number || '--.--'}</p>
        </div>

        <div className="content_data">
          <p className="content_data_name">
            {item?.merchUserDetail?.registed_name
              ? item?.merchUserDetail?.registed_name
              : item?.direction === DirectionTrxnEnum?.PAY
              ? item?.receiver
              : item?.payer}
          </p>
          {renderValue(Number(item?.amount))}
        </div>
      </div>
    </StyleItemTrxnHistory>
  );
};

const StyleItemTrxnHistory = styled.div`
  .content {
    border-top: 1px solid #e7e6f0;
    padding-top: 10px;
    padding-bottom: 10px;

    .content_date {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      /* line-height: 20px; */
      margin: 0;
      color: #8f8f8f;
    }

    .content_desc {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      /* line-height: 20px; */
      margin: 0;
      color: #8f8f8f;
    }

    .content_info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content_info_text {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        /* line-height: 20px; */
        margin: 0;
        color: #8f8f8f;
      }
    }

    .content_data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .content_data_name {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        /* line-height: 20px; */
        color: #171717;
        margin: 0;
      }

      .content_data_value_container {
        display: flex;
        align-items: center;
        .content_data_value_up {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          /* line-height: 20px; */
          margin-right: 6px;
          margin: 0;

          /* Semantic/Green */

          color: #30b364;
        }
        .content_data_value_down {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          /* line-height: 20px; */
          margin-right: 6px;
          margin: 0;

          /* Semantic/Red */

          color: #ca1818;
        }
      }
    }
  }
`;
