import React from 'react';

export function IdDocs() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#6369FF" />
      <path
        d="M16.25 11.095H14.805C13.62 11.095 12.655 10.13 12.655 8.945V7.5C12.655 7.225 12.43 7 12.155 7H10.035C8.495 7 7.25 8 7.25 9.785V14.215C7.25 16 8.495 17 10.035 17H13.965C15.505 17 16.75 16 16.75 14.215V11.595C16.75 11.32 16.525 11.095 16.25 11.095Z"
        fill="white"
      />
      <path
        d="M13.9 7.105C13.695 6.9 13.34 7.04 13.34 7.325V9.07C13.34 9.8 13.96 10.405 14.715 10.405C15.19 10.41 15.85 10.41 16.415 10.41C16.7 10.41 16.85 10.075 16.65 9.875C15.93 9.15 14.64 7.845 13.9 7.105Z"
        fill="white"
      />
    </svg>
  );
}
