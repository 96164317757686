/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
// form imports
import { telegramClient, apiId, apiHash } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  openToast,
  selectStep,
  selectUser,
  setBalanceInfo,
  setCallbackAfterEnterPin,
  setLoading,
  setRouteAfterEnterPin,
  setStep,
  useAppSelector,
} from '@redux';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Api, TelegramClient } from 'telegram';
import { StringSession } from 'telegram/sessions';
import * as Yup from 'yup';
import { adminApi } from '@/api';
import { StyledPaymnetPage } from './style';

// steps import
import { useBalance } from '@/hooks';
import { PATH_ENTER_PIN, PATH_HOME } from '@/router';
import { StepFour, StepOne, StepThreeQR, StepTwo } from './Steps';
import { IContacts, IPayAnyOne } from './types';

export const PayAnyOne = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const session = useAppSelector(selectUser)?.session;
  const userTeleId = useAppSelector(selectUser)?.user_tele_id;
  const user = useAppSelector(selectUser);
  const { balance } = useBalance(user?.user_tele_id);

  // feature var (request payment)
  const feature = 'PA';

  // step of actions (init at step 0)
  const step = useAppSelector(selectStep);

  // states for storing contacts
  const [contacts, setContacts] = useState<IContacts[]>([]);

  // form
  const PayAnyOneSchema = Yup.object().shape({
    amount: Yup.string()
      .trim()
      .required('Please enter an amount to proceed with the payment.')
      .test(
        'empty_val',
        'Amount must be greater than $0.00, please enter a valid value.',
        function (val) {
          if (val) {
            if (Number(val) < 0.01) return false;
          }

          return true;
        }
      )
      .test('max_val', 'Please enters an Amount less than $10,000.', function (val) {
        if (val) {
          if (Number(val) > 10000) return false;
        }

        return true;
      })
      .test('not_enough_balance', 'Your balance is not enough', function (val) {
        if (val) {
          if (Number(val) > balance) return false;
        }

        return true;
      }),
    description: Yup.string().trim().required('Please enters a description'),
    receiver_user_id: Yup.number().required('payer_user_id required'),
    // pay_by: Yup.string().required('Required'),
    // pay_id: Yup.number().required('Required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm<IPayAnyOne>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PayAnyOneSchema),
    defaultValues: {
      amount: '',
      description: '',
      auth_date: '',
    },
  });

  const submitRequest = (formData: IPayAnyOne) => {
    try {
      // assign default values to formData
      formData.payer_user_id = Number(userTeleId);
      formData.auth_date =
        telegramClient?.initDataUnsafe?.auth_date || dayjs(new Date()).valueOf().toString();
      console.log('formdata: ', formData); // for debug

      // redux: next route + callback, after that navigate to enter pin
      dispatch(setRouteAfterEnterPin(`${PATH_HOME}`));
      dispatch(
        setCallbackAfterEnterPin(async () => {
          dispatch(setLoading(true));
          dispatch(
            openToast({ message: 'Submitting Payment...', type: 'info', autoHideDuration: null })
          );
          try {
            dispatch(setBalanceInfo(null)); // set to null in order to change to undefine afterward (null !== undefine)
            await adminApi.payAnyOne(formData);

            dispatch(
              openToast({
                message: 'Payment request sent successfully.',
                type: 'success',
                autoHideDuration: 2000,
              })
            );
            dispatch(setBalanceInfo(undefined)); // clear the balance info after enter pin, this trigger re-get balance
          } catch (error: any) {
            console.log('err: ', error); // for debug
            dispatch(
              openToast({
                message:
                  error?.response?.data?.message || 'Request payment failed. Please try again.',
                type: 'error',
                autoHideDuration: null,
              })
            );
          }

          // dispatch(setLoading(false));
        })
      );
      navigate(`/${PATH_ENTER_PIN}`);
    } catch (error) {
      dispatch(openToast({ message: 'Some thing went wrong, please try again.', type: 'error' }));
      console.log(error);
    } finally {
      // dispatch(closeToast());
    }
  };

  // functions
  const handleNextStep_1_2 = useCallback(() => {
    if (!session) return;
    try {
      // after all is done, go for next step (select payer)
      dispatch(setStep(1));
    } catch (error) {
      console.log(error);
      dispatch(openToast({ message: 'Some thing went wrong, please try again.', type: 'error' }));
    } finally {
      dispatch(setLoading(false));
    }
  }, []);

  // this function will also get all the contact data ( state ) of the accociated user (name, userid, profile picture)
  const handleNextStep_2_3 = useCallback(async () => {
    try {
      dispatch(setLoading(true));

      // init the final contacts var, used for set state at the end of this function
      const finalContactData: IContacts[] = [];

      // init and connect telegram client
      const newSession = new StringSession(session);
      const client = new TelegramClient(newSession, apiId, apiHash, {
        connectionRetries: 5,
      });
      console.log('client cp: ', client); // for debug
      await client.connect(); // This assumes you have already authenticated with .start()
      // end of init and connect telegram client

      // get contacts
      let result = await client.invoke(new Api.contacts.GetContactIDs({}));
      result = result.map((item) => item);
      console.log('result get all contacts: ', result); // prints the result
      // assign ids to contacts state
      result.forEach((item) => {
        if (item)
          finalContactData.push({
            id: item,
          });
      });
      console.log('finalContactData [id]: ', finalContactData); // for debug
      // end of get contacts

      // get acttual user info by their ID get from above
      const result_user = await client.invoke(
        new Api.users.GetUsers({
          id: [...finalContactData.map((contact) => contact?.id)],
        })
      );
      console.log('result_user: ', result_user); // prints the result
      // set username, firstname, lastname, phone to contacts state
      result_user.forEach((user, index) => {
        // @ts-ignore
        finalContactData[index].first_name = user?.firstName;
        // @ts-ignore
        finalContactData[index].last_name = user?.lastName;
        // @ts-ignore
        finalContactData[index].user_name = user?.username;
        // @ts-ignore
        finalContactData[index].phone = user?.phone;
      });
      console.log('finalContactData [username, firstname, lastname, phone]: ', finalContactData); // for debug

      // end of get acttual user info by their ID get from above

      // get all profile pictures
      const profilePicsPromise: any = [];
      for (let index = 0; index < result_user.length; index++) {
        const element = result_user[index];
        // eslint-disable-next-line no-await-in-loop
        const bufferPromise = client.downloadProfilePhoto(element, {
          isBig: false,
          //   outputFile: 'Buffer',
        });
        // @ts-ignore
        profilePicsPromise.push(bufferPromise);
      }
      const profilePics = await Promise.all(profilePicsPromise);
      console.log('profilePics: ', profilePics);
      // assign avatar buffers to contacts state
      profilePics.forEach((buffer, index) => {
        finalContactData[index].avatar = buffer;
      });
      console.log('finalContactData [avatar]: ', finalContactData); // for debug
      // end of get all profile pictures

      // set contacts state to use later on
      setContacts(finalContactData);

      // after all is done, go for next step (select user)
      dispatch(setStep(2));
    } catch (error) {
      console.log(error);
      dispatch(openToast({ message: 'Some thing went wrong, please try again.', type: 'error' }));
    } finally {
      dispatch(setLoading(false));
    }
  }, []);

  // effects
  useEffect(() => {
    console.log('contact state: ', contacts); // for debug
  }, [contacts]);

  useEffect(() => {
    console.log('step: ', step); // for debug
    // scroll to top when step changes
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [step]);

  // set step to 0 when path change
  useEffect(() => {
    dispatch(setStep(0));
  }, [pathname]);

  useEffect(() => {
    console.log('err: ', errors); // for debug
  }, [errors]);

  // use this for debug steps
  //   useEffect(() => {
  //     dispatch(setStep(2));
  //   }, []);

  // main return

  return (
    <StyledPaymnetPage step={step}>
      <form onSubmit={handleSubmit(submitRequest)}>
        <StepOne
          handleNextStep={handleNextStep_1_2}
          register={register}
          errors={errors}
          setValue={setValue}
          amount_val={watch('amount')}
          description_val={watch('description')}
          isActive={step === 0}
          feature={feature}
        />
        <StepTwo
          isValid={isValid}
          handleNextStepContact={handleNextStep_2_3}
          isActive={step === 1}
          setValue={setValue}
          feature={feature}
          selectedContactId={watch('selected_contact_id')}
          contact_name={watch('contact_name')}
        />
        {/* <StepThree
          isActive={step === 2}
          contacts={contacts}
          setValue={setValue}
          feature={feature}
        /> */}
        <StepThreeQR isActive={step === 2.1} setValue={setValue} feature={feature} />
        <StepFour
          register={register}
          errors={errors}
          setValue={setValue}
          amount_val={watch('amount')}
          description_val={watch('description')}
          isActive={step === 2}
          contact_name={watch('contact_name')}
          feature={feature}
        />
      </form>
    </StyledPaymnetPage>
  );
};
