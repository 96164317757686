import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '.';

type IState = {
  step: number;
  isVerify: boolean;
  isLoggedIn: boolean;
  inCompleteSession: string;
  query_id: string;
  user: {
    query_id: string;
    id: number;
    created_at: string;
    updated_at: string;
    user_tele_id: string | number;
    phone_number: string;
    session?: string;
    userChat?: {
      id: string;
      user_id: string;
      user_tele_id: string;
      username: string | null;
      full_name: string;
      is_recovered: boolean;
      account_type: 'CUSTOMER' | 'MERCHANT';
      query_id: string;
    } | null;
  };
  recoverEmail: string;
  next_route_after_enter: string;
  callback_after_enter?: any;
  // socket io
  socketConnected: boolean;
};

const initialState: IState = {
  step: 0,
  isLoggedIn: false,
  inCompleteSession: '',
  isVerify: false,
  recoverEmail: '',
  query_id: '',
  user: {
    query_id: '',
    id: 0,
    created_at: '',
    updated_at: '',
    user_tele_id: '',
    phone_number: '',
    session: undefined,
  },
  next_route_after_enter: '',
  callback_after_enter: undefined,
  socketConnected: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setIsVerify: (state, action) => {
      state.isVerify = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRecoverEmail: (state, action) => {
      state.recoverEmail = action.payload;
    },
    resetUser: (state) => {
      state.user = {
        query_id: '',
        id: 0,
        created_at: '',
        updated_at: '',
        user_tele_id: '',
        phone_number: '',
        session: undefined,
      };
    },
    setQueryId: (state, action) => {
      state.query_id = action.payload;
    },
    setSession: (state, action) => {
      state.user.session = action.payload;
    },
    setInCompleteSession: (state, action) => {
      // @ts-ignore
      state.inCompleteSession = action.payload;
    },
    resetAllAuth: () => initialState,
    setRouteAfterEnterPin: (state, action) => {
      state.next_route_after_enter = action.payload;
    },
    setCallbackAfterEnterPin: (state, action) => {
      state.callback_after_enter = action.payload;
    },

    // socket io
    setSocketIsConnected: (state, action) => {
      state.socketConnected = action.payload;
    },
  },
});

export const {
  setStep,
  setIsVerify,
  setUser,
  setSession,
  setIsLoggedIn,
  setInCompleteSession,
  resetUser,
  resetAllAuth,
  setRecoverEmail,
  setCallbackAfterEnterPin,
  setRouteAfterEnterPin,
  setSocketIsConnected,
  setQueryId,
} = authSlice.actions;

export const selectStep = (state: RootState) => state.auth.step;
export const selectRecoverEmail = (state: RootState) => state.auth.recoverEmail;
export const selectIsVerify = (state: RootState) => state.auth.isVerify;
export const selectUser = (state: RootState) => state.auth.user;
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectInCompleteSession = (state: RootState) => state.auth.inCompleteSession;
export const selectRouteAfterEnterPin = (state: RootState) => state.auth.next_route_after_enter;
export const selectCallbackAfterEnterPin = (state: RootState) => state.auth.callback_after_enter;
export const selectSocketConnected = (state: RootState) => state.auth.socketConnected;
export const selectQueryId = (state: RootState) => state.auth.query_id;

export default authSlice.reducer;
