import * as React from 'react';
// import { Dayjs } from "dayjs";
import { IconButton, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers/MobileDatePicker';
import styled from 'styled-components';
import InputAdornment from '@mui/material/InputAdornment';
import EventIcon from '@mui/icons-material/Event';

type IShareDatePicker = {
  touch?: boolean;
  error?: string;
  placeholder?: string;
  name?: string;
  label?: string;
  request?: boolean;
  handleChange?: (e: any) => void;
  handleBlur?: (e?: any) => void;
  value?: Date | undefined;
  disable?: boolean;
  maxDate?: Date; // MM/DD/YYYY
  minDate?: Date; // MM/DD/YYYY
  startDate?: Date;
  endDate?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  isClearable?: boolean;
  className?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  inputFormat?: string;
};

export function BasicDatePicker(props: IShareDatePicker) {
  //   const [value, setValue] = React.useState<Dayjs | null>(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container className={props.className ? props.className : ''}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          disabled={props.disable}
          inputFormat={props?.inputFormat || 'DD/MM/YYYY'}
          open={open}
          label={props.label}
          value={props.value}
          onOpen={handleOpen}
          onChange={(newValue) => {
            if (props.handleChange) props.handleChange(newValue);
          }}
          onClose={() => {
            if (props.handleBlur) props.handleBlur();
            handleClose();
          }}
          renderInput={(params) => <TextField {...params} />}
          disableFuture={props?.disableFuture}
          disablePast={props?.disablePast}
          InputProps={{
            sx: { width: '100%' },

            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={handleOpen}>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </LocalizationProvider>
      {props?.error && <p className="err_message">{props.error}</p>}
    </Container>
  );
}

const Container = styled.div`
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      border-radius: 8px;
    }
  }
  .MuiFormControl-root {
    width: 100% !important;
  }

  .err_message {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #e62525;
    margin-top: 8px;
  }
`;
