import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { IChargeBackOrderInfo } from '@/interfaces';
import { handleDecimalPrice } from '@/utils';

type IProps = {
  chargeBackInfo: IChargeBackOrderInfo[];
  original_total: string;
};

export const ChargeBackOrderInfo = (props: IProps) => {
  const { chargeBackInfo, original_total } = props;

  // functions
  const sumChargeBack = (data: IChargeBackOrderInfo[], type: 'amount' | 'gst') => {
    let result = 0;

    if (type === 'amount') {
      data.forEach((item) => {
        result += Number(item?.trxns?.[0]?.gross_amount);
      });
    } else {
      data.forEach((item) => {
        result += Number(item?.trxns?.[0]?.gst_amount);
      });
    }

    return Math.abs(result);
  };

  // main return
  return (
    <StyledChargeBackOrderInfo>
      <p className="order_chargeback_title">ChargeBack History</p>
      {chargeBackInfo?.length
        ? chargeBackInfo.map((item, index) => {
            return (
              <div className="charge_back_item" key={item?.id}>
                <p className="charge_back_item_time">
                  {dayjs(item?.created_at).format('YYYY/MM/DD - HH:mm:ss')}
                </p>

                <div className="charge_back_info_item">
                  <div className="charge_back_info_item_name">
                    <p className="charge_back_info_item_name_title">ChargeBack Adj</p>
                  </div>
                  <p className="charge_back_info_item_value">
                    ${handleDecimalPrice(Number(-item?.trxns[0]?.net_amount))}
                  </p>
                </div>

                <div className="charge_back_info_item">
                  <div className="charge_back_info_item_name">
                    <p className="charge_back_info_item_name_title">ChargeBack GST adj</p>
                  </div>
                  <p className="charge_back_info_item_value">
                    ${handleDecimalPrice(Number(-item?.trxns[0]?.gst_amount))}
                  </p>
                </div>

                {item?.trxns[0]?.description ? (
                  <div className="charge_back_item_reason">
                    <p className="charge_back_item_reason_title">Reason</p>
                    <p className="charge_back_item_reason_content">{item?.trxns[0]?.description}</p>
                  </div>
                ) : null}
              </div>
            );
          })
        : null}

      <div className="charge_back_info_item m-0">
        <p className="charge_back_info_item_total_title">Total ChargeBack Adj</p>
        <p className="charge_back_info_item_total_value">
          ${handleDecimalPrice(sumChargeBack(chargeBackInfo, 'amount'))}
        </p>
      </div>

      <div className="charge_back_info_item m-0">
        <p className="charge_back_info_item_total_title">Total ChargeBack GST Adj</p>
        <p className="charge_back_info_item_total_value">
          ${handleDecimalPrice(sumChargeBack(chargeBackInfo, 'gst'))}
        </p>
      </div>

      <div className="charge_back_info_item m-0">
        <p className="charge_back_info_item_total_title">
          New Total {sumChargeBack(chargeBackInfo, 'gst') > 0 ? '(Incl GST)' : ''}
        </p>
        <p className="charge_back_info_item_total_value">
          ${handleDecimalPrice(Number(original_total) - sumChargeBack(chargeBackInfo, 'amount'))}
        </p>
      </div>
    </StyledChargeBackOrderInfo>
  );
};

const StyledChargeBackOrderInfo = styled.div`
  margin-top: 24px;
  border-top: 1px solid #d5d4db;
  padding-top: 20px;

  .m-0 {
    margin: 0 !important;
  }

  .order_chargeback_title {
    color: #8e8e93;
    font-weight: 600;
    font-size: 15px;
    margin: 0;
    margin-bottom: 16px;
  }

  .charge_back_item {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px dashed #d5d4db;

    .charge_back_item_time {
      margin: 0;
      margin-bottom: 5px;
      color: #8e8e93;
      font-weight: 600;
      font-size: 14px;
    }

    .charge_back_item_reason {
      width: 100%;

      .charge_back_item_reason_title {
        margin: 0;
        margin-bottom: 5px;
        color: #8e8e93;
        font-weight: 600;
        font-size: 14px;
      }

      .charge_back_item_reason_content {
        color: #171717;
        font-weight: 500;
        font-size: 14px;
        max-width: 100%;
        overflow: hidden;
        margin: 0;
      }
    }
  }

  .charge_back_info_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;

    .charge_back_info_item_name {
      /* display: flex;
        align-items: center; */

      /* display: grid;
        grid-template-columns: 3fr 1fr; */

      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      flex-wrap: wrap;

      max-width: 75%;
      overflow: hidden;

      .charge_back_info_item_name_title {
        color: #171717;
        font-weight: 500;
        font-size: 15px;
        margin: 0;
        margin-right: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .charge_back_info_item_name_desc {
        color: #8f8f8f;
        font-weight: 500;
        font-size: 15px;
        margin: 0;
        /* display: flex;
          align-items: center; */
      }
    }

    .charge_back_info_item_value {
      color: #171717;
      font-weight: 600;
      font-size: 15px;
      margin: 0;
    }

    .charge_back_info_item_total_title {
      color: #171717;
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    .charge_back_info_item_total_value {
      color: #171717;
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }
  }
`;
