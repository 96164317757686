import { Breadcrumbs, Link as StyledLink } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  PATH_ACCOUNT_CONTACT,
  PATH_ACCOUNT_CONTACT_VERIFY,
  PATH_ADD_PAY_ID,
  PATH_ADMIN_HOME,
  PATH_ID_DOCS,
  PATH_PAY_ID,
  PATH_PERSONAL_DETAILS,
  PATH_QR_CODE,
  PATH_QR_RECEIVE,
  PATH_QR_SHARE,
  PATH_RECOVER_ACCOUNT,
  PATH_REQUEST_PAYMENT,
  PATH_RES_ADDRESS,
  PATH_TRXN_HISTORY,
  PATH_VERIFY_PAY_ID,
} from '@router';

import { memo } from 'react';

interface IProps {
  no_admin_path?: boolean;
}

export const Breadcrumb = memo(function Breadcrumb(props: IProps) {
  const { no_admin_path } = props;
  const navigate = useNavigate();

  const breadcrumbNameMap = {
    // [PATH_ADMIN_HOME]: "Admin",
    [PATH_RES_ADDRESS]: 'Res.Address',
    [PATH_PERSONAL_DETAILS]: 'Personal Details',
    [PATH_PAY_ID]: 'Your PayIDs',
    [`${PATH_PAY_ID}/${PATH_ADD_PAY_ID}`]: 'Add PayIDs',
    [PATH_VERIFY_PAY_ID]: 'Verify PayIDs',
    [PATH_ACCOUNT_CONTACT]: 'Account Contact',
    [`${PATH_ACCOUNT_CONTACT}/${PATH_ACCOUNT_CONTACT_VERIFY}`]: 'Verify',
    [PATH_RECOVER_ACCOUNT]: 'Recover Account',
    [PATH_ID_DOCS]: 'ID Docs',
    [PATH_PAY_ID]: 'Your PayIDs',
    [PATH_ADD_PAY_ID]: 'Add PayIDs',
    [PATH_VERIFY_PAY_ID]: 'Verify PayIDs',
    [PATH_QR_CODE]: 'QR Code',
    [PATH_QR_RECEIVE]: 'QR Receive',
    [PATH_QR_SHARE]: 'QR Share',
    [PATH_REQUEST_PAYMENT]: 'Request Payment',
    [PATH_TRXN_HISTORY]: 'Trxn History',
    [`${PATH_TRXN_HISTORY}/search`]: 'Search Trxn',
  };

  const dynamicPath: Array<string> = [];

  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `${pathSnippets.slice(0, index + 1).join('/')}`;
    // @ts-ignore
    if (breadcrumbNameMap[url]) {
      return (
        <StyledLink
          key={url}
          className={
            index + 1 === pathSnippets.length || dynamicPath.includes(url)
              ? 'breadcrumb-item active'
              : 'breadcrumb-item'
          }
        >
          {index + 1 === pathSnippets.length || dynamicPath.includes(url) ? (
            // @ts-ignore
            <div className="item active">{breadcrumbNameMap[url]}</div>
          ) : (
            // @ts-ignore
            <div className="item" onClick={() => navigate(`/${url}`, { replace: false })}>
              {breadcrumbNameMap[url]}
            </div>
          )}
        </StyledLink>
      );
    }

    return <></>;
  });

  const breadcrumbItems = no_admin_path
    ? extraBreadcrumbItems
    : [
        <StyledLink key="dashboard" className="breadcrumb-item">
          <div className="item" onClick={() => navigate(`/${PATH_ADMIN_HOME}`, { replace: false })}>
            Admin
          </div>
        </StyledLink>,
        // @ts-ignore
      ].concat(extraBreadcrumbItems);

  if (location.pathname.includes(PATH_QR_CODE)) breadcrumbItems.shift();

  return (
    <StyledBreadcrumb>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        {breadcrumbItems}
      </Breadcrumbs>
    </StyledBreadcrumb>
  );
});

const StyledBreadcrumb = styled.div`
  margin-top: 24px;
  .item {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-decoration: none;
    color: #0a84ff;
    opacity: 1;
    &.active {
      opacity: 0.8;
      color: #171717;
      cursor: default;
    }
    cursor: pointer;
  }
  .MuiTypography-colorTextSecondary {
    color: #a5a5a5;
  }
  .MuiLink-underlineHover:hover {
    text-decoration: none;
  }
  .MuiBreadcrumbs-separator {
    font-size: 24px;
    line-height: 24px;
  }
`;
