export enum PayIdTypeEnum {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  ABN_ACN = 'ABNACN',
}

export enum TypeTransactionEnum {
  TOPUP = 10,
  WITHDRAW = 11,
  TRANSFER = 12,
  TIPPE_SONIC = 13,
  QR = 14,
  REQUEST_PAYMEN = 15,
  GST_TRANSFER = 16,
  FEE_TRANSFER = 17,
  ACCOUNT_MERGE = 18,
}

export enum StatusOrderEnum {
  PAID = 'PAID',
  PENDING = 'PENDING',
  FAIL = 'FAIL',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum TypeQrEnum {
  TRANSFER = 'TRANSFER',
  INVOICE = 'INVOICE',
}

export enum AccountTypeEnum {
  CUSTOMER = 'CUSTOMER',
  MERCHANT = 'MERCHANT',
}

export enum DirectionTrxnEnum {
  PAY = 'PAY',
  RECEIVE = 'RECEIVE',
}
