import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { IPayInvoiceResponse } from '@/interfaces';
import { StatusOrderEnum } from '@/configs';

type IProps = {
  orderDetailRes?: IPayInvoiceResponse;
};

export const BasicInfoOrder = (props: IProps) => {
  const { orderDetailRes } = props;

  // main return
  return (
    <StyledBasicInfoOrder>
      <div className="basic_info_item">
        <p className="basic_info_item_title">Updated Time</p>
        <p className="basic_info_item_value">
          {dayjs(orderDetailRes?.updated_at).format('YYYY/MM/DD - HH:mm')}
        </p>
      </div>

      <div className="basic_info_item">
        <p className="basic_info_item_title">Name</p>
        <p className="basic_info_item_value">{orderDetailRes?.name}</p>
      </div>

      <div className="basic_info_item">
        <p className="basic_info_item_title">My Number #</p>
        <p className="basic_info_item_value">{orderDetailRes?.payer_number}</p>
      </div>

      <div className="basic_info_item">
        <p className="basic_info_item_title">Their Number #</p>
        <p className="basic_info_item_value">{orderDetailRes?.receiver_number}</p>
      </div>

      <div className="basic_info_item">
        <p className="basic_info_item_title">My Trxn Ref</p>
        <p className="basic_info_item_value">{orderDetailRes?.my_trxn_ref}</p>
      </div>

      <div className="basic_info_item">
        <p className="basic_info_item_title">Their Trxn Ref</p>
        <p className="basic_info_item_value">{orderDetailRes?.their_trxn_ref}</p>
      </div>

      <div className="basic_info_item">
        <p className="basic_info_item_title">Order Status</p>
        <p
          className={`basic_info_item_value ${
            (orderDetailRes?.status === StatusOrderEnum?.CANCELED && 'cancel') ||
            (orderDetailRes?.status === StatusOrderEnum?.PAID && 'paid') ||
            (orderDetailRes?.status === StatusOrderEnum?.PENDING && 'pending') ||
            (orderDetailRes?.status === StatusOrderEnum?.FAIL && 'fail')
          }`}
        >
          {orderDetailRes?.status}
        </p>
      </div>
    </StyledBasicInfoOrder>
  );
};

const StyledBasicInfoOrder = styled.div`
  margin: 0px 0;
  padding: 16px 0px;
  border-bottom: 1px solid #d5d4db;

  .basic_info_item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    /* margin-bottom: 16px; */

    .basic_info_item_title {
      color: #171717;
      font-weight: 400;
      font-size: 16px;
      opacity: 0.5;
      margin: 0;
      max-width: 35%;
      overflow: hidden;
    }

    .basic_info_item_value {
      margin: 0;
      max-width: 70%;
      overflow: hidden;
      color: #171717;
      font-weight: 500;
      font-size: 16px;
    }
  }
`;
