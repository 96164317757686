import React from 'react';
import { Button, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import { PATH_HOME } from '@router';
import logo from '@assets/Two_step_success.svg';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    maxWidth: '268px',
    margin: 'auto',
  },
  titleWrapper: {
    width: '100%',
  },
  sendButton: {
    width: '100%',
    backgroundColor: '#0A84FF',
    borderRadius: '10px',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&.MuiButton-containedSizeLarge': {
      padding: '13px',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
    },
  },
  buttonWrapper: {
    left: 0,
    bottom: 0,
    right: 0,
    position: 'fixed',
    backgroundColor: '#F6F6F6',
    boxShadow: '0px -0.33px 0px #D8D8D8',
    padding: '8px',
  },
  logoWrapper: {
    marginTop: '49px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
}));

export function CreatePasswordSuccessPage() {
  const classes = useStyles();
  const navigate = useNavigate();

  const backToMenu = () => {
    navigate(PATH_HOME);
  };

  return (
    <Container>
      <div className={classes.logoWrapper}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={logo} />
      </div>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}>Your password has been set successfully!</Typography>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.sendButton}
          onClick={backToMenu}
          size="large"
          type="button"
          variant="contained"
          color="primary"
        >
          Back to Menu
        </Button>
      </div>
    </Container>
  );
}
