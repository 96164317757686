import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import QRCode from 'react-qr-code';
import dayjs from 'dayjs';

import { Input, IconCoinNoBackground, Breadcrumb, MainButton, IconQRCodeLite } from '@components';
import { useAppDispatch, useAppSelector, selectUser, setLoading, openToast } from '@redux';
import { IBalanceInfo, IQRReceiveRequestData, IQRReceiveResponseData } from '@/interfaces';
import { formatCurrency } from '@/libs/helper';
import { regexRemoveAllUnicodeEmojis, telegramClient } from '@/configs';
import { qrCodeApi } from '@/api';
import { useBalance } from '@/hooks';

export function QRReceivePage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const [userInfo, setUserInfo] = useState<IQRReceiveResponseData>();

  const balanceSchema = Yup.object().shape({
    amount: Yup.number()
      .transform((value, originalValue) => {
        return originalValue === '' ? undefined : value;
      })
      .typeError('Please enters an Amount with a number.')
      .min(0.001, 'Amount must be greater than $0.00, please enter a valid value.')
      .max(10000, 'Please enters an Amount less than $10,000.')
      // .test('len', 'Please enters an Amount no more than 12 characters', (val) => {
      //   if (val) {
      //     return val?.toString()?.length <= 12;
      //   }

      //   return true;
      // })
      .required('Please enter an amount to proceed with the payment.'),
    description: Yup.string().trim().max(255, 'The Description field no more than 255 characters'),
  });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    watch,
  } = useForm<IBalanceInfo>({
    mode: 'onTouched',
    resolver: yupResolver(balanceSchema),
  });

  const onSubmit = async (formData: IBalanceInfo) => {
    try {
      const params: IQRReceiveRequestData = {
        receiver_user_id: Number(user?.user_tele_id),
        auth_date:
          telegramClient?.initDataUnsafe?.auth_date || dayjs(new Date()).valueOf().toString(),
        amount: formData?.amount?.toLocaleString('fullwide', { useGrouping: false }),
        description: formData?.description,
      };
      dispatch(setLoading(true));
      const res = await qrCodeApi.createPaymentQRcode(params);

      const data: IQRReceiveResponseData = res?.data?.data;

      console.log('res: ', data); // for debug

      // set states
      setUserInfo(data);
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          message: 'Something went wrong, please try again',
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const { balance } = useBalance(user?.user_tele_id);

  // this function only allows up to 2 decimal places
  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = e?.target?.value
        ?.replace(/[^0-9.]/g, '')
        ?.replace(/^(\d+.?\d{0,2})\d*$/, '$1')
        ?.replaceAll(',', '.');
      setValue('amount', value, { shouldValidate: true });
    } catch (error) {
      console.log(error);
    }
  };

  // Remove emojis
  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // const value = e?.target?.value?.replace(regexRemoveAllUnicodeEmojis, '');
      const value = e?.target?.value;
      setValue('description', value, { shouldValidate: true });
    } catch (error) {
      console.log(error);
    }
  };

  // main return
  return (
    <StyledQRReceivePage>
      <Container>
        <Breadcrumb />
        {!userInfo ? (
          <>
            <div className="balance_container">
              <div className="title">Available Balance</div>
              <div className="balance">AUD:{formatCurrency(Number(balance) || 0)}</div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Input
                className="mb-24 row_item"
                placeholder="Amount"
                name="amount"
                // register={register('amount')}
                error={errors.amount}
                preIcon={<IconCoinNoBackground />}
                type="number"
                onChange={handleChangeAmount}
                value={watch('amount')?.toString()}
              />
              <Input
                className="mb-24 row_item"
                placeholder="Description"
                name="description"
                // register={register('description')}
                value={watch('description')}
                onChange={handleChangeDescription}
                error={errors.description}
              />
              <MainButton
                text="Generate QR Code"
                type="submit"
                preIcon={<IconQRCodeLite />}
                disabled={!isValid}
              />
            </form>
          </>
        ) : (
          <>
            <div className="qr_code_wrapper">
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={JSON.stringify(userInfo)}
                viewBox="0 0 256 256"
              />
            </div>
            <div className="user_info">
              {userInfo?.fullname && <div className="full_name">{userInfo?.fullname}</div>}
              <div className="username">Scan to pay {userInfo?.username || userInfo?.fullname}</div>
            </div>
          </>
        )}
      </Container>
    </StyledQRReceivePage>
  );
}

const StyledQRReceivePage = styled.div`
  margin-bottom: 72px;
  .balance_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 12px;
    margin-bottom: 40px;
    margin-top: 24px;
    padding: 32px 0;
    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0a84ff;
      margin-bottom: 12px;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
    }
  }
  .qr_code_wrapper {
    margin-top: 90px;
    padding: 0 35px;
    max-width: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
  .user_info {
    margin-top: 36px;
    margin-bottom: 24px;
    & .full_name {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 160%;
      text-align: center;
      margin-bottom: 8px;
    }
    & .username {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      text-align: center;
      opacity: 0.7;
    }
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .w-full {
    width: 100%;
  }
`;
