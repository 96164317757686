import React from 'react';

export function VerifyPayID() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#3CB7FC" />
      <path
        d="M15.725 9.47007V10.7251C15.725 11.0801 15.365 11.3101 15.03 11.1851C14.61 11.0301 14.145 10.9701 13.655 11.0201C12.465 11.1501 11.245 12.2951 11.045 13.4801C10.88 14.4651 11.195 15.3851 11.8 16.0351C12.075 16.3351 11.89 16.8201 11.485 16.8651C11.14 16.9051 10.8 16.8951 10.61 16.7551L7.86002 14.7001C7.53502 14.4551 7.27002 13.9251 7.27002 13.5151V9.47007C7.27002 8.90507 7.70002 8.28507 8.22502 8.08507L10.975 7.05507C11.26 6.95007 11.73 6.95007 12.015 7.05507L14.765 8.08507C15.295 8.28507 15.725 8.90507 15.725 9.47007Z"
        fill="white"
      />
      <path
        d="M14 11.7549C12.76 11.7549 11.75 12.7649 11.75 14.0049C11.75 15.2449 12.76 16.2549 14 16.2549C15.24 16.2549 16.25 15.2449 16.25 14.0049C16.25 12.7599 15.24 11.7549 14 11.7549Z"
        fill="white"
      />
      <path
        d="M16.5 17C16.365 17 16.24 16.945 16.145 16.855C16.125 16.83 16.1 16.805 16.085 16.775C16.065 16.75 16.05 16.72 16.04 16.69C16.025 16.66 16.015 16.63 16.01 16.6C16.005 16.565 16 16.535 16 16.5C16 16.435 16.015 16.37 16.04 16.31C16.065 16.245 16.1 16.195 16.145 16.145C16.26 16.03 16.435 15.975 16.595 16.01C16.63 16.015 16.66 16.025 16.69 16.04C16.72 16.05 16.75 16.065 16.775 16.085C16.805 16.1 16.83 16.125 16.855 16.145C16.9 16.195 16.935 16.245 16.96 16.31C16.985 16.37 17 16.435 17 16.5C17 16.63 16.945 16.76 16.855 16.855C16.83 16.875 16.805 16.895 16.775 16.915C16.75 16.935 16.72 16.95 16.69 16.96C16.66 16.975 16.63 16.985 16.595 16.99C16.565 16.995 16.53 17 16.5 17Z"
        fill="white"
      />
    </svg>
  );
}
