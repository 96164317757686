import {
  AccountContact,
  IdDocs,
  MenuButton,
  PersonalDetail,
  RecoverAccount,
  ResAddress,
  VerifyPayID,
  YourPayId,
} from '@components';
import { selectIsVerify, useAppSelector, selectApp } from '@redux';
import {
  PATH_ACCOUNT_CONTACT,
  PATH_ID_DOCS,
  PATH_PAY_ID,
  PATH_PERSONAL_DETAILS,
  PATH_RECOVER_ACCOUNT,
  PATH_RES_ADDRESS,
  PATH_VERIFY_PAY_ID,
} from '@router';
import styled from 'styled-components';

export function ProfilePage() {
  const isVerify = useAppSelector(selectIsVerify);
  const selectAppA = useAppSelector(selectApp);
  console.log('selectApp: ', selectAppA);

  const profileItems = [
    {
      text: 'Personal Details',
      id: 1,
      path: PATH_PERSONAL_DETAILS,
      icon: <PersonalDetail />,
      disabled: !isVerify,
    },
    {
      text: 'Account Contact',
      id: 2,
      path: PATH_ACCOUNT_CONTACT,
      icon: <AccountContact />,
      disabled: false,
    },
    {
      text: 'Res. Address',
      id: 3,
      path: PATH_RES_ADDRESS,
      icon: <ResAddress />,
      disabled: !isVerify,
    },
    {
      text: 'ID Docs',
      id: 4,
      path: PATH_ID_DOCS,
      icon: <IdDocs />,
      disabled: !isVerify,
    },
  ];

  const accountItems = [
    {
      text: 'Your PayIDs',
      id: 5,
      path: PATH_PAY_ID,
      icon: <YourPayId />,
      disabled: !isVerify,
    },
    {
      text: 'Verify PayID',
      id: 6,
      path: PATH_VERIFY_PAY_ID,
      icon: <VerifyPayID />,
      disabled: !isVerify,
    },
  ];

  const extraItems = [
    {
      text: 'Recover Account',
      id: 7,
      path: PATH_RECOVER_ACCOUNT,
      icon: <RecoverAccount />,
      disabled: false,
    },
  ];

  // main return
  return (
    <StyledStartPage>
      <div className="title_container">
        <div className="title">Admin</div>
      </div>

      <div className="menu">
        <div className="menu-title">PROFILE</div>
        <div className="menu-btn-container">
          {profileItems.map((action: any) => (
            <MenuButton
              key={action.id}
              text={action.text}
              path={action.path}
              icon={action.icon}
              disabled={action.disabled}
            />
          ))}
        </div>
      </div>

      <div className="menu">
        <div className="menu-title">ACCOUNT</div>
        <div className="menu-btn-container">
          {accountItems.map((action: any) => (
            <MenuButton
              key={action.id}
              text={action.text}
              path={action.path}
              icon={action.icon}
              disabled={action.disabled}
            />
          ))}
        </div>
      </div>

      <div className="menu" style={{ borderBottom: 'none', paddingTop: '10px' }}>
        <div className="menu-btn-container">
          {extraItems.map((action: any) => (
            <MenuButton
              key={action.id}
              text={action.text}
              path={action.path}
              icon={action.icon}
              disabled={action.disabled}
            />
          ))}
        </div>
      </div>
    </StyledStartPage>
  );
}

const StyledStartPage = styled.div`
  padding: 23px 16px 0 16px;
  .title_container {
    border-bottom: 1px solid #e7e6f0;
    padding-top: 20px;
    .title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 12px;
    }
  }
  .menu {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e6f0;

    .menu-title {
      font-weight: 800;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: #868493;
    }
    .menu-btn-container {
      padding-top: 16px;
      display: grid;
      grid-row-gap: 24px;
      grid-column-gap: 10px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
