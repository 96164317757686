import React from 'react';

export function IconEyeOff() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M21.2699 9.18C20.9799 8.72 20.6699 8.29 20.3499 7.89C19.9799 7.42 19.2799 7.38 18.8599 7.8L15.8599 10.8C16.0799 11.46 16.1199 12.22 15.9199 13.01C15.5699 14.42 14.4299 15.56 13.0199 15.91C12.2299 16.11 11.4699 16.07 10.8099 15.85C10.8099 15.85 9.37995 17.28 8.34995 18.31C7.84995 18.81 8.00995 19.69 8.67995 19.95C9.74995 20.36 10.8599 20.57 11.9999 20.57C13.7799 20.57 15.5099 20.05 17.0899 19.08C18.6999 18.08 20.1499 16.61 21.3199 14.74C22.2699 13.23 22.2199 10.69 21.2699 9.18Z"
          fill="white"
        />
        <path
          d="M21.2699 9.18C20.9799 8.72 20.6699 8.29 20.3499 7.89C19.9799 7.42 19.2799 7.38 18.8599 7.8L15.8599 10.8C16.0799 11.46 16.1199 12.22 15.9199 13.01C15.5699 14.42 14.4299 15.56 13.0199 15.91C12.2299 16.11 11.4699 16.07 10.8099 15.85C10.8099 15.85 9.37995 17.28 8.34995 18.31C7.84995 18.81 8.00995 19.69 8.67995 19.95C9.74995 20.36 10.8599 20.57 11.9999 20.57C13.7799 20.57 15.5099 20.05 17.0899 19.08C18.6999 18.08 20.1499 16.61 21.3199 14.74C22.2699 13.23 22.2199 10.69 21.2699 9.18Z"
          fill="#171717"
        />
        <path
          d="M14.0199 9.98L9.97989 14.02C9.46989 13.5 9.13989 12.78 9.13989 12C9.13989 10.43 10.4199 9.14 11.9999 9.14C12.7799 9.14 13.4999 9.47 14.0199 9.98Z"
          fill="white"
        />
        <path
          d="M14.0199 9.98L9.97989 14.02C9.46989 13.5 9.13989 12.78 9.13989 12C9.13989 10.43 10.4199 9.14 11.9999 9.14C12.7799 9.14 13.4999 9.47 14.0199 9.98Z"
          fill="#171717"
        />
        <path
          d="M18.25 5.75L14.86 9.14C14.13 8.4 13.12 7.96 12 7.96C9.76 7.96 7.96 9.77 7.96 12C7.96 13.12 8.41 14.13 9.14 14.86L5.76 18.25H5.75C4.64 17.35 3.62 16.2 2.75 14.84C1.75 13.27 1.75 10.72 2.75 9.15C3.91 7.33 5.33 5.9 6.91 4.92C8.49 3.96 10.22 3.43 12 3.43C14.23 3.43 16.39 4.25 18.25 5.75Z"
          fill="white"
        />
        <path
          d="M18.25 5.75L14.86 9.14C14.13 8.4 13.12 7.96 12 7.96C9.76 7.96 7.96 9.77 7.96 12C7.96 13.12 8.41 14.13 9.14 14.86L5.76 18.25H5.75C4.64 17.35 3.62 16.2 2.75 14.84C1.75 13.27 1.75 10.72 2.75 9.15C3.91 7.33 5.33 5.9 6.91 4.92C8.49 3.96 10.22 3.43 12 3.43C14.23 3.43 16.39 4.25 18.25 5.75Z"
          fill="#171717"
        />
        <path
          d="M14.8601 12C14.8601 13.57 13.5801 14.86 12.0001 14.86C11.9401 14.86 11.8901 14.86 11.8301 14.84L14.8401 11.83C14.8601 11.89 14.8601 11.94 14.8601 12Z"
          fill="#292D32"
        />
        <path
          d="M21.7699 2.23C21.4699 1.93 20.9799 1.93 20.6799 2.23L2.22988 20.69C1.92988 20.99 1.92988 21.48 2.22988 21.78C2.37988 21.92 2.56988 22 2.76988 22C2.96988 22 3.15988 21.92 3.30988 21.77L21.7699 3.31C22.0799 3.01 22.0799 2.53 21.7699 2.23Z"
          fill="#292D32"
        />
      </g>
    </svg>
  );
}
