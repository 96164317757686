import React from 'react';

export const IconQRReceive = () => {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="36" height="36" rx="18" fill="#3C71FC" />
      <path
        d="M10.1667 16.1253C9.82508 16.1253 9.54175 15.842 9.54175 15.5003V13.417C9.54175 11.0003 11.5084 9.04199 13.9167 9.04199H16.0001C16.3417 9.04199 16.6251 9.32533 16.6251 9.66699C16.6251 10.0087 16.3417 10.292 16.0001 10.292H13.9167C12.1917 10.292 10.7917 11.692 10.7917 13.417V15.5003C10.7917 15.842 10.5084 16.1253 10.1667 16.1253Z"
        fill="white"
      />
      <path
        d="M26.8333 16.1253C26.4917 16.1253 26.2083 15.842 26.2083 15.5003V13.417C26.2083 11.692 24.8083 10.292 23.0833 10.292H21C20.6583 10.292 20.375 10.0087 20.375 9.66699C20.375 9.32533 20.6583 9.04199 21 9.04199H23.0833C25.4917 9.04199 27.4583 11.0003 27.4583 13.417V15.5003C27.4583 15.842 27.175 16.1253 26.8333 16.1253Z"
        fill="white"
      />
      <path
        d="M23.0833 26.958H21.8333C21.4916 26.958 21.2083 26.6747 21.2083 26.333C21.2083 25.9913 21.4916 25.708 21.8333 25.708H23.0833C24.8083 25.708 26.2083 24.308 26.2083 22.583V21.333C26.2083 20.9913 26.4916 20.708 26.8333 20.708C27.1749 20.708 27.4583 20.9913 27.4583 21.333V22.583C27.4583 24.9997 25.4916 26.958 23.0833 26.958Z"
        fill="white"
      />
      <path
        d="M16.0001 26.9583H13.9167C11.5084 26.9583 9.54175 25 9.54175 22.5833V20.5C9.54175 20.1583 9.82508 19.875 10.1667 19.875C10.5084 19.875 10.7917 20.1583 10.7917 20.5V22.5833C10.7917 24.3083 12.1917 25.7083 13.9167 25.7083H16.0001C16.3417 25.7083 16.6251 25.9917 16.6251 26.3333C16.6251 26.675 16.3417 26.9583 16.0001 26.9583Z"
        fill="white"
      />
      <path
        d="M16 12.375H14.3333C13.3833 12.375 12.875 12.875 12.875 13.8333V15.5C12.875 16.4583 13.3833 16.9583 14.3333 16.9583H16C16.95 16.9583 17.4583 16.4583 17.4583 15.5V13.8333C17.4583 12.875 16.95 12.375 16 12.375Z"
        fill="white"
      />
      <path
        d="M22.6667 12.375H21.0001C20.0501 12.375 19.5417 12.875 19.5417 13.8333V15.5C19.5417 16.4583 20.0501 16.9583 21.0001 16.9583H22.6667C23.6167 16.9583 24.1251 16.4583 24.1251 15.5V13.8333C24.1251 12.875 23.6167 12.375 22.6667 12.375Z"
        fill="white"
      />
      <path
        d="M16 19.042H14.3333C13.3833 19.042 12.875 19.542 12.875 20.5003V22.167C12.875 23.1253 13.3833 23.6253 14.3333 23.6253H16C16.95 23.6253 17.4583 23.1253 17.4583 22.167V20.5003C17.4583 19.542 16.95 19.042 16 19.042Z"
        fill="white"
      />
      <path
        d="M22.6667 19.042H21.0001C20.0501 19.042 19.5417 19.542 19.5417 20.5003V22.167C19.5417 23.1253 20.0501 23.6253 21.0001 23.6253H22.6667C23.6167 23.6253 24.1251 23.1253 24.1251 22.167V20.5003C24.1251 19.542 23.6167 19.042 22.6667 19.042Z"
        fill="white"
      />
    </svg>
  );
};
