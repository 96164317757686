import styled from 'styled-components';

export const StyledPaymnetPage = styled.div<{ step: number }>`
  padding: ${(props) => (props.step === 2.1 ? '0' : '23px 16px 0 16px')};
  margin-bottom: ${(props) => {
    // console.log('style step: ', props.step); // for debug
    if (props.step === 2.1) return '0';

    if (props.step === 1) return '300px';

    return '72px';
  }};

  .op_0_5 {
    opacity: 0.5 !important;
  }

  .w_full {
    width: 100%;
  }

  .btn_submit {
    background-color: #0a84ff;
    color: #ffffff;
  }
  .mb-24 {
    margin-bottom: 24px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .sub_title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .balance_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 32px 0;
    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0a84ff;
      margin-bottom: 12px;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
    }
  }

  .divide {
    margin: 16px 0;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(143, 143, 143, 0.2);
      margin-right: 16px;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(143, 143, 143, 0.2);
      margin-left: 16px;
    }
  }

  .options_btn {
    /* display: grid;
    grid-row-gap: 24px;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, 1fr); */

    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 35px;

    .options_btn_qr {
      text-align: center;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      /* identical to box height */

      opacity: 0.8;
      color: #171717;
      /* border-right: 1px solid rgba(143, 143, 143, 0.2);
      border-left: 1px solid rgba(143, 143, 143, 0.2); */
      /* border: 1px solid rgba(143, 143, 143, 0.2); */
      /* padding: 0 0; */
      padding: 0 30px;
      border-radius: 20px;

      cursor: pointer;
    }

    .options_btn_user {
      text-align: center;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      /* identical to box height */

      opacity: 0.8;
      color: #171717;
      cursor: pointer;
    }
  }

  .sub_desc {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */

    text-align: center;

    opacity: 0.7;
    color: #171717;
  }

  .sub_title_small {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    text-align: center;
    letter-spacing: -0.4px;
    color: #171717;
  }

  .contacts {
    margin-top: 20px;
    padding-top: 12px;
    border-top: 1px solid #f0f0f0;

    .contacts_loading {
      width: 100%;
      text-align: center;
    }

    .contacts_item {
      /* display: flex;
      align-items: center; */

      display: grid;
      grid-row-gap: 24px;
      grid-column-gap: 10px;
      grid-template-columns: repeat(7, 1fr);
      cursor: pointer;
      margin-bottom: 10px;

      .contacts_item_img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        grid-column: 1;
        grid-row: 1;
        img {
          width: 100%;
          height: auto;
        }
      }

      .contacts_item_name {
        grid-column: 2/10;
        grid-row: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        /* identical to box height, or 129% */

        letter-spacing: -0.4px;
        color: #171717;
        border-bottom: 1px solid #f0f0f0;

        span {
          color: #6369ff;
          font-weight: 500;
          opacity: 0.8;
          font-size: 12px;
        }
      }
    }
  }

  // style use for steps (active & inactive)
  .inactive {
    height: 0;
    visibility: hidden;
    display: none;
  }

  .active {
    height: auto;
    visibility: visible;
  }
`;
