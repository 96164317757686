import React from 'react';

export function PersonalDetail() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#3C71FC" />
      <path
        d="M15 7.5H9C7.895 7.5 7 8.39 7 9.485V14.515C7 15.61 7.895 16.5 9 16.5H15C16.105 16.5 17 15.61 17 14.515V9.485C17 8.39 16.105 7.5 15 7.5ZM10.25 9.585C10.885 9.585 11.405 10.105 11.405 10.74C11.405 11.375 10.885 11.895 10.25 11.895C9.615 11.895 9.095 11.375 9.095 10.74C9.095 10.105 9.615 9.585 10.25 9.585ZM12.185 14.33C12.14 14.38 12.07 14.41 12 14.41H8.5C8.43 14.41 8.36 14.38 8.315 14.33C8.27 14.28 8.245 14.21 8.25 14.14C8.335 13.3 9.005 12.635 9.845 12.555C10.11 12.53 10.385 12.53 10.65 12.555C11.49 12.635 12.165 13.3 12.245 14.14C12.255 14.21 12.23 14.28 12.185 14.33ZM15.5 14.375H14.5C14.295 14.375 14.125 14.205 14.125 14C14.125 13.795 14.295 13.625 14.5 13.625H15.5C15.705 13.625 15.875 13.795 15.875 14C15.875 14.205 15.705 14.375 15.5 14.375ZM15.5 12.375H13.5C13.295 12.375 13.125 12.205 13.125 12C13.125 11.795 13.295 11.625 13.5 11.625H15.5C15.705 11.625 15.875 11.795 15.875 12C15.875 12.205 15.705 12.375 15.5 12.375ZM15.5 10.375H13C12.795 10.375 12.625 10.205 12.625 10C12.625 9.795 12.795 9.625 13 9.625H15.5C15.705 9.625 15.875 9.795 15.875 10C15.875 10.205 15.705 10.375 15.5 10.375Z"
        fill="white"
      />
    </svg>
  );
}
