import { Typography } from '@material-ui/core';

import logo from '@assets/Two_step_logo.svg';
import { MainButton } from '@components';
import { useStyles } from './style';

type IProps = {
  handleNextStep(): void;
  isActive: boolean;
};

export function CPStepZero(props: IProps) {
  const classes = useStyles();

  const { handleNextStep, isActive } = props;

  return (
    <div className={`${classes.step} ${isActive ? 'active' : ''}`}>
      <div className={classes.logoWrapper}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={logo} />
        <Typography className={classes.titleStepZero}>2-Step Verification</Typography>
      </div>
      <Typography className={classes.subtitle}>
        You can set a password that will be required when you log in on a new device in addition to
        the code you get in the SMS
      </Typography>
      <MainButton text="Continue" type="button" onClick={handleNextStep} />
    </div>
  );
}
