import { Snackbar } from '@material-ui/core';
import { Alert } from '@mui/material';
import { useDispatch } from 'react-redux';

import { closeToast, selectToast, useAppSelector } from '@redux';

export function Toast() {
  // page hooks
  const toast = useAppSelector(selectToast);
  const dispatch = useDispatch();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeToast());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: toast.vertical || 'top',
        horizontal: toast.horizontal || 'center',
      }}
      open={toast.open}
      autoHideDuration={toast.autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={toast.type}
        sx={{ width: '100%' }}
        // variant="filled"
        elevation={4}
      >
        {toast.message}
      </Alert>
    </Snackbar>
  );
}
