/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { IQRReceiveResponseData } from '@interfaces';
import { IconArrowLeft, IconFlashlight } from '@/components';
import {
  useAppDispatch,
  setPaymentInfo,
  useAppSelector,
  selectUser,
  openToast,
  setLoading,
} from '@/redux';
import { PATH_QR_CODE } from '@/router';
import { qrCodeApi } from '@/api';
import { useViewPort } from '@/hooks';
import { TypeQrEnum } from '@/configs';

export const QRScannerPage = () => {
  const streamRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // redux states
  const user = useAppSelector(selectUser);

  // local states
  const [data, setData] = useState<IQRReceiveResponseData>();

  const { height } = useViewPort();

  const handleScan = (decoded: any) => {
    console.log('data scan qr: ', decoded);
    setData(decoded);
  };

  console.log('viewPortHeight', height);

  // @ts-ignore
  // const [on, toggle] = useTorchLight(streamRef?.current);

  // effects
  useEffect(() => {
    if (data && user?.user_tele_id) {
      console.log('data qr in useEffect: ', data); // for debug
      getPaymentInformation(
        data?.receiver_trxn_ref,
        Number(user.user_tele_id),
        data?.type || data?.trxn_type
      );
    }

    if (!data && data !== undefined) {
      dispatch(
        openToast({
          message: 'Invalid QR code. This QR code has already been used, please scan another one.',
          type: 'error',
        })
      );
      navigate(`/${PATH_QR_CODE}`);
      setData(undefined);
      dispatch(setLoading(false));
    }
  }, [data]);

  useEffect(() => {
    console.log('local state => data: ', data); // for debug
  }, [data]);

  useEffect(() => {
    console.log('redux state => user_tele_id: ', user?.user_tele_id); // for debug
  }, [user?.user_tele_id]);

  useEffect(() => {
    if (navigator) console.log('navigator', navigator);
    if (navigator?.permissions) console.log('permissions', navigator.permissions);
    // @ts-ignore
    navigator?.permissions?.query({ name: 'camera' }).then((result) => {
      if (result.state === 'granted') {
        console.log('granted camera');
      } else if (result.state === 'prompt') {
        console.log('prompt camera');
      }
      // Don't do anything if the permission was denied.
    });
  }, []);

  const getPaymentInformation = async (
    receiver_trxn_ref: string,
    payer_tele_id: number,
    qrType: TypeQrEnum
  ) => {
    try {
      dispatch(setLoading(true));
      // regular transfer
      if (qrType === TypeQrEnum?.TRANSFER) {
        const res = await qrCodeApi.getPaymentQRcode(receiver_trxn_ref, payer_tele_id);
        dispatch(setPaymentInfo(res?.data?.data));
        navigate(`/${PATH_QR_CODE}`);
      }
      // pay order for merchant
      if (qrType === TypeQrEnum?.INVOICE) {
        const res = await qrCodeApi.getPaymentQRcodeMerchant(receiver_trxn_ref, payer_tele_id);
        dispatch(setPaymentInfo(res?.data?.data));
        navigate(`/${PATH_QR_CODE}`);
      }
    } catch (error: any) {
      dispatch(
        openToast({
          message: error?.response?.data?.message || error?.message || 'QR Code is invalid',
          type: 'error',
        })
      );
      navigate(`/${PATH_QR_CODE}`);
    } finally {
      // clear data to re-scan
      setData(undefined);
      dispatch(setLoading(false));
    }
  };

  // main return
  return (
    <StyledQRScanner>
      <QrReader
        showViewFinder={false}
        resolution={480}
        facingMode="environment"
        onLoad={({ stream }: any) => {
          streamRef.current = stream;
        }}
        onScan={handleScan}
        onError={(err: any) => navigate(-1)}
        // constraints={{ facingMode: 'environment' }}
        // scanDelay={300}
        // onResult={(result, error, codeReader?: BrowserQRCodeReader) => {
        //   if (result) {
        //     alert(result);
        //   }
        //   if (error) {
        //     console.info(error);
        //   }
        // }}
        // containerStyle={{
        //   width: '100vw',
        //   height: '100vh',
        //   display: 'flex',
        //   alignItems: 'center',
        //   position: 'relative',
        // }}
        // videoContainerStyle={{ overflow: 'unset', position: 'unset' }}
        // videoStyle={{ objectFit: 'cover', overflow: 'unset', width: '100vw', height: '100vh' }}
      />
      <div
        className={classnames({
          qr_zone: true,
        })}
      />
      {/* <div onClick={toggle} className="flash_btn">
        <IconFlashlight />
      </div> */}
      <div className="back_btn" onClick={() => navigate(-1)}>
        <IconArrowLeft />
      </div>
      <div className="screen_title">QR Pay</div>
      <div className="scanner_note">
        {height >= 645 && <div className="note_title">Scan QR Code</div>}
        {height >= 770 && (
          <div className="note_sub_title">
            Place your camera over the entire QR code / Barcode to start scanning.
          </div>
        )}
      </div>
    </StyledQRScanner>
  );
};

export const StyledQRScanner = styled.div`
  position: 'relative';
  & > section {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
  }
  & > section > section {
    overflow: unset !important;
    position: unset !important;
  }
  & > section > section > video {
    overflow: unset !important;
    width: 100vw !important;
    height: 100vh !important;
  }
  .qr_zone {
    width: calc(100% - 104px);
    padding-top: calc(100% - 104px);
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.5);
    /* &.error {
      border: 4px solid #f16562;
    }
    &.success {
      border: 4px solid #5fb662;
    } */
  }
  .flash_btn {
    position: absolute;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  .back_btn {
    position: absolute;
    top: 32px;
    left: 24px;
    cursor: pointer;
  }
  .screen_title {
    position: absolute;
    top: 38px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: white;
  }
  .scanner_note {
    position: absolute;
    top: 112px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    width: 265px;
    & .note_title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 14px;
      text-align: center;
    }
    & .note_sub_title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      opacity: 0.7;
      text-align: center;
    }
  }
`;
