import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalConfirm } from '@/components';
import { useBalance } from '@/hooks';
import {
  useAppSelector,
  selectUser,
  useAppDispatch,
  setLoading,
  selectApp,
  setRouteAfterEnterPin,
  setCallbackAfterEnterPin,
  openToast,
  setReceiptStatus,
  selectReceiptStatus,
} from '@/redux';
import { formatCurrency } from '@/libs/helper';
import { adminApi } from '@/api';
import { ITransaction } from '@/interfaces';
import { PATH_ENTER_PIN, PATH_PAY_REQUEST_PAYMENT } from '@/router';

export const PayRequestPayment = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const trxnId = pathname.split('/').pop();

  const { balance } = useBalance(user?.user_tele_id);

  const [transactionData, setTransactionData] = useState<ITransaction>();

  // redux states
  const receiptStatus = useAppSelector(selectReceiptStatus);

  const getTransactionByRef = async () => {
    try {
      dispatch(setLoading(true));
      const res = await adminApi.getTransactionByRef(trxnId);
      setTransactionData(res?.data?.data);
    } catch (error: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    console.log('status state redux: ', receiptStatus); // for debug
    getTransactionByRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptStatus]);

  const isLoading = useAppSelector(selectApp).loading;

  const transferMoney = async (status: 'PAY' | 'DECLINE') => {
    try {
      dispatch(setLoading(true));
      dispatch(
        openToast({ message: 'Submiting payment...', type: 'info', autoHideDuration: null })
      );
      const res = await adminApi.transferMoney(
        transactionData?.my_trxn_ref,
        transactionData?.their_trxn_ref,
        status
      );
      console.log(res?.data?.data);
      dispatch(
        openToast({
          message:
            status === 'PAY'
              ? 'Payment made successfully, funds have been transferred.'
              : 'Payment declined successfully',
          type: 'success',
        })
      );
      dispatch(setReceiptStatus(!receiptStatus));
    } catch (error: any) {
      console.log('error: ', error); // for debug
      dispatch(
        openToast({ message: error?.message || error?.response?.data?.message, type: 'error' })
      );
    } finally {
      // dispatch(setLoading(false));
    }
  };

  const handleAcceptPay = () => {
    // // redux: next route + callback, after that navigate to enter pin
    dispatch(setRouteAfterEnterPin(`/${PATH_PAY_REQUEST_PAYMENT}/${trxnId}`));
    dispatch(setCallbackAfterEnterPin(() => transferMoney('PAY')));
    navigate(`/${PATH_ENTER_PIN}`);
  };

  const handleDeclinePay = () => {
    // // redux: next route + callback, after that navigate to enter pin
    dispatch(setRouteAfterEnterPin(`/${PATH_PAY_REQUEST_PAYMENT}/${trxnId}`));
    dispatch(setCallbackAfterEnterPin(() => transferMoney('DECLINE')));
    navigate(`/${PATH_ENTER_PIN}`);
  };

  return (
    <StyledStartPage>
      <div
        className="balance_container"
        style={transactionData?.status !== 'PENDING' ? { display: 'none' } : {}}
      >
        <div className="title">Available Balance</div>
        <div className="balance">AUD: {formatCurrency(Number(balance) || 0)}</div>
      </div>
      {/* <div className="sub_title">Payment Request</div> */}
      {!isLoading && transactionData && (
        <div className="content">
          <h3 className="content-title">Payment Request</h3>
          <div className="content-item">
            <p className="content-item-key">myTrxn Ref</p>
            <p className="content-item-value">{transactionData?.my_trxn_ref}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">theirTrxn Ref</p>
            <p className="content-item-value">{transactionData?.their_trxn_ref}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">myNumber</p>
            <p className="content-item-value">{transactionData?.my_number}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">theirNumber</p>
            <p className="content-item-value">{transactionData?.their_number}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">Payer</p>
            <p className="content-item-value">{transactionData?.payer}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">Receiver </p>
            <p className="content-item-value">{transactionData?.receiver}</p>
          </div>
          {/* <div className="content-item">
            <p className="content-item-key">Amount</p>
            <p className="content-item-value">
              {formatCurrency(Number(transactionData?.amount) || 0)}
            </p>
          </div> */}
          <div className="content-item">
            <p className="content-item-key">Description</p>
            <p className="content-item-value desc">{transactionData?.description}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">Status</p>
            <p className="content-item-value">{transactionData?.status}</p>
          </div>

          <div className="content-total">
            <h3>TOTAL:</h3>
            <h3>{formatCurrency(Math.abs(Number(transactionData?.amount)) || 0)}</h3>
          </div>

          <div className="content-thankyou">
            <h3>THANK YOU!</h3>
          </div>
        </div>
      )}
      <div className="btn" style={transactionData?.status !== 'PENDING' ? { display: 'none' } : {}}>
        <ModalConfirm
          title="Decline Request"
          triggerButton={
            <Button
              className="btn-decline"
              variant="contained"
              size="large"
              disabled={transactionData?.status !== 'PENDING'}
            >
              Decline
            </Button>
          }
          confirmButton={
            <Button
              variant="contained"
              className="btn-confirm"
              sx={{
                bgcolor: '#6369FF',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '13px',
                lineHeight: '16px',
                textTransform: 'initial',
                minWidth: '127px',
                py: '10px',
                borderColor: 'transparent',
                '&:hover': {
                  opacity: 0.8,
                  bgcolor: '#6369FF',
                },
              }}
              onClick={handleDeclinePay}
            >
              Yes
            </Button>
          }
        >
          <Box sx={{ textAlign: 'center', padding: '10px 0' }}>Decline this payment request</Box>
        </ModalConfirm>
        <ModalConfirm
          title={
            Math.abs(Number(transactionData?.amount)) <= balance
              ? 'Pay Request'
              : 'Insufficient Funds'
          }
          triggerButton={
            <Button
              className="btn-pay"
              variant="contained"
              size="large"
              disabled={transactionData?.status !== 'PENDING'}
            >
              Pay
            </Button>
          }
          cancelBtnText={Math.abs(Number(transactionData?.amount)) <= balance ? 'Cancel' : 'OK'}
          confirmButton={
            Math.abs(Number(transactionData?.amount)) <= balance ? (
              <Button
                variant="contained"
                className="btn-confirm"
                sx={{
                  bgcolor: '#6369FF',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '13px',
                  lineHeight: '16px',
                  textTransform: 'initial',
                  minWidth: '127px',
                  py: '10px',
                  borderColor: 'transparent',
                  '&:hover': {
                    opacity: 0.8,
                    bgcolor: '#6369FF',
                  },
                }}
                onClick={handleAcceptPay}
              >
                Yes
              </Button>
            ) : (
              <></>
            )
          }
        >
          {Math.abs(Number(transactionData?.amount)) <= balance ? (
            <Box sx={{ textAlign: 'center', padding: '10px 0' }}>
              Pay {formatCurrency(Math.abs(Number(transactionData?.amount)) || 0)} to{' '}
              {transactionData?.receiver}
            </Box>
          ) : (
            <Box sx={{ marginBottom: '4px', textAlign: 'center' }}>
              You cannot pay more than your balance.
            </Box>
          )}
        </ModalConfirm>
      </div>
    </StyledStartPage>
  );
};

const StyledStartPage = styled.div`
  padding: 23px 16px 0 16px;
  margin-bottom: 72px;
  .balance_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 32px 0;
    border: 1px solid;

    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0a84ff;
      margin-bottom: 12px;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
    }
  }

  .sub_title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .content {
    padding: 0 20px;
    padding-top: 5px;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 8px;
    border: 1px solid;

    .content-title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      border-bottom: dashed 2px;
      padding-bottom: 20px;
    }

    .content-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -10px;

      .content-item-key {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        opacity: 0.5;
        color: #171717;
        min-width: 100px;
        margin-right: 24px;
      }

      .content-item-value {
        color: #171717;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        &.desc {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }
    }

    .content-total {
      display: flex;
      justify-content: space-between;
      border-top: 2px dashed;
      border-bottom: 2px dashed;
      margin: 20px 0px;

      h3 {
        font-size: 20px;
      }
    }

    .content-thankyou {
      margin: 30px 0px;
      h3 {
        font-size: 20px;
        text-align: center;
      }
    }
  }

  .btn {
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    background-color: #f6f6f6;
    box-shadow: 0px -0.33px 0px #d8d8d8;
    padding: 8px;

    display: grid;
    grid-row-gap: 24px;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);

    .btn-pay {
      background-color: #0a84ff;
      border-radius: 10px;
      color: #ffffff;
      width: 100%;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }

    .btn-decline {
      background-color: rgba(215, 0, 21, 0.15);
      border-radius: 10px;
      color: #d70015;
      width: 100%;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
`;
