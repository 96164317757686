/* eslint-disable prefer-destructuring */
import React, { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { BasicDatePicker, IconCoinNoBackground, Input, MainButton } from '@/components';
import { useBalance } from '@/hooks';
import { formatCurrency } from '@/libs/helper';
import { selectUser, useAppSelector } from '@/redux';

type IProps = {
  handleNextStep(): void;
  errors: any;
  register: any;
  setValue: UseFormSetValue<any>;
  pay_by_val?: string;
  amount_val: string;
  description_val: string;
  isActive: boolean;
  feature: 'RP' | 'PA'; // request payment OR pay anyone
};

export const StepOne = (props: IProps) => {
  const {
    errors,
    handleNextStep,
    register,
    setValue,
    pay_by_val,
    amount_val,
    description_val,
    isActive,
    feature,
  } = props;

  useEffect(() => {
    console.log('errors: ', errors); // for debug
  }, [errors]);

  const user = useAppSelector(selectUser);

  const { balance } = useBalance(user?.user_tele_id);

  // functions
  // this function only allows up to 2 decimal places
  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = e?.target?.value
        ?.replace(/[^0-9.]/g, '')
        ?.replace(/^(\d+.?\d{0,2})\d*$/, '$1')
        ?.replaceAll(',', '.');
      setValue('amount', value, { shouldValidate: true });
    } catch (error) {
      console.log(error);
    }
  };

  // main return
  return (
    <div className={isActive ? 'active' : 'inactive'}>
      <div className="sub_title">{feature === 'RP' ? 'Payment Request' : 'Transfer'}</div>
      <div className="balance_container">
        <div className="title">Available Balance</div>
        <div className="balance">AUD: {formatCurrency(Number(balance) || 0)}</div>
      </div>
      <Input
        className="mb-24"
        placeholder="Amount"
        name="amount"
        // register={register('amount')}
        error={errors.amount}
        preIcon={<IconCoinNoBackground />}
        type="number"
        onChange={handleChangeAmount}
        value={amount_val}
      />

      <Input
        className="mb-24"
        label="Description"
        name="description"
        register={register('description')}
        // value={description_val}
        // onChange={(e) => {
        //   setValue('description', e?.target?.value?.replaceAll(regexRemoveAllUnicodeEmojis, ''), {
        //     shouldValidate: true,
        //   });
        // }}
        error={errors.description}
        maxLength={280}
      />
      {feature === 'RP' ? (
        <BasicDatePicker
          className="mb-24"
          label="Pay by"
          name="pay_by"
          handleChange={(date) => {
            const dateChange = date?.format('MM/DD/YYYY');
            console.log('date: ', dateChange, typeof dateChange); // for debug
            setValue('pay_by', dateChange);
          }}
          //   value={new Date(watch('pay_by'))}
          value={pay_by_val ? new Date(pay_by_val) : new Date()}
          error={errors.pay_by?.message}
          disablePast
        />
      ) : null}

      <MainButton
        text={feature === 'RP' ? 'Add Payer' : 'Add Receiver'}
        type="button"
        disabled={
          !amount_val ||
          !description_val ||
          (feature === 'PA' && Number(amount_val) > Number(balance)) ||
          errors?.amount
        }
        onClick={handleNextStep}
      />
    </div>
  );
};
