/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
// import { useBalance } from '@/hooks';
import { adminApi } from '@/api';
import { ITransaction } from '@/interfaces';
import { formatCurrency } from '@/libs/helper';
import { openToast, setLoading, useAppDispatch } from '@/redux';

// // dummy data
// const transactionData: ITransaction = {
//   amount: '1000',
//   datetime: '818120-21',
//   description: 'hieu hieu hieu',
//   id: '12787889712',
//   my_number: '189819289',
//   my_trxn_ref: 'QJKNDKJLQNLKCN  ',
//   payer: '179812',
//   payer_user_id: '1278891',
//   receiver: '87981289',
//   receiver_user_id: '7878238998',
//   status: 'PAID',
//   their_number: '89238980239',
//   their_trxn_ref: 'lkajlka',
// };

export const ReceiptTransaction = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const trxnId = useMemo(() => {
    return pathname.split('/').pop();
  }, [pathname]);

  // const { balance } = useBalance(user?.user_tele_id);

  // local states
  const [transactionData, setTransactionData] = useState<ITransaction>();

  // functions
  const getTransactionByRef = async (id?: string) => {
    if (!id) {
      dispatch(openToast({ message: 'Some thing went wrong, please try again.', type: 'error' }));

      return;
    }
    try {
      dispatch(setLoading(true));
      const res = await adminApi.getTransactionByRef(id);
      setTransactionData(res?.data?.data);
    } catch (error: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const hanleOutput = (input?: string | number) => {
    if (input) return input;

    return '--';
  };

  // effects
  useEffect(() => {
    console.log('trxnId: ', trxnId);
    getTransactionByRef(trxnId);
  }, [trxnId]);

  // main return
  return (
    <StyledReceiptPage>
      {/* <div className="balance_container">
        <div className="title">Available Balance</div>
        <div className="balance">AUD: {formatCurrency(Number(balance) || 0)}</div>
      </div> */}
      {/* <div className="sub_title">Payment Summary</div> */}
      {transactionData && (
        <div className="content">
          <h3 className="content-title">Payment Summary</h3>
          <div className="content-item">
            <p className="content-item-key">myTrxn Ref:</p>
            <p className="content-item-value regular">
              {hanleOutput(transactionData?.my_trxn_ref)}
            </p>
          </div>
          <div className="content-item">
            <p className="content-item-key">theirTrxn Ref:</p>
            <p className="content-item-value regular">
              {hanleOutput(transactionData?.their_trxn_ref)}
            </p>
          </div>
          <div className="content-item">
            <p className="content-item-key">myNumber:</p>
            <p className="content-item-value regular">{hanleOutput(transactionData?.my_number)}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">theirNumber:</p>
            <p className="content-item-value regular">
              {hanleOutput(transactionData?.their_number)}
            </p>
          </div>
          <div className="content-item">
            <p className="content-item-key">Payer:</p>
            <p className="content-item-value regular">{hanleOutput(transactionData?.payer)}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">Receiver :</p>
            <p className="content-item-value regular">{hanleOutput(transactionData?.receiver)}</p>
          </div>
          {/* <div className="content-item">
            <p className="content-item-key">Amount:</p>
            <p className="content-item-value regular">
              {formatCurrency(Number(transactionData?.amount) || 0)}
            </p>
          </div> */}
          <div className="content-item">
            <p className="content-item-key">Description:</p>
            <p className="content-item-value desc">{hanleOutput(transactionData?.description)}</p>
          </div>
          <div className="content-item">
            <p className="content-item-key">Status:</p>
            <p className="content-item-value regular">{hanleOutput(transactionData?.status)}</p>
          </div>

          <div className="content-total">
            <h3>TOTAL:</h3>
            <h3>{formatCurrency(Math.abs(Number(transactionData?.amount)) || 0)}</h3>
          </div>

          <div className="content-thankyou">
            <h3>THANK YOU!</h3>
          </div>
        </div>
      )}
    </StyledReceiptPage>
  );
};

const StyledReceiptPage = styled.div`
  padding: 23px 16px 0 16px;
  margin-bottom: 72px;
  .balance_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 32px 0;
    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0a84ff;
      margin-bottom: 12px;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
    }
  }

  .sub_title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .content {
    padding: 0 20px;
    padding-top: 5px;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 8px;
    border: 1px solid;

    .content-title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      border-bottom: dashed 2px;
      padding-bottom: 20px;
    }
    .content-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: -10px;

      .content-item-key {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        opacity: 0.5;
        color: #171717;
        min-width: fit-content;
        margin-right: 24px;
      }

      .content-item-value {
        color: #171717;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        &.desc {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        &.regular {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }
    }

    .content-total {
      display: flex;
      justify-content: space-between;
      border-top: 2px dashed;
      border-bottom: 2px dashed;
      margin: 20px 0px;

      h3 {
        font-size: 20px;
      }
    }

    .content-thankyou {
      margin: 30px 0px;
      h3 {
        font-size: 20px;
        text-align: center;
      }
    }
  }
`;
