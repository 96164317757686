import React from 'react';
import styled from 'styled-components';
import { IPayInvoiceResponse } from '@/interfaces';
import { handleDecimalPrice } from '@/utils';

type Iprops = {
  orderDetailRes?: IPayInvoiceResponse;
};

export const ProductInfoOrder = (props: Iprops) => {
  const { orderDetailRes } = props;

  // main return
  return (
    <StyledProductInfoOrder>
      {orderDetailRes?.order_details?.length
        ? orderDetailRes?.order_details?.map((item) => {
            return (
              <div className="product_info_item" key={item?.id}>
                <div className="product_info_item_name">
                  <p className="product_info_item_name_title">{item?.product_name}</p>
                  <p className="product_info_item_name_desc">
                    {`x${handleDecimalPrice(Number(item?.quantity))}@$
                      ${handleDecimalPrice(Number(item?.gross_price))}`}
                  </p>
                </div>
                <p className="product_info_item_value">
                  ${handleDecimalPrice(Number(item?.gross_price) * Number(item?.quantity))}
                </p>
              </div>
            );
          })
        : null}
      <div className="product_info_item m_total">
        <p className="product_info_item_total_title">
          Total{' '}
          {orderDetailRes?.order_details?.filter((item) => Number(item?.gst_percent) > 0)?.length
            ? '(Incl GST)'
            : '(Excl GST)'}
        </p>
        <p className="product_info_item_total_value">
          ${handleDecimalPrice(Number(orderDetailRes?.total_price))}
        </p>
      </div>
    </StyledProductInfoOrder>
  );
};

const StyledProductInfoOrder = styled.div`
  padding: 16px 0px;
  border-bottom: 1px solid #d5d4db;
  margin-bottom: 16px;

  .m_total {
    margin: 0 !important;
    margin-top: 16px !important;
  }

  .product_info_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin-bottom: 16px; */

    .product_info_item_name {
      /* display: flex;
        align-items: center; */

      /* display: grid;
        grid-template-columns: 3fr 1fr; */

      display: flex;
      flex: 1 1 auto;
      justify-content: flex-start;
      flex-wrap: wrap;

      max-width: 75%;
      overflow: hidden;

      .product_info_item_name_title {
        color: #171717;
        font-weight: 500;
        font-size: 16px;
        margin: 0;
        margin-right: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .product_info_item_name_desc {
        color: #8f8f8f;
        font-weight: 500;
        font-size: 16px;
        margin: 0;
        /* display: flex;
          align-items: center; */
      }
    }

    .product_info_item_value {
      color: #171717;
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    .product_info_item_total_title {
      color: #171717;
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    .product_info_item_total_value {
      color: #171717;
      font-weight: 700;
      font-size: 24px;
      margin: 0;
    }
  }
`;
