import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PATH_BALANCE_WAITING_TOP_UP } from '@/router';
import { telegramClient } from '@/configs';

export const BalanceWaitingPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const timerId = setTimeout(() => {
      console.log('telegram window: ', telegramClient); // for debug
      telegramClient?.WebApp?.disableClosingConfirmation(); // disable close confirmation
      telegramClient?.WebApp?.close(); // close the webapp
    }, 3000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <StyledBalanceWaitingPage>
      {pathname === `/${PATH_BALANCE_WAITING_TOP_UP}` ? 'Topping Up...' : 'Withdraw...'}
    </StyledBalanceWaitingPage>
  );
};

const StyledBalanceWaitingPage = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #6369ff;
`;
