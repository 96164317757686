import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Button } from '@material-ui/core';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { IOTPCodePhone, IOTPCodeEmail, IAccountRecover } from '@interfaces';
import { Input, Breadcrumb, IconCircleArrow, InputPhoneNumber } from '@components';
import { recoverAccountApi } from '@api';
import {
  setLoading,
  useAppDispatch,
  openToast,
  useAppSelector,
  selectUser,
  selectRecoverEmail,
} from '@redux';
import dayjs from 'dayjs';
import { telegramClient } from '@configs';
import { PATH_HOME } from '@/router';

export function RecoverAccountPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const recoverEmail = useAppSelector(selectRecoverEmail);

  const otpPhoneSchema = Yup.object().shape({
    otp_code_phone: Yup.string()
      .trim()
      .required('The OTP field is required')
      .matches(/^[0-9]+$/, 'The OTP field must be only digits')
      .min(6, 'The OTP field must be contain 6 characters')
      .max(6, 'The OTP field must be contain 6 characters'),
  });

  const otpEmailSchema = Yup.object().shape({
    otp_code_email: Yup.string()
      .trim()
      .required('The OTP field is required')
      .matches(/^[0-9]+$/, 'The OTP field must be only digits')
      .min(6, 'The OTP field must be contain 6 characters')
      .max(6, 'The OTP field must be contain 6 characters'),
  });

  const [phone, setPhone] = useState<string>('');

  const [email, setEmail] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
  } = useForm<IOTPCodePhone>({
    mode: 'onTouched',
    resolver: yupResolver(otpPhoneSchema),
  });

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail, isValid: isValidEmail },
    reset: resetEmail,
    setValue: setValueEmail,
    watch: watchEmail,
  } = useForm<IOTPCodeEmail>({
    mode: 'onTouched',
    resolver: yupResolver(otpEmailSchema),
  });

  const submitOTPPhone = (formData: IOTPCodePhone) => {
    checkVerifyOtp('PHONE', formData?.otp_code_phone || '', `+${phone}`);
  };

  const submitOTPEmail = (formData: IOTPCodeEmail) => {
    checkVerifyOtp('EMAIL', formData?.otp_code_email || '', email);
  };

  const handleChangePhone = (phone: string) => {
    setPhone(phone);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const [listUserPhone, setUserPhone] = useState<any>({ isDone: false, data: [] });
  const [listUserEmail, setUserEmail] = useState<any>({ isDone: false, data: [] });

  const sentOtpCode = async (type: 'PHONE' | 'EMAIL', contact: string) => {
    try {
      dispatch(setLoading(true));
      const res = await recoverAccountApi.sentOtpCode(contact, type);
      dispatch(
        openToast({
          message: res?.data?.data?.message,
          type: 'success',
        })
      );
    } catch (error: any) {
      dispatch(
        openToast({
          message: error?.response?.data?.errors[0],
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const checkVerifyOtp = async (type: 'PHONE' | 'EMAIL', code: string, contact: string) => {
    try {
      dispatch(setLoading(true));
      const res = await recoverAccountApi.verifyOtpCode(contact, type, code);
      console.log(res?.data);
      dispatch(
        openToast({
          message: res?.data?.data?.message,
          type: 'success',
        })
      );
      if (type === 'PHONE') reset();
      else resetEmail();
      const res2 = await recoverAccountApi.getListRecoverAccount(
        contact,
        type,
        Number(user?.user_tele_id)
      );
      console.log('listAccountContact', res2?.data);
      if (type === 'PHONE') setUserPhone({ isDone: true, data: res2?.data?.data });
      else setUserEmail({ isDone: true, data: res2?.data?.data });
    } catch (error: any) {
      console.log(error.response);
      dispatch(
        openToast({
          message: error?.response?.data?.message,
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  // const renderFullName = (first?: string, middle?: string, last?: string) => {
  //   return [first, middle, last].join(' ');
  // };

  const handleRecover = async (type: 'PHONE' | 'EMAIL', old_user_tele_id?: number) => {
    try {
      dispatch(setLoading(true));
      // @ts-ignore
      const new_user_tele_id = user?.user_tele_id;

      const res = await recoverAccountApi.saveDataRecover(
        Number(old_user_tele_id),
        Number(new_user_tele_id),
        type,
        type === 'EMAIL' ? recoverEmail : user?.phone_number, // get the telegram phone and put to empty string,
        telegramClient?.initDataUnsafe?.auth_date || dayjs(new Date()).valueOf().toString()
      );
      dispatch(
        openToast({
          message: 'Successfully completed account recovery setup',
          type: 'success',
        })
      );
      navigate(PATH_HOME);
    } catch (error: any) {
      console.log(error.response);
      dispatch(
        openToast({
          message: error?.response?.data?.message,
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <StyledRecoverAccount>
      <Container>
        <Breadcrumb />
        <form onSubmit={handleSubmit(submitOTPPhone)}>
          <div className="form_title">Mobile Number</div>
          <div className="row mb-24">
            <div className="input">
              <InputPhoneNumber
                value={phone}
                onChange={handleChangePhone}
                specialLabel="Mobile number"
              />
            </div>
            {/* <Input
              className="input"
              label="Your mobile number"
              name="phone"
              type="text"
              value={phone}
              onChange={handleChangePhone}
            /> */}
            <Button
              variant="contained"
              className="btn-blue"
              onClick={() => sentOtpCode('PHONE', `+${phone}`)}
            >
              Send OTP
            </Button>
          </div>
          <div className="row mb-24">
            <Input
              className="input"
              label="Enter OTP"
              name="otp_code_phone"
              error={errors.otp_code_phone}
              type="number"
              register={register('otp_code_phone')}
              // type="text"
              // onChange={(e) => {
              //   const res = e?.target?.value?.replaceAll('e', '')?.replace(/[^0-9.]/g, '');
              //   setValue('otp_code_phone', res, {
              //     shouldValidate: true,
              //   });
              // }}
              // value={watch('otp_code_phone')}
            />
            <Button variant="contained" className="btn-blue" type="submit" disabled={!isValid}>
              Verify
            </Button>
          </div>
        </form>
        {listUserPhone.data.length > 0 ? (
          <div className="list_account">
            <div className="title">Choose accounts to recover</div>
            {listUserPhone.data.map((user: IAccountRecover, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="account_wrapper" key={index}>
                <div className="account_info">
                  <div className="full_name">
                    {/* {renderFullName(user?.first_name, user?.middle_name, user?.last_name)} */}
                    {user?.full_name}
                  </div>
                  <div className="user_name">{user?.user_name}</div>
                </div>
                {user?.is_deactive && (
                  <div
                    className="btn_recover"
                    onClick={() => handleRecover('PHONE', user?.user_tele_id)}
                  >
                    <div className="icon_wrapper">
                      <IconCircleArrow />
                    </div>
                    Recover
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          listUserPhone.isDone && (
            <div className="error_no_data">No Eligible Accounts Found For Recovery</div>
          )
        )}

        <div className="divide" />
        <form onSubmit={handleSubmitEmail(submitOTPEmail)} className="mt-24">
          <div className="form_title">Email</div>
          <div className="row mb-24">
            <Input
              className="input"
              label="Your email address"
              name="email"
              type="text"
              value={email}
              onChange={handleChangeEmail}
            />
            <Button
              variant="contained"
              className="btn-blue"
              onClick={() => sentOtpCode('EMAIL', email)}
            >
              Send OTP
            </Button>
          </div>
          <div className="row mb-24">
            <Input
              className="input"
              label="Enter OTP"
              name="otp_code_email"
              error={errorsEmail.otp_code_email}
              type="number"
              register={registerEmail('otp_code_email')}
              // type="text"
              // onChange={(e) => {
              //   const res = e?.target?.value?.replaceAll('e', '')?.replace(/[^0-9.]/g, '');
              //   setValueEmail('otp_code_email', res, {
              //     shouldValidate: true,
              //   });
              // }}
              // value={watchEmail('otp_code_email')}
            />
            <Button variant="contained" className="btn-blue" type="submit" disabled={!isValidEmail}>
              Verify
            </Button>
          </div>
        </form>
        {listUserEmail.data.length > 0 ? (
          <div className="list_account">
            <div className="title">Choose accounts to recover</div>
            {listUserEmail.data.map((user: IAccountRecover, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="account_wrapper" key={index}>
                <div className="account_info">
                  <div className="full_name">
                    {/* {renderFullName(user?.first_name, user?.middle_name, user?.last_name)} */}
                    {user?.full_name}
                  </div>
                  <div className="user_name">{user?.user_name}</div>
                </div>
                {user?.is_deactive && (
                  <div
                    className="btn_recover"
                    onClick={() => handleRecover('EMAIL', user?.user_tele_id)}
                  >
                    <div className="icon_wrapper">
                      <IconCircleArrow />
                    </div>
                    Recover
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          listUserEmail.isDone && (
            <div className="error_no_data">No Eligible Accounts Found For Recovery</div>
          )
        )}
      </Container>
    </StyledRecoverAccount>
  );
}

const StyledRecoverAccount = styled.div`
  form {
    margin-top: 36px;
    &.mt-24 {
      margin-top: 24px;
    }
  }
  .divide {
    height: 1px;
    width: 100%;
    background-color: #eeedf5;
  }
  .form_title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .mb-24 {
    margin-bottom: 24px;
    &.row_item {
      width: 50%;
    }
  }
  .row {
    display: grid;
    /* grid-row-gap: 24px; */
    grid-column-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    .input {
      grid-column: span 3 / span 3;
    }
    .MuiButton-label {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
    }
    .MuiButton-contained.Mui-disabled {
      background-color: #8f8f8f !important;
    }
  }
  .error_no_data {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: #8f8f8f;
    margin-bottom: 24px;
  }
  .list_account {
    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      opacity: 0.7;
      margin-bottom: 14px;
    }
    .account_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    .account_info {
      .full_name {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
      }
      .username {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }
    }
    .btn_recover {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #0a84ff;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      padding: 8px 0;
      .icon_wrapper {
        margin-right: 4px;
        display: flex;
        align-items: center;
      }
    }
  }
`;
