import React from 'react';
import styled from 'styled-components';
import { IOrderUserDetail } from '@/interfaces';
import { ResAddressIcon } from '@/components';

type IProps = {
  userDetail: IOrderUserDetail;
};

export const CompanyDetailInfo = (props: IProps) => {
  const { userDetail } = props;

  // main return
  return (
    <StyledCompanyDetailInfo>
      <ResAddressIcon />
      <div className="res_address_content">
        <p className="res_address_content_name">{userDetail?.registed_name}</p>
        {userDetail?.resAddress?.address_line_first ? (
          <p className="res_address_content_address">{`${
            userDetail?.resAddress?.address_line_first
          } ${
            userDetail?.resAddress?.address_line_second
              ? `,${userDetail?.resAddress?.address_line_second}`
              : ''
          }`}</p>
        ) : null}
        {userDetail?.abn_acn ? (
          <p className="res_address_content_address">{`ABN/ACN: ${userDetail?.abn_acn}`}</p>
        ) : null}
      </div>
    </StyledCompanyDetailInfo>
  );
};

const StyledCompanyDetailInfo = styled.div`
  padding: 24px 0;
  border-top: 1px solid #e7e6f0;
  border-bottom: 1px solid #e7e6f0;

  display: flex;
  align-items: start;

  .res_address_content {
    margin-left: 10px;
    p {
      margin: 0;
      margin-bottom: 8px;
    }

    .res_address_content_name {
      color: #171717;
      font-weight: 600;
      font-size: 16px;
    }

    .res_address_content_address {
      color: #8f8f8f;
      font-weight: 400;
      font-size: 14px;
    }
  }
`;
