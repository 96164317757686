import { TypeOf, z } from 'zod';

export const personalDetailSchema = z.object({
  first_name: z
    .string()
    .min(1, 'Please enter your first name')
    .max(30, 'The first name field should contain no more than 30 characters.'),
  middle_name: z
    .string()
    .max(30, 'The middle name field should contain no more than 30 characters.')
    .optional(),
  last_name: z
    .string()
    .min(1, 'Please enter your last name')
    .max(30, 'The last name field should contain no more than 30 characters.'),
  date_of_birth: z
    .instanceof(Date, { message: 'Date of birth is required' })
    .refine((date) => {
      return date < new Date(Date.now());
    }, 'Wrong format DOB, please re-choose')
    .or(z.string()),
});

export type PersonalDetailType = TypeOf<typeof personalDetailSchema>;
