import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { telegramClient, TypeTransactionEnum } from '@configs';

import {
  IconWithdraw,
  Input,
  InputSelect,
  IconTopUp,
  IconCoinNoBackground,
  ModalConfirm,
  IconCircleCheck,
  BalanceComponent,
} from '@components';
import {
  PATH_BALANCE,
  PATH_BALANCE_WAITING_TOP_UP,
  PATH_BALANCE_WAITING_WITHDRAW,
  PATH_ENTER_PIN,
} from '@router';
import {
  useAppDispatch,
  setRouteAfterEnterPin,
  useAppSelector,
  selectUser,
  setBalanceInfo,
  setCallbackAfterEnterPin,
  selectBalanceInfo,
  setLoading,
  openToast,
} from '@redux';
import { IBalanceInfo } from '@/interfaces';
import { IPayIdResponse, useQueryGetPayId } from '@/services/pay-id';
import { formatCurrency } from '@/libs/helper';
import { adminApi } from '@/api';
import { useBalance } from '@/hooks';

export function BalancePage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUser)?.user_tele_id;
  const balanceInfoRedux = useAppSelector(selectBalanceInfo);

  const balance = Number(useBalance(userId)?.balance);

  const { payIdList } = useQueryGetPayId({ params: { is_verify: true } });

  const payIdVerifyList = useMemo(() => {
    return payIdList?.filter((payId: IPayIdResponse) => payId.is_verify);
  }, [payIdList]);

  const payIdOptions = useMemo(() => {
    return payIdVerifyList?.map((item) => ({
      label: item.payid,
      value: String(item.payid),
      type: item.type,
    }));
  }, [payIdVerifyList]);

  const mapPayId = useMemo(() => {
    let temp = {};
    payIdVerifyList?.forEach((item) => {
      temp = { ...temp, [String(item.payid)]: item.payid };
    });

    return temp;
  }, [payIdVerifyList]);

  const balanceSchema = Yup.object().shape({
    payid: Yup.string().required('Please select a PayID to continue.'),
    amount: Yup.number()
      .transform((value, originalValue) => {
        return originalValue === '' ? undefined : value;
      })
      .typeError('Please enters an Amount with a number')
      .min(0.001, 'Please enter an amount greater than $0.00.')
      .max(10000, 'Please enters an Amount less than 10,000')
      // .test('len', 'Please enters an Amount no more than 12 characters', (val) => {
      //   if (val) {
      //     return val?.toString()?.length <= 12;
      //   }

      //   return true;
      // })
      .required('Please enter an amount to continue.'),
  });

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
    handleSubmit,
  } = useForm<IBalanceInfo>({
    mode: 'onTouched',
    resolver: yupResolver(balanceSchema),
  });

  const handleChangePayId = (selectedOption: any) => {
    setValue('payid', selectedOption.value, { shouldValidate: true });
    // setValue('amount', '');
    setValue('type', selectedOption.type);
  };

  const handleClickTopUpButton = async (open: () => void) => {
    const isValid = await trigger();
    if (isValid) open();
    setValue('type_transaction', TypeTransactionEnum.TOPUP);
  };

  const handleClickWithdrawButton = async (open: () => void) => {
    const isValid = await trigger();
    if (isValid) open();
    setValue('type_transaction', TypeTransactionEnum.WITHDRAW);
  };

  const handleTopupWithdraw = async (formData: IBalanceInfo) => {
    console.log('formData', formData);
    try {
      formData.auth_date =
        telegramClient?.initDataUnsafe?.auth_date || dayjs(new Date()).valueOf().toString();
      formData.user_tele_id = Number(userId);
      formData.amount = formData.amount.toLocaleString('fullwide', { useGrouping: false });
      console.log('from data: ', formData);
      // set redux state for balance info
      dispatch(setBalanceInfo(formData));
    } catch (error) {
      console.log('err while topping up: ', error); // for debug
    }
  };

  const handleConfirmTransaction = (type: TypeTransactionEnum) => {
    if (!balanceInfoRedux) {
      console.log('no redux balance');

      return;
    }
    if (!type) {
      console.log('no type transaction');

      return;
    }

    const messageInfo = type === TypeTransactionEnum?.TOPUP ? 'Topping up...' : 'Withdrawing...';
    const messageSuccess =
      type === TypeTransactionEnum?.TOPUP
        ? 'The top-up was successful.'
        : 'The withdrawal was successful.';
    const errorMessage =
      type === TypeTransactionEnum?.TOPUP
        ? 'Topup failed. Please try again.'
        : 'Withdraw failed. Please try again.';

    try {
      // // redux: next route + callback, after that navigate to enter pin
      dispatch(setRouteAfterEnterPin(`/${PATH_BALANCE}`));
      dispatch(
        setCallbackAfterEnterPin(async () => {
          try {
            dispatch(openToast({ message: messageInfo, type: 'info', autoHideDuration: null }));
            console.log('after yes btn: ', balanceInfoRedux);
            await adminApi.topUpWithDraw(balanceInfoRedux);
            dispatch(
              openToast({ message: messageSuccess, type: 'success', autoHideDuration: 3000 })
            );
            dispatch(setBalanceInfo(undefined)); // clear the balance info after enter pin, this trigger re-get balance
          } catch (error: any) {
            console.log('err: ', error); // for debug
            dispatch(
              openToast({
                message: error?.response?.data?.message || errorMessage,
                type: 'error',
                autoHideDuration: null,
              })
            );
          }
        })
      );
      navigate(`/${PATH_ENTER_PIN}`);
    } catch (error) {
      console.log(error);
    }
  };

  // this function only allows up to 2 decimal places
  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = e?.target?.value
        ?.replace(/[^0-9.]/g, '')
        ?.replace(/^(\d+.?\d{0,2})\d*$/, '$1')
        ?.replaceAll(',', '.');
      setValue('amount', value, { shouldValidate: true });
    } catch (error) {
      console.log(error);
    }
  };

  // effects
  useEffect(() => {
    console.log('balance: ', balance, typeof balance); // for debug
  }, [balance]);

  // main return
  return (
    <StyledBalancePage>
      <BalanceComponent />
      <form autoComplete="off" onSubmit={handleSubmit(handleTopupWithdraw)}>
        <InputSelect
          className="mb-24"
          options={payIdOptions}
          placeholder="Select PayID"
          register={register('payid')}
          name="payid"
          error={errors.payid}
          onChange={handleChangePayId}
          value={payIdOptions?.find((payId) => payId.value === watch('payid')) || null}
        />
        <Input
          className="mb-24 row_item"
          placeholder="Amount"
          name="amount"
          // register={register('amount')}
          error={errors.amount}
          preIcon={<IconCoinNoBackground />}
          type="number"
          onChange={handleChangeAmount}
          value={watch('amount')?.toString()}
        />

        <div className="btn_group">
          <ModalConfirm
            className="w-full mr-16"
            // isDone={mutationDeletePayId.isSuccess || mutationDeletePayId.isError}
            title="Top Up"
            triggerButton={
              <Button
                variant="contained"
                startIcon={<IconTopUp />}
                className="balance_btn top_up"
                type="submit"
              >
                Top Up
              </Button>
            }
            onClickTriggerButton={handleClickTopUpButton}
            ModalProps={{ maxWidth: 'md' }}
            confirmButton={
              <Button
                variant="contained"
                sx={{
                  bgcolor: '#6369FF',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '13px',
                  lineHeight: '16px',
                  textTransform: 'initial',
                  minWidth: '127px',
                  py: '10px',
                  borderColor: 'transparent',
                  '&:hover': {
                    opacity: 0.8,
                    bgcolor: '#6369FF',
                  },
                }}
                onClick={() => {
                  handleConfirmTransaction(TypeTransactionEnum?.TOPUP);
                }}
              >
                Yes
              </Button>
            }
          >
            <Box sx={{ marginBottom: '4px', textAlign: 'center' }}>
              Top up Tippe account by{' '}
              <Box component="span" sx={{ fontWeight: '600' }}>
                {formatCurrency(Number(watch('amount')))}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              From PayID:
              <Box
                component="span"
                sx={{
                  fontWeight: '600',
                  marginLeft: '4px',
                  marginRight: '2px',
                  wordBreak: 'break-all',
                }}
              >
                {/* @ts-ignore */}
                {watch('payid') && mapPayId[watch('payid')]}
              </Box>
              <IconCircleCheck />
            </Box>
          </ModalConfirm>
          <ModalConfirm
            // isDone={mutationDeletePayId.isSuccess || mutationDeletePayId.isError}
            title={watch('amount') <= balance ? 'Withdraw' : 'Insufficient Funds'}
            className="w-full"
            triggerButton={
              <Button
                variant="contained"
                startIcon={<IconWithdraw />}
                className="balance_btn withdraw"
                type="submit"
              >
                Withdraw
              </Button>
            }
            cancelBtnText={watch('amount') <= balance ? 'Cancel' : 'OK'}
            onClickTriggerButton={handleClickWithdrawButton}
            ModalProps={{ maxWidth: 'md' }}
            confirmButton={
              watch('amount') <= balance ? (
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: '#6369FF',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '16px',
                    textTransform: 'initial',
                    minWidth: '127px',
                    py: '10px',
                    borderColor: 'transparent',
                    '&:hover': {
                      opacity: 0.8,
                      bgcolor: '#6369FF',
                    },
                  }}
                  onClick={() => {
                    handleConfirmTransaction(TypeTransactionEnum?.WITHDRAW);
                  }}
                >
                  Yes
                </Button>
              ) : (
                <></>
              )
            }
          >
            {watch('amount') <= balance ? (
              <>
                <Box sx={{ marginBottom: '4px', textAlign: 'center' }}>
                  Withdraw{' '}
                  <Box component="span" sx={{ fontWeight: '600' }}>
                    {formatCurrency(Number(watch('amount')))}
                  </Box>{' '}
                  from Tippe account
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  To PayID:
                  <Box
                    component="span"
                    sx={{
                      fontWeight: '600',
                      marginLeft: '4px',
                      marginRight: '2px',
                      wordBreak: 'break-all',
                    }}
                  >
                    {/* @ts-ignore */}
                    {watch('payid') && mapPayId[watch('payid')]}
                  </Box>
                  <IconCircleCheck />
                </Box>
              </>
            ) : (
              <Box sx={{ marginBottom: '4px', textAlign: 'center' }}>
                Withdraw Amount Greater Than Account Balance. Please Enter a Different Amount
              </Box>
            )}
          </ModalConfirm>
        </div>
      </form>
    </StyledBalancePage>
  );
}

const StyledBalancePage = styled.div`
  padding: 23px 16px 0 16px;
  margin-bottom: 72px;

  .mb-24 {
    margin-bottom: 24px;
  }
  .btn_group {
    display: flex;
  }
  .balance_btn {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    opacity: 0.8;
    text-transform: capitalize;
    padding: 12px 8px;
    border-radius: 8px;
    width: 100%;
    &.top_up {
      background-color: #52d2fc;
    }
    &.withdraw {
      background-color: #8591ff;
    }
  }
  .w-full {
    width: 100%;
  }
  .mr-16 {
    margin-right: 16px;
  }
`;
