import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';

import { IResAddress } from '@interfaces';
import { Input, InputSelect, MainButton, Breadcrumb } from '@components';
import { Country, State, City } from 'country-state-city';
import { adminApi } from '@api';
import { openToast, selectUser, setLoading, useAppSelector } from '@redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATH_ADMIN_HOME } from '@router';

export const ResAddressPage = () => {
  const userTeleId = useAppSelector(selectUser)?.user_tele_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resAddressSchema = Yup.object().shape({
    address_line_first: Yup.string().trim().required('The Address Line 1 field is required'),
    country: Yup.string().trim().required('The Country field is required'),
    state: Yup.string()
      .nullable()
      .test('checkState', 'The State field is required', function (val) {
        console.log('this', this); // for debug
        const countryCode = this?.parent?.country;

        // only use this for validation
        const listStatesTemp = State.getStatesOfCountry(countryCode);

        // check if that country has any states, if not, no validation needed
        if (listStatesTemp?.length && !val) {
          console.log('all val (has states): '); // for debug

          return false;
        }

        if (!listStatesTemp?.length) {
          console.log('all val (not has states): '); // for debug

          return true;
        }

        console.log('no ideal state, ', val); // for debug

        return true;
      }),
    city: Yup.string()
      .nullable()
      .test('checkCity', 'The City field is required', function (val) {
        const countryCode = this?.parent?.country;
        const stateCode = this?.parent?.state;

        // only use this for validation
        let ListCityTemp: any[] | undefined;

        if (countryCode && stateCode) {
          ListCityTemp = City.getCitiesOfState(countryCode, stateCode);
        }

        if (countryCode && !stateCode) {
          ListCityTemp = City.getCitiesOfCountry(countryCode);
        }

        // check if that country has any city, if not, no validation needed
        if (ListCityTemp?.length && !val) {
          console.log('all val (has city): '); // for debug

          return false;
        }

        if (!ListCityTemp?.length) {
          console.log('all val (not has city): '); // for debug

          return true;
        }

        console.log('no ideal city, ', val); // for debug

        return true;
      }),
    postcode: Yup.string().trim().required('The Post code field is required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IResAddress>({
    mode: 'onTouched',
    resolver: yupResolver(resAddressSchema),
    defaultValues: {},
  });

  const submitResAddress = async (formData: IResAddress) => {
    try {
      dispatch(setLoading(true));
      console.log('formData: ', formData); // for debug
      if (!userTeleId) return;

      await adminApi.addResAddress(formData, userTeleId?.toString());
      dispatch(setLoading(false));
      navigate(`/${PATH_ADMIN_HOME}`); // back to home
      dispatch(openToast({ message: 'Your address was saved successfully.', type: 'success' }));
    } catch (error) {
      console.log('error submit: ', error); // for debug
      dispatch(setLoading(false));
      dispatch(
        openToast({
          message: "We're sorry, an error has occurred. Please try again.",
          type: 'error',
        })
      );
    }
  };

  const getResAddress = async (tele_id: string) => {
    try {
      dispatch(setLoading(true));
      if (!tele_id) return;
      const res = await adminApi.getResAddress(tele_id);
      console.log(res?.data?.data); // for debug
      reset({ ...res?.data?.data });
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };

  const handleChangeCountry = (selectedOption: any) => {
    setValue('country', selectedOption.value, { shouldValidate: true });
    // @ts-ignore
    setValue('state', null, { shouldValidate: true });
    // @ts-ignore
    setValue('city', null, { shouldValidate: true });
    setValue('postcode', undefined, { shouldValidate: true });
  };

  const handleChangeState = (selectedOption: any) => {
    setValue('state', selectedOption.value, { shouldValidate: true });
    // @ts-ignore
    setValue('city', null, { shouldValidate: true });
    setValue('postcode', undefined, { shouldValidate: true });
  };

  const handleChangeCity = (selectedOption: any) => {
    setValue('city', selectedOption.value, { shouldValidate: true });
    setValue('postcode', undefined, { shouldValidate: true });
  };

  // init list countries (just need name, flag, and isoCode)
  const listCountry = useMemo(() => {
    console.log('reinit');
    const res = Country.getAllCountries().map((item) => {
      return {
        label: item?.name,
        value: item?.isoCode,
        // flag: item?.flag,
      };
    });
    console.log('list countries: ', res); // for debug

    return res;
  }, []);

  // handle list states (by country code get from form state)
  const getListState = (code?: string) => {
    if (!code) {
      console.log('no country code yet!');

      return [];
    }

    const res = State.getStatesOfCountry(code)?.map((item) => {
      return {
        label: item?.name,
        value: item?.isoCode,
      };
    });

    console.log('list states: ', res); // for debug

    return res;
  };

  // handle list city (by state code and country code get from form state)
  const getListCity = (countryCode?: string, stateCode?: string) => {
    if (!countryCode && !stateCode) {
      console.log('no code for city yet!');

      return [];
    }

    // handle no state
    if (!stateCode && countryCode) {
      const res = City.getCitiesOfCountry(countryCode)?.map((item) => {
        return { label: item?.name, value: item?.name };
      });
      console.log('city of country: ', res); // for debug

      return res;
    }

    // handle both state and country
    if (countryCode && stateCode) {
      const res = City.getCitiesOfState(countryCode, stateCode)?.map((item) => {
        return { label: item?.name, value: item?.name };
      });
      console.log('city of state: ', res); // for debug

      return res;
    }

    // default
    console.log('no case handle, return empty [] !!!'); // for debug

    return [];
  };

  // init list states and city
  const listStates = useMemo(() => {
    return getListState(watch('country'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('country')]);

  const listCity = useMemo(() => {
    return getListCity(watch('country'), watch('state'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('country'), watch('state')]);

  // effects
  useEffect(() => {
    getResAddress(userTeleId?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // main return
  return (
    <StyledResAddress>
      <Breadcrumb />
      <form onSubmit={handleSubmit(submitResAddress)}>
        <Input
          className="mb-24"
          label="Address Line 1"
          name="address_line_first"
          error={errors.address_line_first}
          type="text"
          register={register('address_line_first')}
          shrink
        />
        <Input
          className="mb-24"
          label="Address Line 2"
          name="address_line_second"
          error={errors.address_line_second}
          type="text"
          register={register('address_line_second')}
          shrink
        />
        <InputSelect
          className="mb-24"
          register={register('country')}
          options={listCountry}
          name="country"
          placeholder="Country"
          error={errors.country}
          onChange={handleChangeCountry}
          value={listCountry.find((country) => country.value === watch('country'))}
        />
        <InputSelect
          className="mb-24"
          register={register('state')}
          options={listStates}
          name="state"
          placeholder="State"
          error={errors.state}
          onChange={handleChangeState}
          value={listStates?.find((state) => state.value === watch('state')) || null}
        />
        <div className="row">
          <InputSelect
            className="mb-24 row_item mr-20"
            register={register('city')}
            options={listCity}
            name="city"
            placeholder="City"
            error={errors.city}
            onChange={handleChangeCity}
            value={listCity?.find((city) => city.value === watch('city')) || null}
          />
          <Input
            className="mb-24 row_item zip_code"
            placeholder="Post/Zip Code"
            name="postcode"
            error={errors.postcode}
            type="number"
            register={register('postcode')}
            shrink={false}
          />
        </div>
        <MainButton text="Save changes" type="submit" />
      </form>
    </StyledResAddress>
  );
};

const StyledResAddress = styled.div`
  min-height: calc(100vh - 24px);
  padding: 0 16px;
  form {
    margin-top: 36px;
  }
  .mb-24 {
    margin-bottom: 24px;
    &.row_item {
      width: 50%;
    }
    &.mr-20 {
      margin-right: 20px;
    }
  }
  .row {
    display: flex;
  }
  .zip_code {
    /* z-index: -1; */
    .MuiInputLabel-outlined {
      z-index: -1;
    }
  }
`;
