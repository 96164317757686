import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type IProps = {
  text: string;
  icon: ReactElement;
  path: string;
  onClick?: () => void;
  disabled: boolean;
};

export function MenuButton(props: IProps) {
  const navigate = useNavigate();

  const { text, path, icon, onClick, disabled } = props;

  return (
    <StyledMenuButton
      onClick={disabled ? undefined : !onClick ? () => navigate(`/${path}`) : onClick}
      className={disabled ? 'disabled' : ''}
    >
      <div className="icon_wrapper">{icon}</div>
      <div className="title">{text}</div>
    </StyledMenuButton>
  );
}

const StyledMenuButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    opacity: 0.8;
  }
  .icon_wrapper {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
`;
