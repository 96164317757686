/* eslint-disable react-hooks/exhaustive-deps */
import { CssBaseline } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TelegramClient } from 'telegram';
import { StringSession } from 'telegram/sessions';

import { appApi } from '@api';
import { Toast } from '@components';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  clearTelegramClient,
  openToast,
  resetAllAuth,
  selectQueryId,
  selectSocketConnected,
  selectStep,
  selectUser,
  setBalance,
  setIsVerify,
  setLoading,
  setQueryId,
  setSocketIsConnected,
  setStep,
  setTelegramClient,
  setUser,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import Router, {
  mainRoutes,
  PATH_ADMIN_HOME,
  PATH_HOME,
  PATH_QR_CODE,
  profileRoutes,
  qrRoutes,
  reviewRoutes,
  stepRoutes,
} from '@router';

import { apiHash, apiId, isDevMode } from '@configs';
import { socket } from '@socket';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './index.css';
import { queryClient } from './libs/react-query';

const theme = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans',
    allVariants: {
      color: '#171717',
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }

        body {
          padding: 0;
          margin: 0;
          color: #171717;
        }
      `,
    },
  },
});
function App() {
  // @ts-ignore
  const telegram = window.Telegram.WebApp;
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // redux states
  const step = useAppSelector(selectStep);
  const session = useAppSelector(selectUser)?.session;
  const socketIsConnected = useAppSelector(selectSocketConnected);
  const user = useAppSelector(selectUser);
  const query_id_redux = useAppSelector(selectQueryId);

  // refs
  const socketFirstTimeConnected = useRef<boolean>(false);

  // functions
  const checkInfo = async (user_id: string) => {
    try {
      const res = await appApi.checkInfoByUserId(user_id);
      console.log('res checkInfo: ', res?.data?.data); // for debug
      dispatch(setIsVerify(res?.data?.data));
    } catch (error) {
      console.log(error); // for debug
      dispatch(setIsVerify(false));
    }
  };

  const getAllInfo = async (user_id: string, query_id?: string) => {
    try {
      const res = await appApi.getTwofactor(user_id);
      console.log('res get all 2fa_auth: ', res); // for debug
      // const personal = await adminApi.getPersonalDetail(user_id);
      dispatch(
        setUser({
          ...res.data,
          query_id,
          //  id: personal?.data?.data?.user?.id
        })
      );
    } catch (error) {
      console.log(error);
      console.log('reset all auth cache... (gegetAllInfo)'); // for debug
      dispatch(resetAllAuth()); // clear cached user
    }
  };

  // this function create a telegram client instance for later uses (only connect 1 time)
  const initTeleClient = useCallback(async (sessionStr: string | undefined) => {
    try {
      dispatch(setLoading(true));
      console.log('init telegram client...'); // for debug
      // init and connect telegram client
      const newSession = new StringSession(sessionStr);
      const client = new TelegramClient(newSession, apiId, apiHash, {
        connectionRetries: 5,
        autoReconnect: true,
      });
      console.log('client cp: ', client); // for debug
      await client.connect(); // This assumes you have already authenticated with .start()
      // cache the client into redux
      dispatch(setTelegramClient(client));
      // end of init and connect telegram client
      console.log('telegram client init done!'); // for debug
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          message: 'Cannot initial telegram client, please try reload the page',
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // effects
  useEffect(() => {
    if (session) {
      initTeleClient(session);
    } else {
      dispatch(clearTelegramClient); // if there is no session string on db (cache), clear the client instance since it has expired
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    if (user) console.log('redux state => user: ', user); // for debug
  }, [user]);

  useEffect(() => {
    telegram.ready();
    console.log('telegram: ', telegram); // for debug
    telegram.isClosingConfirmationEnabled = false;

    const handleClickBack = () => {
      const isStepRoute = stepRoutes.some((item: string) => pathname.includes(item));
      const isProfileRoute = profileRoutes.some((item: string) => pathname.slice(1) === item);
      const isQRRoute = qrRoutes.some((item: string) => pathname.slice(1) === item);
      const isMainRoute = mainRoutes.some((item: string) => pathname.slice(1) === item);
      const isReviewRoute = reviewRoutes.some((item: string) => pathname.includes(item));

      if (isStepRoute && step > 0) {
        dispatch(setStep(Math.round(step - 1)));
      } else if (isProfileRoute) {
        navigate(`/${PATH_ADMIN_HOME}`);
      } else if (isQRRoute) {
        navigate(`/${PATH_QR_CODE}`);
      } else if (isMainRoute || isReviewRoute) {
        navigate(PATH_HOME);
      } else {
        navigate(-1);
      }
    };

    if (pathname === PATH_HOME) {
      telegram.BackButton.hide();
    } else {
      telegram.BackButton.show();
      telegram.BackButton.onClick(handleClickBack);
    }

    return () => {
      telegram.BackButton.offClick(handleClickBack);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telegram, step, pathname]);

  useEffect(() => {
    (async () => {
      if (isDevMode) {
        // hieu
        checkInfo('1157652146'); // hard code
        getAllInfo('1157652146', ''); // hard code

        // // truong
        // checkInfo('5392632048'); // hard code
        // getAllInfo('5392632048', ''); // hard code

        // // a duc
        // checkInfo('1037642048'); // hard code
        // getAllInfo('1037642048', ''); // hard code

        console.log('userId in dev mode:', user.id);

        // socket.emit('add-user', {
        //   user_tele_id: user?.user_tele_id,
        //   user_id: user?.id,
        // });

        return;
      }

      if (telegram?.initDataUnsafe?.user) {
        console.log('checking init data unsafe...'); // for debug

        checkInfo(telegram?.initDataUnsafe?.user?.id);
        getAllInfo(telegram?.initDataUnsafe?.user?.id, telegram?.initDataUnsafe?.query_id);

        dispatch(setQueryId(telegram?.initDataUnsafe?.query_id)); // save the query_id to redux

        console.log('userId', user.id); // for debug
        console.log('user query ID raw: ', telegram?.initDataUnsafe?.query_id); // for debug
        console.log('user query ID redux: ', query_id_redux); // for debug

        // socket.emit('add-user', {
        //   user_tele_id: user?.user_tele_id,
        //   user_id: user?.id,
        // });
      } else {
        dispatch(resetAllAuth()); // clear cached auth
        console.log('reset all auth cache... (not have telegram obj)'); // for debug
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // socket.io
  useEffect(() => {
    socket.on('connect', () => {
      console.log('socket connected!');
      dispatch(setSocketIsConnected(true));
    });

    socket.on('disconnect', () => {
      console.log('socket disconnected!');
      dispatch(setSocketIsConnected(false));
    });

    socket.on('connect_error', (err) => {
      // if (err.message === 'invalid username') {
      //   this.usernameAlreadySelected = false;
      // }
    });

    socket.onAny((event, ...args) => {
      console.log('socket onAny: ', event, args);
    });

    socket.on('response-transfer-money', (args) => {
      console.log('Balance', Number(args?.data?.balance));
      dispatch(setBalance(Number(args?.data?.balance)));
    });

    socket.on('response-event-success', (args) => {
      console.log('Balance', Number(args)); // for debug
      navigate('/'); // back to home page
      dispatch(openToast({ message: 'payment submited!', type: 'success' }));
    });

    socket.on('get-user', (args) => {
      console.log('get-user: ', args);
    });

    // custom events

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.off('response-transfer-money');
      // socket.close();
    };
  }, []);

  useEffect(() => {
    if (
      socketIsConnected &&
      user?.user_tele_id &&
      user?.userChat?.user_id &&
      socketFirstTimeConnected.current === false
    ) {
      console.log('user add-user socket: ', user);
      socket.emit('add-user', {
        user_tele_id: user?.user_tele_id,
        user_id: user?.userChat?.user_id,
      });
      socketFirstTimeConnected.current = true; // this prevent multiple re-emit (this emit should only fire one time when socket is connected)
    }
  }, [socketIsConnected, user?.user_tele_id, user?.userChat?.user_id]);

  console.log('app start...   CLIENT   ....'); // for debug

  const [queryClientConfig] = useState(queryClient);

  // main return
  return (
    <QueryClientProvider client={queryClientConfig}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={{ body: { color: '#171717', margin: 0, padding: 0 } }} />
        <Router />
        <Toast />
      </ThemeProvider>
      <ReactQueryDevtools
        initialIsOpen={false}
        panelProps={{ style: { minHeight: 250 } }}
        position="bottom-right"
      />
    </QueryClientProvider>
  );
}

export default App;
