import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBalanceInfo, IPaymentInfo, IToast } from '@interfaces';
import { TelegramClient } from 'telegram';
import { RootState } from '.';
import { IRequestPayment } from '@/pages';

type IState = {
  loading: boolean;
  toast: IToast;
  balance: number;
  paymentInfo: IPaymentInfo;
  balanceInfo?: IBalanceInfo;
  requestPayment?: IRequestPayment;
  receiptStatus?: boolean;
  telegramClient?: TelegramClient;
};

const initialState: IState = {
  loading: false,
  toast: {
    autoHideDuration: 2000,
    vertical: 'top',
    horizontal: 'center',
    open: false,
    type: 'success',
    message: '',
  },
  paymentInfo: {},
  balance: 0,
  balanceInfo: undefined,
  requestPayment: undefined,
  receiptStatus: false, // switch between true or false (used for dependencie in useEffect - Pay request payment - auto re fetch the receipt data after done)
  telegramClient: undefined,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    openToast: (state, action: PayloadAction<IToast>) => {
      const { vertical, horizontal, open, autoHideDuration, type, message } = action.payload;
      const newState: IToast = {
        vertical: vertical || 'top',
        horizontal: horizontal || 'center',
        open: open || true,
        autoHideDuration: autoHideDuration === null ? null : autoHideDuration || 2000,
        type: type || 'success',
        message,
      };
      state.toast = newState;
    },
    closeToast: (state) => {
      state.toast.open = false;
    },
    setPaymentInfo: (state, action) => {
      state.paymentInfo = action.payload;
    },
    setBalanceInfo: (state, action) => {
      state.balanceInfo = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setRequestPayment: (state, action) => {
      state.requestPayment = action.payload;
    },
    setReceiptStatus: (state, action) => {
      state.receiptStatus = action.payload;
    },
    // telegram client
    setTelegramClient: (state, action) => {
      state.telegramClient = action.payload;
    },
    clearTelegramClient: (state, action) => {
      state.telegramClient = undefined;
    },
  },
});

export const {
  setLoading,
  openToast,
  closeToast,
  setPaymentInfo,
  setBalanceInfo,
  setRequestPayment,
  setReceiptStatus,
  setBalance,
  setTelegramClient,
  clearTelegramClient,
} = appSlice.actions;

export const selectApp = (state: RootState) => state.app;
export const selectToast = (state: RootState) => state.app.toast;
export const selectPaymentInfo = (state: RootState) => state.app.paymentInfo;
export const selectBalanceInfo = (state: RootState) => state.app.balanceInfo;
export const selectBalance = (state: RootState) => state.app.balance;
export const selectRequestPayment = (state: RootState) => state.app.requestPayment;
export const selectReceiptStatus = (state: RootState) => state.app.receiptStatus;
export const selectTelegramClient = (state: RootState) => state.app.telegramClient;

export default appSlice.reducer;
