import React, { useState, useEffect } from 'react';

import {
  useAppDispatch,
  setLoading,
  useAppSelector,
  selectBalanceInfo,
  selectBalance,
  setBalance,
} from '@/redux';

import { adminApi } from '@/api';

export function useBalance(userId: string | number) {
  const dispatch = useAppDispatch();

  const balance = useAppSelector(selectBalance);

  const balanceInfoRedux = useAppSelector(selectBalanceInfo);

  const getBalance = async () => {
    try {
      console.log('startGetBalance, id: ', userId);
      dispatch(setLoading(true));
      const res = await adminApi.getBalance(Number(userId));
      dispatch(setBalance(res?.data?.data?.balance));
      console.log('balance: ', res); // for debug
    } catch (error: any) {
      console.log('error: ', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    // if (balanceInfoRedux === null) return;
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceInfoRedux, userId]);

  return { balance, getBalance };
}
