import React from 'react';

export function IconNoImage() {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M3.69006 25.8468L3.6634 25.8735C3.3034 25.0868 3.07673 24.1935 2.9834 23.2068C3.07673 24.1801 3.33006 25.0601 3.69006 25.8468Z"
          fill="#8E8E93"
        />
        <path
          d="M3.69006 25.8468L3.6634 25.8735C3.3034 25.0868 3.07673 24.1935 2.9834 23.2068C3.07673 24.1801 3.33006 25.0601 3.69006 25.8468Z"
          fill="#8F8F8F"
        />
        <path
          d="M12.25 14.3401C14.0026 14.3401 15.4233 12.9193 15.4233 11.1667C15.4233 9.41416 14.0026 7.99341 12.25 7.99341C10.4974 7.99341 9.07666 9.41416 9.07666 11.1667C9.07666 12.9193 10.4974 14.3401 12.25 14.3401Z"
          fill="#8E8E93"
        />
        <path
          d="M12.25 14.3401C14.0026 14.3401 15.4233 12.9193 15.4233 11.1667C15.4233 9.41416 14.0026 7.99341 12.25 7.99341C10.4974 7.99341 9.07666 9.41416 9.07666 11.1667C9.07666 12.9193 10.4974 14.3401 12.25 14.3401Z"
          fill="#8F8F8F"
        />
        <path
          d="M21.8365 3.16675H10.6632C5.80984 3.16675 2.9165 6.06008 2.9165 10.9134V22.0868C2.9165 23.5401 3.16984 24.8067 3.66317 25.8734C4.80984 28.4067 7.26317 29.8334 10.6632 29.8334H21.8365C26.6898 29.8334 29.5832 26.9401 29.5832 22.0868V19.0334V10.9134C29.5832 6.06008 26.6898 3.16675 21.8365 3.16675ZM27.4098 17.1667C26.3698 16.2734 24.6898 16.2734 23.6498 17.1667L18.1032 21.9267C17.0632 22.8201 15.3832 22.8201 14.3432 21.9267L13.8898 21.5534C12.9432 20.7267 11.4365 20.6467 10.3698 21.3667L5.38317 24.7134C5.08984 23.9667 4.9165 23.1001 4.9165 22.0868V10.9134C4.9165 7.15341 6.90317 5.16675 10.6632 5.16675H21.8365C25.5965 5.16675 27.5832 7.15341 27.5832 10.9134V17.3134L27.4098 17.1667Z"
          fill="#8E8E93"
        />
        <path
          d="M21.8365 3.16675H10.6632C5.80984 3.16675 2.9165 6.06008 2.9165 10.9134V22.0868C2.9165 23.5401 3.16984 24.8067 3.66317 25.8734C4.80984 28.4067 7.26317 29.8334 10.6632 29.8334H21.8365C26.6898 29.8334 29.5832 26.9401 29.5832 22.0868V19.0334V10.9134C29.5832 6.06008 26.6898 3.16675 21.8365 3.16675ZM27.4098 17.1667C26.3698 16.2734 24.6898 16.2734 23.6498 17.1667L18.1032 21.9267C17.0632 22.8201 15.3832 22.8201 14.3432 21.9267L13.8898 21.5534C12.9432 20.7267 11.4365 20.6467 10.3698 21.3667L5.38317 24.7134C5.08984 23.9667 4.9165 23.1001 4.9165 22.0868V10.9134C4.9165 7.15341 6.90317 5.16675 10.6632 5.16675H21.8365C25.5965 5.16675 27.5832 7.15341 27.5832 10.9134V17.3134L27.4098 17.1667Z"
          fill="#8F8F8F"
        />
      </g>
    </svg>
  );
}
