import { DefaultOptions, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

function getMinutes(val: number) {
  return val * 60 * 1000;
}

function queryErrorHandler(error: AxiosError['response']): void {
  const id = 'react-query-error';
  const titleError =
    error instanceof Error
      ? // remove the initial 'Error: ' that accompanies many errors
        error.toString().replace(/^Error:\s*/, '')
      : 'error connecting to server';
  console.log({ id, title: titleError });
}

const defaultOptions: DefaultOptions<AxiosError['response']> = {
  queries: {
    onError: queryErrorHandler,
    staleTime: getMinutes(2), // 2 minutes

    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  },
  mutations: {
    onError: queryErrorHandler,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: defaultOptions as DefaultOptions,
});
