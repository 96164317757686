import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  ExtractFnReturnType,
  IDataResponse,
  MutationConfig,
  QueryConfig,
} from '@/libs/react-query';

import { request } from '@/libs/request';

import { useGlobalLoading } from '@/hooks';
import { convertError } from '@/libs/helper';
import { openToast, selectUser, useAppDispatch, useAppSelector } from '@/redux';
import { PATH_PAY_ID } from '@/router';
import { IParamsPayId, IPayIdResponse } from './pay-id.model';

export const payIdUrl = 'api/payid';

function useRefetchPayIdList() {
  const telegramId = useAppSelector(selectUser).user_tele_id;
  const queryClient = useQueryClient();

  return function () {
    queryClient.invalidateQueries([payIdUrl, telegramId]);
  };
}

// -------------------------GetPayId------------------------------
export const getPayIdRequest = (id: string, params?: IParamsPayId) =>
  request<{}, IDataResponse<IPayIdResponse[]>>({
    url: `${payIdUrl}/${id}`,
    method: 'get',
    params,
  });

type QueryPayIdType = typeof getPayIdRequest;

interface IUseGetPayIdOptions {
  config?: QueryConfig<QueryPayIdType>;
  params?: IParamsPayId;
}
export const useQueryGetPayId = ({ config, params }: IUseGetPayIdOptions = {
  // params: { is_verify: 'ALL' },
}) => {
  const telegramId = useAppSelector(selectUser).user_tele_id;

  const { data, isLoading, ...restResult } = useQuery<ExtractFnReturnType<QueryPayIdType>>({
    queryFn: () => getPayIdRequest(String(telegramId), params),
    queryKey: [payIdUrl, telegramId, params],
    enabled: !!telegramId,
    refetchOnMount: true,

    ...config,
  });

  useGlobalLoading(isLoading);

  return { payIdList: data?.data, ...restResult };
};

// -------------------------add pay id------------------------------
export const addPayIdRequest = (data: {
  user_tele_id: number;
  payid?: string;
  type?: 'EMAIL' | 'PHONE' | 'ABNACN';
}) =>
  request<typeof data, IDataResponse<IPayIdResponse>>({
    url: `${payIdUrl}`,
    method: 'POST',
    data,
  });

type MutationAddPayId = typeof addPayIdRequest;
interface IUseMutationAddPayId {
  config?: MutationConfig<MutationAddPayId>;
}

export const useMutationAddPayId = ({ config }: IUseMutationAddPayId = {}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const telegramId = useAppSelector(selectUser).user_tele_id;

  const navigate = useNavigate();

  const resultMutation = useMutation({
    onSuccess(data) {
      if (data.success) {
        dispatch(openToast({ message: 'You have successfully added a PayID.', type: 'success' }));

        navigate(`/${PATH_PAY_ID}`);
      }
    },
    onError(error) {
      const msgError = convertError(error);
      if (msgError) {
        dispatch(openToast({ message: msgError, type: 'error' }));
      }
    },
    onSettled: async () => {
      await queryClient.refetchQueries([payIdUrl, telegramId], { type: 'inactive' });
    },
    ...config,
    mutationFn: addPayIdRequest,
  });

  useGlobalLoading(resultMutation.isLoading);

  return resultMutation;
};

// -------------------------send otp pay id------------------------------
export const sendOtpRequest = (id: string) =>
  request<{}, IDataResponse<{ message: string }>>({
    url: `${payIdUrl}/send-otp-verify/${id}`,
    method: 'POST',
  });

type MutationSendOtpType = typeof sendOtpRequest;
interface IUseMutationSendOtpOptions {
  config?: MutationConfig<MutationSendOtpType>;
}

export const useMutationSendOtpPayId = ({ config }: IUseMutationSendOtpOptions = {}) => {
  const dispatch = useAppDispatch();
  const refetchPayIdList = useRefetchPayIdList();

  const resultMutation = useMutation({
    onSuccess(data) {
      if (data.success) {
        dispatch(openToast({ message: data.data.message }));
        refetchPayIdList();
      }
    },
    ...config,
    mutationFn: sendOtpRequest,
  });

  useGlobalLoading(resultMutation.isLoading);

  return resultMutation;
};

// -------------------------verify otp pay id------------------------------
export const verifyOtpRequest = (data: { id_payid: string; code: string }) =>
  request<typeof data, IDataResponse<{ message: string }>>({
    url: `${payIdUrl}/verify-code`,
    method: 'POST',
    data,
  });

type MutationVerifyOtpType = typeof verifyOtpRequest;
interface IUseMutationVerifyOtpOptions {
  config?: MutationConfig<MutationVerifyOtpType>;
}

export const useMutationVerifyOtpPayId = ({ config }: IUseMutationVerifyOtpOptions = {}) => {
  const dispatch = useAppDispatch();
  const refetchPayIdList = useRefetchPayIdList();

  const navigate = useNavigate();

  const resultMutation = useMutation({
    onSuccess(data) {
      console.log('data: ', data);
      if (data.success) {
        dispatch(openToast({ message: data.data.message, type: 'success' }));
        refetchPayIdList();
        navigate('/admin');
      }
    },
    onError(error) {
      dispatch(openToast({ message: error?.message, type: 'error' }));
    },
    ...config,
    mutationFn: verifyOtpRequest,
  });

  useGlobalLoading(resultMutation.isLoading);

  return resultMutation;
};

// -------------------------delete pay id------------------------------
export const deletePayIdRequest = (id: number) =>
  request<{}, IDataResponse<string>>({
    url: `${payIdUrl}/${id}`,
    method: 'DELETE',
  });

type MutationDeletePayIdType = typeof deletePayIdRequest;
interface IUseMutationDeletePayIdOtpOptions {
  config?: MutationConfig<MutationDeletePayIdType>;
}

export const useMutationDeletePayId = ({ config }: IUseMutationDeletePayIdOtpOptions = {}) => {
  const refetchPayIdList = useRefetchPayIdList();

  const dispatch = useAppDispatch();

  const resultMutation = useMutation({
    onSuccess(data) {
      if (data.success) {
        dispatch(openToast({ message: data.data, type: 'success' }));
        refetchPayIdList();
      }
    },
    onError(error) {
      dispatch(openToast({ message: error?.message, type: 'error' }));
    },
    ...config,
    mutationFn: deletePayIdRequest,
  });

  useGlobalLoading(resultMutation.isLoading);

  return resultMutation;
};
