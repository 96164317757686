import { Typography } from '@material-ui/core';

import { Input, MainButton } from '@components';
import { useStyles } from './style';

type IProps = {
  errors: any;
  register: any;
  isActive: boolean;
  email?: string;
  isValid?: boolean;
  isSubmiting?: boolean;
  skipMail: any;
};

export function CPStepTwo(props: IProps) {
  const classes = useStyles();

  const { register, errors, isActive, email, skipMail } = props;

  return (
    <div className={`${classes.step} ${isActive ? 'active' : ''}`}>
      <Typography className={classes.title}>Recovery email</Typography>
      <Input
        label="Enter recovery email"
        name="recoverEmail"
        error={errors.recoverEmail}
        type="text"
        register={register('recoverEmail')}
      />
      <button className={classes.subAction} onClick={skipMail} type="submit">
        Skip and create my 2FA Password
      </button>
      <MainButton text="Continue" type="submit" disabled={!email} />
    </div>
  );
}
