import React from 'react';

export const ArrowUp = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.45371 4.32048L7.31371 2.18048L6.00704 0.867148C5.45371 0.313815 4.55371 0.313815 4.00037 0.867148L0.54704 4.32048C0.0937065 4.77381 0.420373 5.54715 1.05371 5.54715H4.79371H8.94704C9.58704 5.54715 9.90704 4.77381 9.45371 4.32048Z"
        fill="#30B364"
      />
    </svg>
  );
};
