import React from 'react';

export function ResAddress() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#3CB7FC" />
      <path
        d="M16.31 10.225C15.785 7.915 13.77 6.875 12 6.875C12 6.875 12 6.875 11.995 6.875C10.23 6.875 8.21005 7.91 7.68505 10.22C7.10005 12.8 8.68005 14.985 10.11 16.36C10.64 16.87 11.32 17.125 12 17.125C12.68 17.125 13.36 16.87 13.885 16.36C15.315 14.985 16.895 12.805 16.31 10.225ZM12 12.73C11.13 12.73 10.425 12.025 10.425 11.155C10.425 10.285 11.13 9.58 12 9.58C12.87 9.58 13.575 10.285 13.575 11.155C13.575 12.025 12.87 12.73 12 12.73Z"
        fill="white"
      />
    </svg>
  );
}
