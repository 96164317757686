import React from 'react';

export function IconVoice() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#6369FF" />
      <path
        d="M17.9998 26.275C13.7998 26.275 10.3748 22.8584 10.3748 18.65V17.0834C10.3748 16.7584 10.6414 16.5 10.9581 16.5C11.2748 16.5 11.5414 16.7667 11.5414 17.0834V18.65C11.5414 22.2084 14.4331 25.1 17.9914 25.1C21.5498 25.1 24.4414 22.2084 24.4414 18.65V17.0834C24.4414 16.7584 24.7081 16.5 25.0248 16.5C25.3414 16.5 25.6081 16.7667 25.6081 17.0834V18.65C25.6248 22.8584 22.1998 26.275 17.9998 26.275Z"
        fill="white"
      />
      <path
        d="M17.9998 9.6667C15.1998 9.6667 12.9165 11.95 12.9165 14.75V18.6584C12.9165 21.4584 15.1998 23.7417 17.9998 23.7417C20.7998 23.7417 23.0832 21.4584 23.0832 18.6584V14.75C23.0832 11.95 20.7998 9.6667 17.9998 9.6667ZM19.8165 16.825C19.7582 17.05 19.5498 17.2 19.3248 17.2C19.2832 17.2 19.2332 17.1917 19.1915 17.1834C18.3415 16.95 17.4415 16.95 16.5915 17.1834C16.3165 17.2584 16.0415 17.1 15.9665 16.825C15.8915 16.5584 16.0498 16.275 16.3248 16.2C17.3498 15.9167 18.4332 15.9167 19.4582 16.2C19.7332 16.275 19.8915 16.55 19.8165 16.825ZM20.5248 14.5167C20.4498 14.725 20.2582 14.85 20.0498 14.85C19.9915 14.85 19.9332 14.8417 19.8748 14.8167C18.5998 14.35 17.1998 14.35 15.9248 14.8167C15.6582 14.9167 15.3665 14.7834 15.2665 14.5167C15.1748 14.2584 15.3082 13.9667 15.5748 13.8667C17.0748 13.325 18.7248 13.325 20.2165 13.8667C20.4832 13.9667 20.6165 14.2584 20.5248 14.5167Z"
        fill="white"
      />
    </svg>
  );
}
