/* eslint-disable import/order */
import { memo, useEffect } from 'react';
import { RouteObject, useLocation, useNavigate, useRoutes } from 'react-router-dom';

import { LoginPageTest } from '@/pages/auth/LoginPage/LoginPageTest';
import { AppLayout, AuthLayout } from '@layouts';
import {
  AccountContact,
  AddPayIDPage,
  BalancePage,
  BalanceWaitingPage,
  CreatePasswordPage,
  CreatePasswordSuccessPage,
  EnterPinPage,
  // AdminHomePage,
  HelpPage,
  IDDocsPage,
  PayAnyOne,
  PayIDPage,
  PayInvoice,
  PayRequestPayment,
  PersonalDetails,
  ProfilePage,
  QRCodePage,
  QRReceivePage,
  QRScannerPage,
  QRSharePage,
  ReceiptTransaction,
  RecoverAccountPage,
  RequestPayment,
  ResAddressPage,
  SearchTrxnHistory,
  SonicPay,
  StartPage,
  TrxnHistory,
  VerifyContactPage,
  VerifyPayIDPage,
} from '@pages';
import { selectUser, useAppSelector } from '@redux';
import { RequireVerify } from 'src/components/RequireVerify';
// import { useAppSelector, selectUser } from "@redux";

export const PATH_VERIFY_CODE = 'verify-code';

export const PATH_CREATE_PASSWORD = 'create-password';
export const PATH_CREATE_PASSWORD_SUCCESS = 'create-password-success';
export const PATH_ENTER_PIN = 'enter-pin';

export const PATH_HOME = '/';
export const PATH_ADMIN_HOME = 'admin';
export const PATH_RES_ADDRESS = 'res-address';
export const PATH_RECOVER_ACCOUNT = 'recover-account';
export const PATH_ID_DOCS = 'id-docs';
export const PATH_PAY_ID = 'pay-id';
export const PATH_ADD_PAY_ID = 'pay-id/add-pay-id';
export const PATH_SETTINGS = 'settings';
export const PATH_PERSONAL_DETAILS = 'personal-details';
export const PATH_ACCOUNT_CONTACT = 'account-contact';
export const PATH_ACCOUNT_CONTACT_VERIFY = 'verify';
export const PATH_BALANCE = 'balance';
export const PATH_BALANCE_WAITING_TOP_UP = 'balance/top-up';
export const PATH_BALANCE_WAITING_WITHDRAW = 'balance/withdraw';
export const PATH_QR_CODE = 'qr-code';
export const PATH_QR_SCANNER = 'qr-code/scanner';
export const PATH_QR_RECEIVE = 'qr-code/receive';
export const PATH_QR_SHARE = 'qr-code/share';
export const PATH_REQUEST_PAYMENT = 'request-payment';
export const PATH_PAY_REQUEST_PAYMENT = 'pay-requested-payment';
export const PATH_TRXN_HISTORY = 'trxn-history';
export const PATH_PAY_ANYONE = 'pay-anyone';
export const PATH_RECEIPT_TRANSACTION = 'receipt';
export const PATH_PAY_INVOICE_ORDER = 'pay-invoice-order';
export const PATH_SONIC_PAY = 'sonic-pay';

export const PATH_VERIFY_PAY_ID = 'verify-pay-id';

export const PATH_HELP = 'help';

export const PATH_NOT_FOUND = 'not-found';

export const normalRoutes = [
  PATH_ADMIN_HOME,
  PATH_RES_ADDRESS,
  PATH_PAY_ID,
  PATH_SETTINGS,
  PATH_RECOVER_ACCOUNT,
  PATH_PERSONAL_DETAILS,
  PATH_ACCOUNT_CONTACT,
  PATH_ID_DOCS,
  PATH_ENTER_PIN,
  PATH_PAY_ID,
  PATH_ADD_PAY_ID,
  PATH_BALANCE,
  PATH_BALANCE_WAITING_TOP_UP,
  PATH_BALANCE_WAITING_WITHDRAW,
  PATH_QR_CODE,
  PATH_QR_RECEIVE,
  PATH_QR_SCANNER,
  PATH_QR_SHARE,
  PATH_REQUEST_PAYMENT,
  PATH_TRXN_HISTORY,
  PATH_PAY_REQUEST_PAYMENT,
  PATH_PAY_ANYONE,
  PATH_RECEIPT_TRANSACTION,
  PATH_PAY_INVOICE_ORDER,
  PATH_SONIC_PAY,
];

export const authRoutes = [PATH_VERIFY_CODE];

export const mainRoutes = [PATH_ADMIN_HOME, PATH_BALANCE, PATH_QR_CODE, PATH_TRXN_HISTORY];

export const profileRoutes = [
  PATH_PERSONAL_DETAILS,
  PATH_ACCOUNT_CONTACT,
  PATH_RES_ADDRESS,
  PATH_ID_DOCS,
  PATH_PAY_ID,
  PATH_RECOVER_ACCOUNT,
  PATH_VERIFY_PAY_ID,
];

export const qrRoutes = [PATH_QR_RECEIVE, PATH_QR_SCANNER, PATH_QR_SHARE];

export const reviewRoutes = [
  PATH_RECEIPT_TRANSACTION,
  PATH_PAY_REQUEST_PAYMENT,
  PATH_PAY_INVOICE_ORDER,
];

export const stepRoutes = [PATH_CREATE_PASSWORD, PATH_REQUEST_PAYMENT, PATH_PAY_ANYONE];

function Router() {
  const session = useAppSelector(selectUser)?.session; // the telegram session get from api (main session, after login and create 2fa password)
  const routes: RouteObject[] = [
    {
      path: PATH_HOME,
      element: <AuthLayout />,
      children: [
        { path: PATH_HOME, element: <StartPage /> },
        { path: PATH_VERIFY_CODE, element: <LoginPageTest /> },
        { path: PATH_CREATE_PASSWORD, element: <CreatePasswordPage /> },
        {
          path: PATH_CREATE_PASSWORD_SUCCESS,
          element: <CreatePasswordSuccessPage />,
        },
        { path: PATH_HELP, element: <HelpPage /> },
        { path: PATH_ENTER_PIN, element: <EnterPinPage /> },
      ],
    },
    {
      path: PATH_HOME,
      element: <AppLayout />,
      children: [
        // admin not verified
        {
          path: PATH_ADMIN_HOME,
          element: <ProfilePage />,
        },

        {
          path: PATH_ACCOUNT_CONTACT,

          children: [
            { index: true, element: <AccountContact /> },
            {
              path: PATH_ACCOUNT_CONTACT_VERIFY,
              element: <VerifyContactPage />,
            },
          ],
        },

        {
          path: PATH_RECOVER_ACCOUNT,
          element: <RecoverAccountPage />,
        },
      ],
    },

    {
      path: PATH_HOME,

      element: (
        <RequireVerify>
          <AppLayout />
        </RequireVerify>
      ),
      children: [
        // admin protected when verified
        {
          path: PATH_RES_ADDRESS,
          element: <ResAddressPage />,
        },

        {
          path: PATH_PERSONAL_DETAILS,
          element: <PersonalDetails />,
        },

        {
          path: PATH_ID_DOCS,
          element: <IDDocsPage />,
        },
        {
          path: PATH_PAY_ID,
          element: <PayIDPage />,
        },
        {
          path: PATH_ADD_PAY_ID,
          element: <AddPayIDPage />,
        },
        {
          path: PATH_VERIFY_PAY_ID,
          element: <VerifyPayIDPage />,
        },
        {
          path: PATH_BALANCE,
          element: <BalancePage />,
        },
        {
          path: PATH_BALANCE_WAITING_TOP_UP,
          element: <BalanceWaitingPage />,
        },
        {
          path: PATH_BALANCE_WAITING_WITHDRAW,
          element: <BalanceWaitingPage />,
        },
        {
          path: PATH_QR_CODE,
          element: <QRCodePage />,
        },
        {
          path: PATH_QR_SCANNER,
          element: <QRScannerPage />,
        },
        {
          path: PATH_QR_RECEIVE,
          element: <QRReceivePage />,
        },
        {
          path: PATH_QR_SHARE,
          element: <QRSharePage />,
        },
        {
          path: `${PATH_PAY_REQUEST_PAYMENT}/:trxnId`,
          element: <PayRequestPayment />,
        },
        {
          path: PATH_REQUEST_PAYMENT,
          element: <RequestPayment />,
        },
        {
          path: PATH_TRXN_HISTORY,
          element: <TrxnHistory />,
        },
        {
          path: `${PATH_TRXN_HISTORY}/search`,
          element: <SearchTrxnHistory />,
        },
        {
          path: PATH_PAY_ANYONE,
          element: <PayAnyOne />,
        },
        {
          path: `${PATH_RECEIPT_TRANSACTION}/:trxnId`,
          element: <ReceiptTransaction />,
        },
        {
          path: `${PATH_PAY_INVOICE_ORDER}/:trxnId`,
          element: <PayInvoice />,
        },
        {
          path: PATH_SONIC_PAY,
          element: <SonicPay />,
        },
      ],
    },
  ];

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // WHAT: check normal path
    const isNormalRoute =
      pathname === PATH_HOME
        ? true
        : normalRoutes.some((item: string) => {
            if (item === PATH_HOME) {
              return false;
            }

            return pathname.includes(item);
          });

    // <<------------------------------------------>>
    if (pathname === PATH_ADMIN_HOME) return; // not run anything
    console.log('session', session);
    console.log('pathname', pathname);

    // handle normal routes
    if (isNormalRoute) {
      console.log('normal');
      if (!session) {
        // navigate(PATH_HOME);
      }
    }
  }, [pathname, session, navigate]);

  return useRoutes(routes);
}

export default memo(Router);
