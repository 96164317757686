/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Breadcrumb, Input, SeacrhTrxn } from '@/components';
import { openToast, selectUser, setLoading, useAppSelector } from '@/redux';
import { IItemTrxnHistory, IResponseTrxnHistory } from '@/interfaces';
import { adminApi } from '@/api';
import { ItemTrxnHistory } from './ItemTrxnHistory';

export const SearchTrxnHistory = () => {
  const dispatch = useDispatch();

  // redux states
  const user_tele_id_redux = useAppSelector(selectUser)?.user_tele_id;

  // local states
  const [listItemsTrxn, setListItemsTrxn] = useState<Array<IItemTrxnHistory>>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>();

  // local states for pagination
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(15);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  // refs
  const timeOutRef = useRef<NodeJS.Timeout>();

  // functions
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!user_tele_id_redux) throw new Error('No user tele id was provided!');

      const value = e?.target?.value;

      // reset page & total page
      setPage(1);
      setLimit(15);
      setTotalPage(0);
      setTotalItems(0);

      // debounce
      if (timeOutRef?.current) clearTimeout(timeOutRef.current);

      if (!value) {
        setListItemsTrxn([]);
        setSearchKeyword('');

        return;
      }

      timeOutRef.current = setTimeout(async () => {
        // console.log('value: ', value); // for debug
        setSearchKeyword(value);
        dispatch(setLoading(true));

        const res = await adminApi?.getListTrxnHistory(user_tele_id_redux, 1, 15, value);
        const response: IResponseTrxnHistory = res?.data?.data;

        const items = response?.items;

        // const newData = listItemsTrxn.concat(items);
        setListItemsTrxn(items);
        setTotalPage(response?.meta?.totalPages);
        setTotalItems(response?.meta?.totalItems);

        console.log('res get list order: ', res?.data);
        dispatch(setLoading(false));
      }, 500);
    } catch (error: any) {
      console.log(error);
      dispatch(
        openToast({
          message:
            error?.response?.data?.message ||
            error?.message ||
            'Something went wrong, please try again',
          type: 'error',
        })
      );
    } finally {
      // dispatch(setLoading(false));
    }
  };

  const handleLoadMoreData = async (
    user_tele_id: string | number,
    page: number,
    limit: number,
    searchKeyword: string
  ) => {
    try {
      dispatch(setLoading(true));

      const res = await adminApi?.getListTrxnHistory(user_tele_id, page, limit, searchKeyword);
      const response: IResponseTrxnHistory = res?.data?.data;

      const items = response?.items;

      const newData = listItemsTrxn.concat(items);
      setListItemsTrxn(newData);
      setTotalPage(response?.meta?.totalPages);

      console.log(`res get list order page ${page}: `, res?.data);
      dispatch(setLoading(false));
    } catch (error: any) {
      console.log(error);
      dispatch(
        openToast({
          message:
            error?.response?.data?.message ||
            error?.message ||
            'Something went wrong, please try again',
          type: 'error',
        })
      );
    } finally {
      // dispatch(setLoading(false));
    }
  };

  const hanleNext = () => {
    const newPage = page + 1;

    if (newPage <= totalPage) {
      setPage(newPage);
    }
  };

  // effects
  useEffect(() => {
    console.log('local states => listItemsTrxn: ', listItemsTrxn); // for debug
  }, [listItemsTrxn]);

  useEffect(() => {
    if (listItemsTrxn?.length && searchKeyword && page > 1 && user_tele_id_redux) {
      handleLoadMoreData(user_tele_id_redux, page, limit, searchKeyword);
    }
  }, [page, totalPage, searchKeyword, user_tele_id_redux]);

  // main return
  return (
    <StyledSearchTrxnHistory>
      <Breadcrumb no_admin_path />
      <div className="search_input_container">
        <Input label="Search by Keyword" sufIcon={<SeacrhTrxn />} onChange={handleSearchChange} />
      </div>

      {totalItems ? <p className="trnx_num">{totalItems} transactions found</p> : null}

      <InfiniteScroll
        dataLength={listItemsTrxn?.length}
        next={hanleNext}
        hasMore
        loader={undefined}
      >
        {listItemsTrxn?.length ? (
          listItemsTrxn?.map((item) => {
            return <ItemTrxnHistory item={item} key={`${item?.id}-${item?.my_trxn_ref}`} />;
          })
        ) : (
          <div className="no_result">
            <p className="no_result_title">No Results</p>
            <p className="no_result_desc">
              We couldn&rsquo;t find any results in the last 50 transactions
            </p>
          </div>
        )}
      </InfiniteScroll>
    </StyledSearchTrxnHistory>
  );
};

const StyledSearchTrxnHistory = styled.div`
  padding: 23px 16px 0 16px;
  margin-bottom: 72px;

  .search_input_container {
    width: 100%;
    margin-top: 17px;
  }

  .trnx_num {
    color: #171717;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  .no_result {
    width: 100%;
    margin-top: 67px;

    .no_result_title {
      text-align: center;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #8f8f8f;
      margin-bottom: 8px;
    }

    .no_result_desc {
      text-align: center;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #8f8f8f;
      margin-top: 0;
    }
  }
`;
