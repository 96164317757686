import React from 'react';

export function YourPayId() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#6369FF" />
      <path
        d="M17 16.625C17 16.83 16.83 17 16.625 17H7.375C7.17 17 7 16.83 7 16.625C7 16.42 7.17 16.25 7.375 16.25H16.625C16.83 16.25 17 16.42 17 16.625Z"
        fill="white"
      />
      <path
        d="M13.695 8.25988L8.32497 13.6299C8.11997 13.8349 7.78997 13.8349 7.58997 13.6299H7.58497C6.88997 12.9299 6.88997 11.7999 7.58497 11.1049L11.16 7.52988C11.86 6.82988 12.99 6.82988 13.69 7.52988C13.895 7.72488 13.895 8.05988 13.695 8.25988Z"
        fill="white"
      />
      <path
        d="M16.41 10.2449L14.885 8.71991C14.68 8.51491 14.35 8.51491 14.15 8.71991L8.77997 14.0899C8.57497 14.2899 8.57497 14.6199 8.77997 14.8249L10.305 16.3549C11.005 17.0499 12.135 17.0499 12.835 16.3549L16.405 12.7799C17.115 12.0799 17.115 10.9449 16.41 10.2449ZM12.38 14.7599L11.775 15.3699C11.65 15.4949 11.445 15.4949 11.315 15.3699C11.19 15.2449 11.19 15.0399 11.315 14.9099L11.925 14.2999C12.045 14.1799 12.255 14.1799 12.38 14.2999C12.505 14.4249 12.505 14.6399 12.38 14.7599ZM14.365 12.7749L13.145 13.9999C13.02 14.1199 12.815 14.1199 12.685 13.9999C12.56 13.8749 12.56 13.6699 12.685 13.5399L13.91 12.3149C14.03 12.1949 14.24 12.1949 14.365 12.3149C14.49 12.4449 14.49 12.6499 14.365 12.7749Z"
        fill="white"
      />
    </svg>
  );
}
