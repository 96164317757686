import React from 'react';
import styled from 'styled-components';

type IProps = {
  comment?: string;
};

export const CommentInfoOrder = (props: IProps) => {
  const { comment } = props;

  return (
    <StyledCommentInfoOrder>
      <p className="order_comment_title">Comment</p>
      <p className="order_comment_content">{comment}</p>
    </StyledCommentInfoOrder>
  );
};

const StyledCommentInfoOrder = styled.div`
  width: 100%;
  /* margin-bottom: 47px; */

  .order_comment_title {
    color: #8e8e93;
    font-weight: 600;
    font-size: 15px;
    margin: 0;
    margin-bottom: 8px;
  }

  .order_comment_content {
    color: #171717;
    font-weight: 500;
    font-size: 15px;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
  }
`;
