/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Modal } from '@material-ui/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { adminApi } from '@/api';
import { StatusOrderEnum, telegramClient } from '@/configs';
import { useBalance } from '@/hooks';
import { IPayInvoiceBody, IPayInvoiceResponse } from '@/interfaces';
import { formatCurrency } from '@/libs/helper';
import {
  openToast,
  selectReceiptStatus,
  selectUser,
  setCallbackAfterEnterPin,
  setLoading,
  setReceiptStatus,
  setRouteAfterEnterPin,
  useAppSelector,
} from '@/redux';
import { PATH_ENTER_PIN, PATH_PAY_INVOICE_ORDER } from '@/router';
import { styleModal, ChargeBackOrderInfo, CompanyDetailInfo } from './widget';
import { BasicInfoOrder, CommentInfoOrder, ProductInfoOrder } from '@/components';

export const PayInvoice = () => {
  const { trxnId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux states
  const user_tele_id_redux = useAppSelector(selectUser)?.user_tele_id;
  const receiptStatus = useAppSelector(selectReceiptStatus);

  const { balance } = useBalance(user_tele_id_redux);

  // local states
  const [orderDetailRes, setOrderDetailRes] = useState<IPayInvoiceResponse>();
  const [payConfirmModalVisible, setPayConfirmModalVisible] = useState<boolean>(false);
  const [notEnoughBalanceModalVisible, setNotEnoughBalanceModalVisible] = useState<boolean>(false);
  const [declineConfirmModalVisible, setDeclineConfirmModalVisible] = useState<boolean>(false);

  // functions
  const getOrderDetail = async (trxn_id: string) => {
    try {
      if (!trxn_id) throw new Error('No trxn ID was provided!');

      dispatch(setLoading(true));

      const res = await adminApi.getOrderDetailInvoice(trxn_id);
      const data: IPayInvoiceResponse = res?.data?.data;
      //   console.log('res get order detail: ', data); // for debug

      // set states
      setOrderDetailRes(data);
    } catch (error: any) {
      console.log(error);
      dispatch(
        openToast({
          message:
            error?.response?.data?.message ||
            error?.message ||
            'Something went wrong, please try again',
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const payInvoiceOrder = async (type: 'PAY' | 'DECLINE', order?: IPayInvoiceResponse) => {
    try {
      if (!order) throw new Error('No order object was provided!');

      dispatch(setLoading(true));
      dispatch(
        openToast({ message: 'Submiting payment...', type: 'info', autoHideDuration: null })
      );

      const body: IPayInvoiceBody = {
        receiver_trxn_ref: order?.my_trxn_ref,
        payer_trxn_ref: order?.their_trxn_ref,
        status: type,
        auth_date:
          telegramClient?.initDataUnsafe?.auth_date || dayjs(new Date()).valueOf().toString(),
      };

      //   console.log('body to pay: ', body); // for debug
      const res = await adminApi.payInvoiceOrder(body);
      //   console.log(res?.data?.data); // for debug

      dispatch(
        openToast({
          message: type === 'PAY' ? 'Payment successful' : 'Payment declined',
          type: 'success',
        })
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        openToast({
          message:
            error?.response?.data?.message ||
            error?.message ||
            'Something went wrong, please try again',
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
      dispatch(setReceiptStatus(!receiptStatus)); // refetch the order detail
    }
  };

  const handleAcceptPay = () => {
    // // redux: next route + callback, after that navigate to enter pin
    dispatch(setRouteAfterEnterPin(`/${PATH_PAY_INVOICE_ORDER}/${trxnId}`));
    dispatch(setCallbackAfterEnterPin(() => payInvoiceOrder('PAY', orderDetailRes)));
    navigate(`/${PATH_ENTER_PIN}`);
  };

  const handleDeclinePay = () => {
    // // redux: next route + callback, after that navigate to enter pin
    dispatch(setRouteAfterEnterPin(`/${PATH_PAY_INVOICE_ORDER}/${trxnId}`));
    dispatch(setCallbackAfterEnterPin(() => payInvoiceOrder('DECLINE', orderDetailRes)));
    navigate(`/${PATH_ENTER_PIN}`);
  };

  // effects
  useEffect(() => {
    if (trxnId) getOrderDetail(trxnId);
  }, [trxnId, receiptStatus]);

  useEffect(() => {
    console.log('local state => orderDetailRes: ', orderDetailRes); // for debug
  }, [orderDetailRes]);

  // main return
  return (
    <StyledOderSummary>
      <h3
        className={`title ${
          orderDetailRes?.status !== StatusOrderEnum?.IN_PROGRESS ? 'border_none pb_0' : ''
        }`}
      >
        {orderDetailRes?.status === StatusOrderEnum?.IN_PROGRESS ? 'Order Summary' : 'Tax Invoice'}
      </h3>

      <div
        className="balance_container"
        style={orderDetailRes?.status !== StatusOrderEnum?.IN_PROGRESS ? { display: 'none' } : {}}
      >
        <div className="title">Available Balance</div>
        <div className="balance">AUD: {formatCurrency(Number(balance) || 0)}</div>
      </div>

      {orderDetailRes?.userDetail?.registed_name ? (
        <CompanyDetailInfo userDetail={orderDetailRes?.userDetail} />
      ) : null}

      <BasicInfoOrder orderDetailRes={orderDetailRes} />

      {orderDetailRes?.order_details?.length ? (
        <ProductInfoOrder orderDetailRes={orderDetailRes} />
      ) : null}

      {orderDetailRes?.comment ? <CommentInfoOrder comment={orderDetailRes?.comment} /> : null}

      {orderDetailRes?.chargeBackOrder?.length ? (
        <ChargeBackOrderInfo
          chargeBackInfo={orderDetailRes?.chargeBackOrder}
          original_total={orderDetailRes?.total_price}
        />
      ) : null}

      <div
        className="btn"
        style={orderDetailRes?.status !== StatusOrderEnum?.IN_PROGRESS ? { display: 'none' } : {}}
      >
        <Button className="btn-decline" onClick={() => setDeclineConfirmModalVisible(true)}>
          Decline
        </Button>
        <Button
          className="btn-pay"
          onClick={() => {
            if (Number(orderDetailRes?.total_price) <= Number(balance)) {
              setPayConfirmModalVisible(true);
            } else {
              setNotEnoughBalanceModalVisible(true);
            }
          }}
        >
          Pay
        </Button>
      </div>

      {/* modal not enough balance */}
      <Modal open={notEnoughBalanceModalVisible} keepMounted={false}>
        <Box sx={styleModal}>
          <Box sx={styleModal.title}>Insufficient Funds</Box>
          <Box sx={styleModal.content}>You cannot pay more than your balance</Box>
          <Box sx={styleModal.btn}>
            <Button
              style={{ width: '100%', background: '#F4F4F5', borderRadius: 0 }}
              onClick={() => setNotEnoughBalanceModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ width: '100%', background: '#6369FF', borderRadius: 0, color: '#ffffff' }}
              onClick={() => setNotEnoughBalanceModalVisible(false)}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* modal pay confirm */}
      <Modal open={payConfirmModalVisible} keepMounted={false}>
        <Box sx={styleModal}>
          <Box sx={styleModal.title}>Pay Order</Box>
          <Box sx={styleModal.content}>
            Pay ${orderDetailRes?.total_price} to {orderDetailRes?.receiver_name}?
          </Box>
          <Box sx={styleModal.btn}>
            <Button
              style={{ width: '100%', background: '#F4F4F5', borderRadius: 0 }}
              onClick={() => setPayConfirmModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ width: '100%', background: '#6369FF', borderRadius: 0, color: '#ffffff' }}
              onClick={handleAcceptPay}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* modal decline confirm */}
      <Modal open={declineConfirmModalVisible} keepMounted={false}>
        <Box sx={styleModal}>
          <Box sx={styleModal.title}>Decline Order</Box>
          <Box sx={styleModal.content}>Decline this order?</Box>
          <Box sx={styleModal.btn}>
            <Button
              style={{ width: '100%', background: '#F4F4F5', borderRadius: 0 }}
              onClick={() => setDeclineConfirmModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ width: '100%', background: '#6369FF', borderRadius: 0, color: '#ffffff' }}
              onClick={handleDeclinePay}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </StyledOderSummary>
  );
};

const StyledOderSummary = styled.div`
  padding: 23px 16px 0 16px;
  margin-bottom: 72px;

  .m-0 {
    margin: 0 !important;
  }

  .title {
    color: #171717;
    font-weight: 600;
    font-size: 24px;
    border-bottom: 1px solid #e7e6f0;
    padding-bottom: 8px;
  }

  .balance_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 32px 0;
    border: 1px solid #d5d4db;

    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0a84ff;
      margin-bottom: 12px;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
    }
  }

  .m-0 {
    margin: 0 !important;
  }

  .btn {
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    background-color: #f6f6f6;
    box-shadow: 0px -0.33px 0px #d8d8d8;
    padding: 8px;

    display: grid;
    grid-row-gap: 24px;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);

    .btn-pay {
      background-color: #0a84ff;
      border-radius: 10px;
      color: #ffffff;
      width: 100%;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }

    .btn-decline {
      background-color: rgba(215, 0, 21, 0.15);
      border-radius: 10px;
      color: #d70015;
      width: 100%;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
`;
