import React from 'react';

export const IconCircleArrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.70016 14.4466C12.5602 13.6933 14.6668 11.0933 14.6668 7.99992C14.6668 4.31992 11.7068 1.33325 8.00016 1.33325C3.5535 1.33325 1.3335 5.03992 1.3335 5.03992M1.3335 5.03992V1.99992M1.3335 5.03992H2.6735H4.2935"
        stroke="#0A84FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.3335 8C1.3335 11.68 4.32016 14.6667 8.00016 14.6667"
        stroke="#0A84FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
    </svg>
  );
};
