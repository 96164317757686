import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';
// import { encryptblob, _arrayBufferToBase64 } from '@utils';
import { encryptblob } from '@utils';

import {
  Breadcrumb,
  IconCamera,
  IconNoImage,
  IconTrash,
  InputFile,
  InputSelect,
  MainButton,
} from '@components';
import { chat_bot_username } from '@configs';
import { IIDDocs, IOption } from '@interfaces';
import { openToast, selectTelegramClient, selectUser, setLoading, useAppSelector } from '@redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Api } from 'telegram';
import { PATH_ADMIN_HOME } from '@/router';
import { adminApi } from '@/api';

export function IDDocsPage() {
  const session = useAppSelector(selectUser)?.session;
  const userId = useAppSelector(selectUser)?.user_tele_id;
  const [messageId, setMessageId] = useState<number>(-1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux states
  const client = useAppSelector(selectTelegramClient);

  const IdDocSchema = Yup.object().shape({
    document_type: Yup.string().trim().required('Required'),
    category: Yup.string().trim().required('Required'),
    // attach_file: Yup.mixed().required('This File field is required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm<IIDDocs>({
    mode: 'onChange',
    resolver: yupResolver(IdDocSchema),
  });

  const submitIDDocs = async (formData: IIDDocs) => {
    try {
      dispatch(setLoading(true));
      if (!session || !userId || !client) {
        console.log('no session | no user id | client');

        return;
      }
      console.log(formData);

      // init message ID
      let mesId: number = messageId;
      // end of init message ID

      // Only run this block when the image has been changed
      if (typeof formData?.attach_file !== 'string') {
        // send file new (send file to bot by its username)
        const newBlob = new Blob([formData.attach_file]);

        const newBlobEncrypted = await encryptblob(newBlob);
        // @ts-ignore
        console.log('newBlobEncrypted: ', newBlobEncrypted); // for debug
        // @ts-ignore
        const fileEnc = new File([newBlobEncrypted[0]], 'EncyptedFile');
        console.log('file obj after encrypt: ', fileEnc);

        // add ivdata and exported key to formData
        // eslint-disable-next-line prefer-destructuring
        formData.ivdata = newBlobEncrypted[1].toString();
        // eslint-disable-next-line prefer-destructuring
        formData.exportedkey = JSON.stringify(newBlobEncrypted[2]);

        // // test decrypt
        // const newBlobDecrypted = await decryptblob(
        //   newBlobEncrypted[0],
        //   newBlobEncrypted[1],
        //   newBlobEncrypted[2]
        // );

        // console.log('newBlobDecrypted: ', newBlobDecrypted); // for debug
        // const fileDec = new File([newBlobDecrypted], 'EncyptedFile.png');
        // console.log('file obj after encrypt: ', fileDec);
        // // end of test decrypt

        const resSendNew = await client.sendFile(chat_bot_username, {
          file: await client.uploadFile({
            file: fileEnc,
            workers: 1,
          }),
          caption: `Please do not delete ${formData.document_type} until we tell you, otherwise you'll need to upload it again`,
        });
        console.log('resSendNew: ', resSendNew); // for debug
        // end of send file new (send file to bot by its username)

        // get lateast message
        const result_history = await client.invoke(
          new Api.messages.GetHistory({
            peer: chat_bot_username,
            // offsetId: 43,
            // offsetDate: 43,
            // addOffset: 0,
            limit: 10,
            // maxId: 0,
            // minId: 0,
            // hash: BigInt('-4156887774564'),
          })
        );
        console.log('result_history: ', result_history); // prints the result
        // reassign message id
        // @ts-ignore
        mesId = result_history?.messages?.[0]?.id;
        // get lateast message
      }

      // save message id to server
      // remove file attachment from fromData before submiting (avoid overload payload)
      delete formData.attach_file;
      // if user does not change image, use the initial one get from api. Otherwise, reassign it in the above code block
      await adminApi.updateIdDoc(parseInt(userId.toString()), mesId, formData);
      // end of save message id to server

      navigate(`/${PATH_ADMIN_HOME}`);
      dispatch(
        openToast({ message: 'You have successfully saved the ID document.', type: 'success' })
      );
    } catch (error) {
      console.log('error when submit: ', error);
      dispatch(
        openToast({
          message: "We're sorry, an error has occurred. Please try again.",
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangeCategory = (selectOptions: any) => {
    setValue('category', selectOptions.value, { shouldValidate: true });
    setValue('document_type', '');
  };

  const handleChangeDocument = (selectOptions: any) => {
    setValue('document_type', selectOptions.value, { shouldValidate: true });
  };

  const handleChangeAttachFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files) {
        console.log('file init: ', event.target.files[0]); // for debug
        setValue('attach_file', event.target.files[0]);

        // test encrypt and decrypt
        // const newBlob = new Blob([event.target.files[0]]);

        // const newBlobEncrypted = await encryptblob(newBlob);
        // console.log('newBlobEncrypted: ', newBlobEncrypted); // for debug

        // const newBlobDecrypted = await decryptblob(
        //   newBlobEncrypted[0],
        //   newBlobEncrypted[1],
        //   newBlobEncrypted[2]
        // );

        // console.log('newBlobDecrypted: ', newBlobDecrypted); // for debug

        // const file = new File([newBlobEncrypted], 'image.png');
        // console.log('file: ', file);
      }
    } catch (error) {
      console.log(error); // for debug
    }
  };

  const handleRemoveImg = () => {
    setValue('attach_file', undefined);
  };

  // const getImageFromTelegram = async (mesId: number) => {
  //   try {
  //     dispatch(setLoading(true));
  //     if (!session || !mesId) return;

  //     // init and connect telegram client
  //     const newSession = new StringSession(session);
  //     const client = new TelegramClient(newSession, apiId, apiHash, {
  //       connectionRetries: 5,
  //     });
  //     console.log('client cp: ', client); // for debug
  //     await client.connect(); // This assumes you have already authenticated with .start()
  //     // end of init and connect telegram client

  //     // get message
  //     const messages = await client.getMessages(undefined, { ids: mesId });
  //     console.log('messages: ', messages); // for debug

  //     if (!messages[0]) throw new Error('Message does not exist!'); // throw message when mesage not found
  //     // end of get message

  //     // read the media of message
  //     const buffer = await client.downloadMedia(messages[0], {});
  //     console.log('buffer: ', buffer); // for debug
  //     if (buffer) {
  //       // convert to blob before decrypt
  //       const blobEnc = new Blob([buffer]);
  //       console.log('blobEnc get from tele: ', blobEnc); // for debug

  //       // decrypt the above blob
  //       const newBlobDecrypted = await decryptblob(
  //         blobEnc,
  //         watch('ivdata'),
  //         JSON.parse(watch('exportedkey'))
  //       );

  //       console.log('newBlobDecrypted: ', newBlobDecrypted); // for debug

  //       // const b64 = buffer.toString('base64');
  //       const arrBuffer = await newBlobDecrypted.arrayBuffer();
  //       const base64String = _arrayBufferToBase64(arrBuffer);
  //       console.log('b64: ', base64String);
  //       setValue('attach_file', base64String); // set init form value
  //     }
  //     // end of read the media of message
  //   } catch (error) {
  //     console.log('error while getting message: ', error); // for debug
  //     // If anything go wrong, delete the Doc ID and set all states to initial values
  //     await adminApi.deleteIdDoc(userId, mesId);
  //     setMessageId(-1);
  //     setValue('category', '');
  //     setValue('document_type', '');
  //     setValue('attach_file', '');
  //     setValue('exportedkey', '');
  //     setValue('ivdata', '');
  //   } finally {
  //     dispatch(setLoading(false));
  //   }
  // };

  // const getDocId = async (userId: string | number) => {
  //   try {
  //     dispatch(setLoading(true));
  //     if (!userId) return;
  //     const res = await adminApi.getPersonalDetail(userId);
  //     // @ts-ignore
  //     const dataIdDocs = res?.data?.data?.user?.idDocs?.[0];

  //     console.log('res get id doc: ', res?.data?.data?.user?.idDocs); // for debug
  //     reset({ ...dataIdDocs });
  //     setMessageId(parseInt(dataIdDocs?.message_id));
  //   } catch (error) {
  //     console.log(error); // for debug
  //   } finally {
  //     dispatch(setLoading(false));
  //   }
  // };

  // options
  const CATEGORY_DATA: IOption[] = [
    { value: '1', label: 'Category 1' },
    { value: '2', label: 'Category 2' },
  ];

  const DOC_DATA_CATE_1 = [
    { value: 'Driver’s License', label: 'Driver’s License' },
    { value: 'Passport Bio Page', label: 'Passport Bio Page' },
    { value: 'Proof of Age card', label: 'Proof of Age card' },
    { value: 'National ID Card', label: 'National ID Card' },
    { value: 'Student ID Card', label: 'Student ID Card' },
  ];

  const DOC_DATA_CATE_2 = [
    { value: 'Birth Certificate', label: 'Birth Certificate' },
    { value: 'Citizenship Certificate', label: 'Citizenship Certificate' },
    { value: 'Rates Notice', label: 'Rates Notice' },
    { value: 'Utility Bill', label: 'Utility Bill' },
    { value: 'Letter From Gov Agenc', label: 'Letter From Gov Agenc' },
  ];

  const [docData, setDocData] = useState<Array<IOption>>([]);

  // effetcs
  useEffect(() => {
    if (watch('category') === '1') setDocData(DOC_DATA_CATE_1);
    else if (watch('category') === '2') setDocData(DOC_DATA_CATE_2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('category')]);

  useEffect(() => {
    if (typeof watch('attach_file') === 'string') return; // only run when image changes (ignore first time)
    try {
      const output = document.getElementById('output');
      if (output) {
        // @ts-ignore
        output.src = URL.createObjectURL(watch('attach_file'));
      }
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('attach_file')]);

  // useEffect(() => {
  //   getDocId(userId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (messageId === -1) return;
  //   getImageFromTelegram(messageId); // hard code for now
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [messageId]);

  // main return
  return (
    <StyledIDDocs>
      <Container>
        <Breadcrumb />
        <div className="page_title">Upload ID Docs</div>
        <form onSubmit={handleSubmit(submitIDDocs)}>
          <InputSelect
            className="mb-16"
            register={register('category')}
            options={CATEGORY_DATA}
            name="category"
            placeholder="Select Category"
            error={errors.category}
            onChange={handleChangeCategory}
            value={CATEGORY_DATA.find((category) => category.value === watch('category'))}
          />
          <InputSelect
            className="mb-36"
            register={register('document_type')}
            options={docData}
            name="document_type"
            placeholder="Select Document to Upload"
            error={errors.document_type}
            onChange={handleChangeDocument}
            value={docData.find((document) => document.value === watch('document_type')) || null}
          />
          <div className="helper_note">
            Take a picture of your ID Doc on a neutral background then attach the file below
          </div>

          {watch('attach_file') ? (
            <div className="img-container">
              {/* {typeof watch('attach_file') === 'string' ? (
                <img src={`data:image/png;base64,${watch('attach_file')}`} alt="abc" />
              ) : (
                <img alt="abc" id="output" />
              )} */}

              <img
                src={
                  typeof watch('attach_file') === 'string'
                    ? `data:image/png;base64,${watch('attach_file')}`
                    : ''
                }
                alt="abc"
                id="output"
              />

              <button type="button" className="btn-del" onClick={handleRemoveImg}>
                <IconTrash />
              </button>
            </div>
          ) : (
            <>
              <div className="row">
                <InputFile onChange={handleChangeAttachFile} accept="image/png, image/jpeg">
                  <div className="zone">
                    <div className="icon_wrapper">
                      <IconNoImage />
                    </div>
                    <div className="text">Attach file (image)</div>
                  </div>
                </InputFile>
                {/* <div className="separator">or</div>
                <div
                  className="zone"
                  // onClick={() => {
                  //   getMedia({
                  //     audio: true,
                  //     video: {
                  //       width: { min: 1024, ideal: 1280, max: 1920 },
                  //       height: { min: 576, ideal: 720, max: 1080 },
                  //       facingMode: { exact: 'environment' },
                  //       frameRate: { ideal: 10, max: 15 },
                  //     },
                  //   });
                  // }}
                >
                  <div className="icon_wrapper">
                    <IconCamera />
                  </div>
                  <div className="text">Take a Photo</div>
                </div> */}
              </div>

              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              {/* <video width="240" height="320" controls>
                <track src="fgsubtitles_en.vtt" kind="subtitles" srcLang="en" label="English" />
              </video> */}
            </>
          )}

          <MainButton text="Submit" type="submit" disabled={!isValid || !watch('attach_file')} />
        </form>
      </Container>
    </StyledIDDocs>
  );
}

const StyledIDDocs = styled.div`
  .MuiContainer-root {
    margin-bottom: 75px;
  }
  .page_title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }
  .mb-36 {
    margin-bottom: 36px;
  }
  .helper_note {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    opacity: 0.9;
    margin-bottom: 24px;
  }
  .zone {
    height: 97px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px dashed #8e8e93;
    cursor: pointer;
    width: 100%;
    & .text {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #8f8f8f;
    }
  }
  .row {
    display: flex;
    align-items: center;
    & .separator {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      opacity: 0.7;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  .w-full {
    width: 100%;
  }
  .h-full {
    height: 100%;
  }
  .img-container {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    align-items: center;
    img {
      max-width: 80%;
      height: auto;
      border-radius: 10px;
    }
    .btn-del {
      background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      width: 46px;
      height: 46px;
      cursor: pointer;
    }
  }
`;
