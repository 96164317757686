import { ComponentProps, useEffect, useId } from 'react';

import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

type FormProps<TFormValues extends FieldValues> = {
  className?: string;
  id?: string;

  form: UseFormReturn<TFormValues>;
  onSubmit?: SubmitHandler<TFormValues>;
} & Omit<ComponentProps<'form'>, 'onSubmit'>;

export function Form<TFormValues extends FieldValues>({
  form,
  onSubmit,
  children,
  className,
  id,
  ...props
}: FormProps<TFormValues>) {
  const uniqueId = useId();

  const { clearErrors, reset, handleSubmit } = form;

  useEffect(
    () => () => {
      // reset on unmount
      clearErrors();
      reset();
    },
    [clearErrors, reset]
  );

  return (
    <FormProvider {...form}>
      <form
        id={id ?? uniqueId}
        onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}
        {...props}
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  );
}
