/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { Api, TelegramClient } from 'telegram';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import { adminApi } from '@/api';

import { BreadcrumbStep, IconAdmin, IconQRPay, Input, MainButton } from '@/components';
import {
  openToast,
  selectStep,
  selectTelegramClient,
  selectUser,
  setLoading,
  setStep,
  useAppDispatch,
  useAppSelector,
} from '@/redux';
import { _arrayBufferToBase64 } from '@/utils';
import { IResponseSearchUser, IUserSearchItem } from '@/interfaces';

type IProps = {
  handleNextStepContact(): void;
  isValid: boolean;
  isActive: boolean;
  feature: 'RP' | 'PA'; // request payment OR pay anyone
  // setValue: UseFormSetValue<IRequestPayment> | UseFormSetValue<IPayAnyOne>;
  // setValueRP?: UseFormSetValue<IRequestPayment>;
  // setValuePA?: UseFormSetValue<IPayAnyOne>;
  setValue: UseFormSetValue<any>;
  selectedContactId?: string;
  contact_name?: string;
};

export const StepTwo = (props: IProps) => {
  const {
    handleNextStepContact,
    isValid,
    isActive,
    setValue,
    feature,
    selectedContactId,
    contact_name,
  } = props;

  const dispatch = useAppDispatch();

  // local states
  // const [teleClient, setTeleClient] = useState<TelegramClient>();
  // const [searchUserValues, setSearchUserValues] = useState<Api.contacts.Found['users']>();
  // const [b64PicArr, setB64PicArr] = useState<string[]>([]);

  const [searchUserValues, setSearchUserValues] = useState<IUserSearchItem[]>();
  const [selectedContact, setSelectedContact] = useState<IUserSearchItem | Api.TypeUser>();
  const [seacrhLoading, setSearchLoading] = useState<boolean>(false);

  // refs
  const timer = useRef<NodeJS.Timeout>();

  // redux states
  // const session = useAppSelector(selectUser)?.session;
  const step = useAppSelector(selectStep);
  const reduxTeleClient = useAppSelector(selectTelegramClient);
  const user_tele_id_redux = useAppSelector(selectUser)?.user_tele_id;

  // functions
  // const initTeleClient = useCallback(async () => {
  //   try {
  //     dispatch(setLoading(true));
  //     // init and connect telegram client
  //     const newSession = new StringSession(session);
  //     const client = new TelegramClient(newSession, apiId, apiHash, {
  //       connectionRetries: 5,
  //       autoReconnect: true,
  //     });
  //     console.log('client cp: ', client); // for debug
  //     await client.connect(); // This assumes you have already authenticated with .start()
  //     setTeleClient(client);
  //     // end of init and connect telegram client
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     dispatch(setLoading(false));
  //   }
  // }, []);

  const handleSearchChange = async (value: string) => {
    setValue('contact_name', value);
  };

  const handleSearch = async (value?: string, client?: TelegramClient, timeout?: number) => {
    if (timer?.current) clearTimeout(timer?.current);
    timer.current = setTimeout(async () => {
      try {
        console.log('value: ', value); // for debug

        // handle empty search value
        if (!value) {
          setSearchUserValues([]);
          setValue('selected_contact_id', undefined);

          return;
        }

        // use client to search
        if (!client) {
          throw new Error('no tele client');
        }

        setSearchLoading(true);

        // new search api
        const res = await adminApi.searchUser(value, 1, 100);
        const data: IResponseSearchUser = res?.data?.data;

        console.log('data search: ', data); // for debug

        // set states
        setSearchUserValues(
          data?.items?.filter((item) => item?.user_tele_id !== user_tele_id_redux)
        );

        // const result = await client.invoke(
        //   new Api.contacts.Search({
        //     q: value,
        //     limit: 100,
        //   })
        // );
        // console.log('res search: ', result); // prints the result
        // // @ts-ignore
        // const users = result.users?.filter((user) => user?.self !== true); // exclude current account
        // setSearchUserValues(users);

        // // handle profile pics
        // const profilePicsPromise: any = [];
        // for (let index = 0; index < users.length; index++) {
        //   const user = users[index];
        //   const bufferPromise = client.downloadProfilePhoto(user, {
        //     isBig: false,
        //     //   outputFile: 'Buffer',
        //   });
        //   profilePicsPromise.push(bufferPromise);
        // }
        // const res_profile_pics = await Promise.all(profilePicsPromise);
        // console.log('res_profile_pics [buffer]: ', res_profile_pics); // for debug

        // const b64Arr: string[] = [];
        // res_profile_pics.forEach((buffer) => {
        //   b64Arr.push(_arrayBufferToBase64(buffer));
        // });
        // console.log('b64Arr: ', b64Arr); // for debug
        // setB64PicArr(b64Arr);
      } catch (error) {
        console.log('error while searching...', error);
        setSearchUserValues([]);

        dispatch(
          openToast({
            message: 'Something went wrong, please try login again',
            type: 'error',
          })
        );
      } finally {
        setSearchLoading(false);
      }
    }, timeout || 500);
  };

  const handleSelectContact = (user: IUserSearchItem) => {
    try {
      console.log('selected contact id: ', user?.user_tele_id);
      setValue('selected_contact_id', Number(user?.user_tele_id));
      setSelectedContact(user);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmContact = async (user_tele_id?: string) => {
    try {
      if (!user_tele_id) {
        console.log('no user tele id was provided');
        dispatch(
          openToast({
            message: 'Please select a contact',
            type: 'error',
            autoHideDuration: 3500,
          })
        );

        return;
      }

      dispatch(setLoading(true));

      const res = await adminApi.checkUserPayId(user_tele_id);

      // handle no user in db
      if (res?.data?.data === false) {
        dispatch(
          openToast({
            message: 'Invalid user ID. The ID you entered does not match any existing user.',
            type: 'error',
            autoHideDuration: 3500,
          })
        );

        return;
      }
      // end of handle no user in db

      // find the matched contact in list search
      const matchedContact = searchUserValues?.find(
        (contact) => Number(contact?.user_tele_id) === Number(user_tele_id)
      );
      // set form value based on feature (request payment | pay anyone)
      feature === 'RP'
        ? setValue('payer_user_id', user_tele_id) // set form value for payer_user_id
        : setValue('receiver_user_id', user_tele_id); // set form value for receiver_user_id

      setValue(
        'contact_name',
        // // @ts-ignore
        // matchedContact?.username ||
        //   // @ts-ignore
        //   `${matchedContact?.firstName ? matchedContact?.firstName : ''} ${
        //     // @ts-ignore
        //     matchedContact?.lastName ? matchedContact?.lastName : ''
        //   }`?.trim()
        matchedContact?.username || matchedContact?.full_name
      ); // set form value for contact_name
      dispatch(setStep(2)); // go to final step
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          message: 'Something went wrong. Please check your connection and try again',
          type: 'error',
          autoHideDuration: 3500,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  // effects
  // useEffect(() => {
  //   if (step === 1) {
  //     initTeleClient();
  //   }
  // }, [step]);

  useEffect(() => {
    // auto select first contact when search change (set to undefined when search is empty)
    if (searchUserValues?.length) {
      setValue('selected_contact_id', Number(searchUserValues[0]?.user_tele_id));
      setSelectedContact(searchUserValues[0]);
    } else {
      setValue('selected_contact_id', undefined);
    }
  }, [searchUserValues]);

  useEffect(() => {
    console.log('user select change: ', selectedContactId); // for debug
  }, [selectedContactId]);

  useEffect(() => {
    console.log('contact_name change: ', contact_name); // for debug
    if (step === 1) {
      console.log('search...');
      handleSearch(contact_name, reduxTeleClient, 500);
    }
  }, [contact_name, step]);

  // useEffect(() => {
  //   setValue('contact_name', 'long');
  // }, []);

  // useEffect(() => {
  //   if (step === 1 && teleClient) {
  //     console.log('teleClient init step 2: ', teleClient); // for debug
  //   }
  // }, [teleClient]);

  // useEffect(() => {
  //   if (step === 1) {
  //     console.log('searchUserValues: ', searchUserValues); // for debug
  //   }
  // }, [searchUserValues]);

  // useEffect(() => {
  //   if (step === 1) {
  //     console.log('b64PicArr: ', b64PicArr); // for debug
  //   }
  // }, [b64PicArr]);

  // main return
  return (
    <div className={isActive ? 'active' : 'inactive'}>
      <BreadcrumbStep />
      <div className="sub_title">Add {feature === 'RP' ? 'Payer' : 'Receiver'}</div>

      <div className="options_btn">
        <div className="options_btn_qr" onClick={() => dispatch(setStep(2.1))}>
          <IconQRPay />
          <p>Scan QR Code</p>
        </div>
        {/* <div className="options_btn_user" onClick={handleNextStepContact}>
          <TelegramIcon />
          <p>Telegram Contacts</p>
        </div> */}
      </div>

      <div className="divide">OR</div>

      <div className="sub_desc">Choose a Telegram user</div>

      <Input
        label="Telegram User"
        className="mt-40"
        onChange={(e) => {
          handleSearchChange(e?.target?.value);
        }}
        value={contact_name}
      />

      {searchUserValues?.length ? (
        <div className="contacts">
          {seacrhLoading ? (
            <div className="contacts_loading">
              <CircularProgress />
            </div>
          ) : (
            searchUserValues?.map((user, index) => {
              return (
                <div
                  className="contacts_item"
                  key={Number(user?.id)}
                  onClick={() => {
                    handleSelectContact(user);
                  }}
                >
                  <div className="contacts_item_img">
                    {/* {b64PicArr[index] ? (
                      <img src={`data:image/png;base64,${b64PicArr[index]}`} alt="avatar" />
                    ) : (
                      <IconAdmin width="40" height="40" />
                    )} */}
                    <IconAdmin width="40" height="40" />
                  </div>
                  <div className="contacts_item_name">
                    {
                      // // @ts-ignore
                      // `${user?.firstName ? user?.firstName : ''} ${
                      //   // @ts-ignore
                      //   user?.lastName ? user?.lastName : ''
                      // }`
                      user?.username ? user?.username : user?.full_name
                    }
                    <span>{` @${user?.account_type}`}</span>
                    <Radio
                      checked={user?.id === selectedContact?.id}
                      value={user?.id}
                      name="contact-radio-buttons"
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>
      ) : (
        <div className="contacts">
          <div className="sub_desc op_0_5">No results</div>
        </div>
      )}

      <MainButton
        text="Add"
        type="button"
        // disabled={!selectedContactId}
        onClick={() => {
          handleConfirmContact(selectedContactId);
        }}
      />
    </div>
  );
};
