import { IOption } from '@interfaces';
import classnames from 'classnames';
import { useRef } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import Select, { Props as SelectProps } from 'react-select';
import styled from 'styled-components';

type IInputSelectProps = {
  register?: Partial<UseFormRegisterReturn>;
  name?: string;
  error?: FieldError;
  placeholder?: string;
  label?: string;
  required?: boolean;
  helperText?: string;
  className?: string;
  options?: Array<IOption>;
  value?: IOption | null;
  onChange?(selectedOption: any): void;
  disabled?: boolean;
  components?: SelectProps['components'];
  isOptionDisabled?: SelectProps['isOptionDisabled'];
  formatOptionLabel?: SelectProps['formatOptionLabel'];
};

export function InputSelect(props: IInputSelectProps) {
  const {
    register,
    name,
    error,
    placeholder,
    label,
    required,
    helperText,
    className = '',
    options,
    value,
    onChange,
    disabled,
    components,
    isOptionDisabled,
    formatOptionLabel,
  } = props;

  // refs
  const selectRef = useRef<HTMLDivElement | null>(null);

  const customStyles: SelectProps['styles'] = {
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: '#F3F2F8',
      borderRadius: '8px',
      outline: 'none',
      height: '48px',
      border: 'none',
      boxShadow: 'none',
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: 'Plus Jakarta Sans',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18px',
      opacity: '0.8',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0',
      paddingLeft: '12px',
      height: '48px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: 'Plus Jakarta Sans',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18px',
      opacity: '0.9',
      color: '#030303',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: 'Plus Jakarta Sans',
      fontStyle: 'normal',
      fontWeight: '5400',
      fontSize: '14px',
      lineHeight: '18px',
      opacity: '0.5',
      color: '#171717',
    }),
    menu: (provided) => ({ ...provided, zIndex: '1000' }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided, { isDisabled }) => ({
      ...provided,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }),
  };

  // main return
  return (
    <StyledSelect className={`input-form ${className}`} ref={selectRef}>
      {label && (
        <label
          htmlFor={name}
          className={classnames({
            'form-label w-full flex sm:flex-row': true,
            "after:content-['*'] after:ml-0.5": required,
          })}
        >
          {label}
        </label>
      )}
      <Select
        {...register}
        styles={customStyles}
        value={value}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        isSearchable={false}
        isDisabled={disabled}
        components={components}
        isOptionDisabled={isOptionDisabled}
        formatOptionLabel={formatOptionLabel}
        onMenuOpen={() => {
          if (selectRef?.current) {
            console.log('select scroll...');
            setTimeout(() => {
              selectRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
              });
            }, 500);
          }
        }}
      />
      {!error && helperText && <div className="helper mt-2">{helperText}</div>}
      {error && <div className="err_message mt-2">{error?.message}</div>}
    </StyledSelect>
  );
}

const StyledSelect = styled.div`
  label {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;
    color: #60626b;
  }
  input {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1e2029;
    border: 1px solid #acacac;
    border-radius: 8px;
    width: 100%;
    &::placeholder {
      color: #acacac;
    }
    &:focus {
      box-shadow: none;
      border-color: #1e2029;
    }
    &.border-danger {
      border-color: #e52525;
    }
  }
  .helper {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #60626b;
    margin-top: 8px;
  }
  .err_message {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #e62525;
    margin-top: 8px;
  }
`;
