import { Box } from '@mui/material';
import { memo } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { IInputProps, Input } from './Input';

function ControlInput(props: UseControllerProps<any, any> & { inputProps?: IInputProps }) {
  const { inputProps, ...controllerProps } = props;
  const { field } = useController(controllerProps);

  return (
    <Box>
      <Input {...inputProps} {...field} />
    </Box>
  );
}

export default memo(ControlInput);
