/* eslint-disable import/order */
import { useBalance } from '@/hooks';
import { formatCurrency } from '@/libs/helper';
import {
  IconAdmin,
  IconClock,
  IconCoin,
  IconHelp,
  IconMessage,
  IconQRCode,
  IconTransfer,
  IconVoice,
  MenuButton,
} from '@components';
import {
  selectIsVerify,
  selectTelegramClient,
  selectUser,
  setInCompleteSession,
  setLoading,
  setRouteAfterEnterPin,
  setStep,
  useAppSelector,
} from '@redux';
import {
  PATH_ADMIN_HOME,
  PATH_BALANCE,
  PATH_CREATE_PASSWORD,
  PATH_ENTER_PIN,
  PATH_HELP,
  PATH_PAY_ANYONE,
  PATH_QR_CODE,
  PATH_REQUEST_PAYMENT,
  PATH_SONIC_PAY,
  PATH_TRXN_HISTORY,
  PATH_VERIFY_CODE,
} from '@router';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Api } from 'telegram';

export function StartPage() {
  const isVerify = useAppSelector(selectIsVerify);

  const adminActions = [
    {
      text: 'Tippe Sonic',
      id: 1,
      path: PATH_SONIC_PAY,
      icon: <IconVoice />,
      disabled: !isVerify,
    },
    {
      text: 'QR Code',
      id: 2,
      path: PATH_QR_CODE,
      icon: <IconQRCode />,
      disabled: !isVerify,
    },
    {
      text: 'Balance',
      id: 3,
      path: PATH_BALANCE,
      icon: <IconCoin />,
      disabled: !isVerify,
    },
    {
      text: 'Transfer',
      id: 4,
      path: PATH_PAY_ANYONE,
      icon: <IconTransfer />,
      disabled: !isVerify,
    },
    {
      text: 'Admin',
      id: 5,
      path: PATH_ADMIN_HOME,
      icon: <IconAdmin />,
      disabled: false,
    },
    {
      text: 'Request Payment',
      id: 6,
      path: PATH_REQUEST_PAYMENT,
      icon: <IconMessage />,
      disabled: !isVerify,
    },
    {
      text: 'Trxn History',
      id: 7,
      path: PATH_TRXN_HISTORY,
      icon: <IconClock />,
      disabled: !isVerify,
    },
    {
      text: 'Help',
      id: 8,
      path: PATH_HELP,
      icon: <IconHelp />,
      disabled: false,
    },
  ];

  // redux states
  const session = useAppSelector(selectUser)?.session;
  const client = useAppSelector(selectTelegramClient);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const protectRoute = [PATH_ADMIN_HOME, PATH_QR_CODE, PATH_SONIC_PAY];

  const handleClickMenuButton = async (nextPath: string) => {
    if (!nextPath) return;

    // handle public screens
    if (!protectRoute.includes(nextPath)) {
      navigate(nextPath);

      return;
    }

    if (session && client) {
      dispatch(setLoading(true));

      console.log('still have session in cache (handleClickAdmin)'); // for debug
      try {
        // check password using client
        console.log('client', client);
        const result = await client.invoke(new Api.account.GetPassword());
        console.log('result', result); // for debug purposes

        if (result.hasPassword) {
          // set the next route after complete enter 2fa password
          dispatch(setRouteAfterEnterPin(`/${nextPath}`));
          navigate(`/${PATH_ENTER_PIN}`);
        } else {
          dispatch(setInCompleteSession(session)); // used for create password (only use incomplete session)
          dispatch(setStep(0)); // set step = 0 to start the first screen
          navigate(`/${PATH_CREATE_PASSWORD}`);
        }
      } catch (error: any) {
        console.log('something wrong with session (or telegram): ', error); // for debug
        dispatch(setRouteAfterEnterPin(`/${PATH_ADMIN_HOME}`));
        // if anything went wrong, go to enter 2fa code screen (eg: session expired)
        navigate(`/${PATH_ENTER_PIN}`);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      console.log('session in cache has been clear (handleClickAdmin)'); // for debug
      dispatch(setStep(0)); // set step = 0 to start the first screen
      navigate(`/${PATH_VERIFY_CODE}`);
    }
  };

  const userId = useAppSelector(selectUser)?.user_tele_id;

  const { balance } = useBalance(userId);

  // main return
  return (
    <StyledStartPage>
      <div className="balance_container">
        <div className="title">Available Balance</div>
        <div className="balance">AUD: {formatCurrency(Number(balance) || 0)}</div>
      </div>
      <div className="menu">
        {adminActions.map((action) => (
          <MenuButton
            key={action.id}
            text={action.text}
            path={action.path}
            icon={action.icon}
            onClick={() => handleClickMenuButton(action.path)}
            disabled={action.disabled}
          />
        ))}
      </div>
    </StyledStartPage>
  );
}

const StyledStartPage = styled.div`
  padding: 23px 16px 0 16px;
  .balance_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 12px;
    margin-bottom: 40px;
    padding: 32px 0;
    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0a84ff;
      margin-bottom: 12px;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
    }
  }
  .menu {
    display: grid;
    grid-row-gap: 24px;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
`;
