import { useState, useEffect } from 'react';
import { telegramClient } from '@/configs';

function getViewPort() {
  const height = telegramClient?.WebApp?.viewportHeight;

  return {
    height,
  };
}

export function useViewPort() {
  const [viewPort, setViewPort] = useState<{ height: number }>(getViewPort());

  useEffect(() => {
    function handleResize() {
      setViewPort(getViewPort());
    }

    telegramClient?.WebApp?.onEvent('viewportChanged', handleResize);

    return () => telegramClient?.WebApp?.offEvent('viewportChanged', handleResize);
  }, []);

  return viewPort;
}
