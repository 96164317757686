import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  titleStepZero: {
    position: 'absolute',
    textAlign: 'center',
    bottom: '0',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
  },
  title: {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
    marginTop: '35px',
    marginBottom: '36px',
  },
  subtitle: {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '160%',
    opacity: '0.7',
    textAlign: 'center',
  },
  logoWrapper: {
    marginTop: '49px',
    marginBottom: '24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  mb_24: {
    marginBottom: '24px',
  },
  step: {
    height: '0',
    visibility: 'hidden',
    '&.active': {
      visibility: 'visible',
      height: 'auto',
    },
  },

  subAction: {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#0A84FF',
    marginTop: '20px',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
}));
