import React from 'react';

type IProps = {
  width?: string;
  height?: string;
};

export function IconAdmin(props: IProps) {
  const { width, height } = props;

  return (
    <svg
      width={width || 36}
      height={height || 36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#3C71FC" />
      <path
        d="M17.9998 9.6667C15.8165 9.6667 14.0415 11.4417 14.0415 13.625C14.0415 15.7667 15.7165 17.5 17.8998 17.575C17.9665 17.5667 18.0332 17.5667 18.0832 17.575C18.0998 17.575 18.1082 17.575 18.1248 17.575C18.1332 17.575 18.1332 17.575 18.1415 17.575C20.2748 17.5 21.9498 15.7667 21.9582 13.625C21.9582 11.4417 20.1832 9.6667 17.9998 9.6667Z"
        fill="white"
      />
      <path
        d="M22.2331 19.7917C19.9081 18.2417 16.1164 18.2417 13.7747 19.7917C12.7164 20.5 12.1331 21.4584 12.1331 22.4834C12.1331 23.5084 12.7164 24.4584 13.7664 25.1584C14.9331 25.9417 16.4664 26.3334 17.9997 26.3334C19.5331 26.3334 21.0664 25.9417 22.2331 25.1584C23.2831 24.45 23.8664 23.5 23.8664 22.4667C23.8581 21.4417 23.2831 20.4917 22.2331 19.7917Z"
        fill="white"
      />
    </svg>
  );
}
