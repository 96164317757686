import React from 'react';

export function IconCoin() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#52D2FC" />
      <path
        d="M18.6248 21.2667H19.1664C19.7081 21.2667 20.1581 20.7834 20.1581 20.2C20.1581 19.475 19.8998 19.3334 19.4748 19.1834L18.6331 18.8917V21.2667H18.6248Z"
        fill="white"
      />
      <path
        d="M17.9746 9.58337C13.3746 9.60004 9.64965 13.3417 9.66632 17.9417C9.68298 22.5417 13.4246 26.2667 18.0246 26.25C22.6246 26.2334 26.3496 22.4917 26.333 17.8917C26.3163 13.2917 22.5746 9.57504 17.9746 9.58337ZM19.883 18C20.533 18.225 21.408 18.7084 21.408 20.2C21.408 21.4834 20.3996 22.5167 19.1663 22.5167H18.6246V23C18.6246 23.3417 18.3413 23.625 17.9996 23.625C17.658 23.625 17.3746 23.3417 17.3746 23V22.5167H17.0746C15.708 22.5167 14.5996 21.3667 14.5996 19.95C14.5996 19.6084 14.883 19.325 15.2246 19.325C15.5663 19.325 15.8496 19.6084 15.8496 19.95C15.8496 20.675 16.3996 21.2667 17.0746 21.2667H17.3746V18.45L16.1163 18C15.4663 17.775 14.5913 17.2917 14.5913 15.8C14.5913 14.5167 15.5996 13.4834 16.833 13.4834H17.3746V13C17.3746 12.6584 17.658 12.375 17.9996 12.375C18.3413 12.375 18.6246 12.6584 18.6246 13V13.4834H18.9246C20.2913 13.4834 21.3996 14.6334 21.3996 16.05C21.3996 16.3917 21.1163 16.675 20.7746 16.675C20.433 16.675 20.1496 16.3917 20.1496 16.05C20.1496 15.325 19.5996 14.7334 18.9246 14.7334H18.6246V17.55L19.883 18Z"
        fill="white"
      />
      <path
        d="M15.8499 15.8084C15.8499 16.5334 16.1082 16.675 16.5332 16.825L17.3749 17.1167V14.7334H16.8332C16.2915 14.7334 15.8499 15.2167 15.8499 15.8084Z"
        fill="white"
      />
    </svg>
  );
}

export const IconCoinNoBackground = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.25">
        <path
          d="M12.75 15.9203H13.4C14.05 15.9203 14.59 15.3403 14.59 14.6403C14.59 13.7703 14.28 13.6003 13.77 13.4203L12.76 13.0703V15.9203H12.75Z"
          fill="white"
        />
        <path
          d="M12.75 15.9203H13.4C14.05 15.9203 14.59 15.3403 14.59 14.6403C14.59 13.7703 14.28 13.6003 13.77 13.4203L12.76 13.0703V15.9203H12.75Z"
          fill="#171717"
        />
        <path
          d="M11.9699 1.90041C6.44994 1.92041 1.97994 6.41041 1.99994 11.9304C2.01994 17.4504 6.50994 21.9204 12.0299 21.9004C17.5499 21.8804 22.0199 17.3904 21.9999 11.8704C21.9799 6.35041 17.4899 1.89041 11.9699 1.90041ZM14.2599 12.0004C15.0399 12.2704 16.0899 12.8504 16.0899 14.6404C16.0899 16.1804 14.8799 17.4204 13.3999 17.4204H12.7499V18.0004C12.7499 18.4104 12.4099 18.7504 11.9999 18.7504C11.5899 18.7504 11.2499 18.4104 11.2499 18.0004V17.4204H10.8899C9.24995 17.4204 7.91994 16.0404 7.91994 14.3404C7.91994 13.9304 8.25994 13.5904 8.66994 13.5904C9.07994 13.5904 9.41994 13.9304 9.41994 14.3404C9.41994 15.2104 10.0799 15.9204 10.8899 15.9204H11.2499V12.5404L9.73994 12.0004C8.95994 11.7304 7.90994 11.1504 7.90994 9.36041C7.90994 7.82041 9.11995 6.58041 10.5999 6.58041H11.2499V6.00041C11.2499 5.59041 11.5899 5.25041 11.9999 5.25041C12.4099 5.25041 12.7499 5.59041 12.7499 6.00041V6.58041H13.1099C14.7499 6.58041 16.0799 7.96041 16.0799 9.66041C16.0799 10.0704 15.7399 10.4104 15.3299 10.4104C14.9199 10.4104 14.5799 10.0704 14.5799 9.66041C14.5799 8.79041 13.9199 8.08041 13.1099 8.08041H12.7499V11.4604L14.2599 12.0004Z"
          fill="#292D32"
        />
        <path
          d="M9.42004 9.37008C9.42004 10.2401 9.73004 10.4101 10.24 10.5901L11.25 10.9401V8.08008H10.6C9.95004 8.08008 9.42004 8.66008 9.42004 9.37008Z"
          fill="#292D32"
        />
      </g>
    </svg>
  );
};
