import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { FieldError } from 'react-hook-form';

import 'react-phone-input-2/lib/material.css';

interface IProps {
  value: string;
  onChange: (phone: string) => void;
  error?: FieldError;
  specialLabel?: string;
}

export const InputPhoneNumber = (props: IProps) => {
  const { value, onChange, error, specialLabel = 'Phone' } = props;

  return (
    <StyledInputPhoneNumber>
      <PhoneInput country="au" value={value} onChange={onChange} specialLabel={specialLabel} />
      {error && <div className="err_message">{error?.message}</div>}
    </StyledInputPhoneNumber>
  );
};

const StyledInputPhoneNumber = styled.div`
  .react-tel-input .form-control {
    width: 100%;
    padding: 14px 14px 14px 58px;
    background-color: #fafafa;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border-radius: 8px;
    &:focus {
      border-color: #3f51b5;
      box-shadow: 0 0 0 1px #3f51b5;
    }
  }
  .react-tel-input .special-label {
    background-color: #fafafa !important;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    transform: translate(-30px, -2px) scale(0.75);
    padding: 0 12px 0 6px;
    color: rgba(0, 0, 0, 0.54);
  }
  .err_message {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #e62525;
    margin-top: 8px;
  }
`;
