import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@material-ui/core';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TelegramClient } from 'telegram';
import { StringSession } from 'telegram/sessions';
import * as Yup from 'yup';

import { appApi } from '@api';
import { CPStepOne, CPStepThree, CPStepTwo, CPStepZero } from '@components';
import { apiHash, apiId } from '@configs';
import { ICreate2FAPassword, IVerifyCode } from '@interfaces';
import {
  selectStep,
  selectUser,
  setInCompleteSession,
  setLoading,
  setSession,
  setStep,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { PATH_CREATE_PASSWORD_SUCCESS, PATH_HOME } from '@router';

export function CreatePasswordPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const twoFAPasswordSchema = Yup.object().shape({
    password: Yup.string().trim().required('Please enter password field'),
    // .min(6, "The password must be 6 or more characters")
    // .max(32, "The password is from 6-32 characters"),
    confirmPassword: Yup.string()
      .trim()
      // .min(6, "The password must be 6 or more characters")
      // .max(32, "The password is from 6-32 characters")
      .oneOf([Yup.ref('password'), null], 'The re-enter password and password must be the same'),
    recoverEmail: Yup.string().email('This email is not valid').max(255),
    code: Yup.number().max(100),
  });

  const verifyCodeSchema = Yup.object().shape({});

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid, isSubmitting },
    setValue,
  } = useForm<ICreate2FAPassword>({
    mode: 'onTouched',
    resolver: yupResolver(twoFAPasswordSchema),
  });

  const {
    register: registerCode,
    watch: watchCode,
    setError,
    formState: { errors: errorsCode },
  } = useForm<IVerifyCode>({
    mode: 'onChange',
    resolver: yupResolver(verifyCodeSchema),
  });

  const handleNextStep = async () => {
    let isValid = false;

    switch (step) {
      case 0:
        isValid = true;
        break;
      case 1:
        isValid = await trigger(['password', 'confirmPassword', 'hint'], {
          shouldFocus: true,
        });
        break;
      case 2:
        isValid = true;
        break;
      default:
        break;
    }

    if (isValid) {
      dispatch(setStep(step + 1));
    }
  };

  const step = useAppSelector(selectStep);
  const sessionInComplete = useAppSelector(selectUser)?.session;
  const user = useAppSelector(selectUser);

  const handleSubmitPassword = async (formData: ICreate2FAPassword) => {
    try {
      console.log('password: ', formData.password);
      console.log('confirm-password: ', formData.confirmPassword);
      console.log('hint: ', formData.hint);
      console.log('recover-email: ', formData.recoverEmail);

      dispatch(setLoading(true));

      const newSession = new StringSession(sessionInComplete);
      const client = new TelegramClient(newSession, apiId, apiHash, {
        connectionRetries: 5,
      });
      console.log('client cp: ', client); // for debug

      await client.connect(); // This assumes you have already authenticated with .start()

      let result: any;

      // this can be with or without recovery email
      if (formData.recoverEmail) {
        result = await client.updateTwoFaSettings({
          isCheckPassword: false,
          currentPassword: undefined,
          newPassword: formData.password,
          hint: formData.hint,
          email: formData.recoverEmail,
          emailCodeCallback: async (_) => {
            dispatch(setLoading(true));

            return await emailCodeCallback();
          },
          onEmailCodeError: (err) => {
            console.log('err: ', err);
            setError('code', {
              type: 'custom',
              message: 'Invalid code! Please try again.',
            });
          },
        });
      } else {
        result = await client.updateTwoFaSettings({
          isCheckPassword: false,
          currentPassword: undefined,
          newPassword: formData.password,
          hint: formData.hint,
        });
      }

      console.log(result); // prints the result

      // console.log("debug..."); //for debug
      if (user.id) {
        // @ts-ignore
        await appApi.updateSessionTele(client.session.save(), user.id);
      }

      dispatch(setLoading(false));
      navigate(`/${PATH_CREATE_PASSWORD_SUCCESS}`, { replace: true });
      dispatch(setSession(client.session.save())); // save new session
      dispatch(setStep(0));
      dispatch(setInCompleteSession('')); // clear incomplete session
    } catch (error) {
      console.log('error while creating 2fa password: ', error);
      dispatch(setLoading(false));
      alert('Something went wrong. Please try again');
      dispatch(setStep(0));
      navigate(PATH_HOME);
    }
  };

  const btnSubmitEmailCode = useRef<HTMLButtonElement>(null);

  const emailCodeCallback = useCallback(
    (): Promise<string> =>
      new Promise((resolve) => {
        dispatch(setStep(3));
        dispatch(setLoading(false));
        // @ts-ignore
        btnSubmitEmailCode.current.addEventListener('click', () => {
          dispatch(setLoading(true));
          resolve(watchCode('code'));
          dispatch(setLoading(false));
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Container>
      <form onSubmit={handleSubmit(handleSubmitPassword)}>
        <CPStepZero handleNextStep={handleNextStep} isActive={step === 0} />
        <CPStepOne
          isActive={step === 1}
          handleNextStep={handleNextStep}
          register={register}
          errors={errors}
        />
        <CPStepTwo
          isActive={step === 2}
          register={register}
          errors={errors}
          email={watch('recoverEmail')}
          isValid={isValid}
          isSubmiting={isSubmitting}
          skipMail={() => setValue('recoverEmail', '')}
        />
        <CPStepThree
          isActive={step === 3}
          register={registerCode}
          errors={errorsCode}
          emailAddress={watch('recoverEmail')}
          ref={btnSubmitEmailCode}
          code={watchCode('code')}
        />
      </form>
    </Container>
  );
}
//
