/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import { appApi } from '@/api';
import { BalanceComponent, MainButton, TickCircleIcon } from '@/components';
import { openToast, selectUser, setUser, useAppSelector } from '@/redux';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

declare global {
  interface Window {
    Quiet: {
      ab2str: any;
      addReadyCallback: any;
      disconnect: any;
      emscriptenInitialized: any;
      emscriptenLocateFile: any;
      init: any;
      mergeab: any;
      receiver: any;
      str2ab: any;
      transmitter: any;
    };
    Telegram: {
      WebApp: any;
    };
  }
}

export const SonicPay = () => {
  const quietInstance = useRef<Window['Quiet']>();
  const transmitter = useRef<any>();
  const dispatch = useDispatch();
  const telegram = window.Telegram.WebApp;
  const dataToSend = telegram?.initDataUnsafe?.query_id;

  // redux states
  const user_id_redux = useAppSelector(selectUser)?.user_tele_id;
  // const dataToSend = useAppSelector(selectUser)?.userChat?.query_id || '';

  // local states
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  // const [dataToSend, setDataToSend] = useState<string>('AAGyXgBFAAAAALJeAEW3tIlN'); // always 24 characters

  // same as the one in app.tsx
  const getAllInfo = async (user_id: string) => {
    try {
      const res = await appApi.getTwofactor(user_id);
      console.log('res get all 2fa_auth: ', res); // for debug
      // const personal = await adminApi.getPersonalDetail(user_id);
      dispatch(
        setUser({
          ...res.data,
          //  id: personal?.data?.data?.user?.id
        })
      );
    } catch (error) {
      console.log(error);
      console.log('reset all auth cache... (gegetAllInfo)'); // for debug
      // dispatch(resetAllAuth()); // clear cached user
    }
  };

  // quite js
  const handlePaySonic = () => {
    if (!dataToSend) {
      dispatch(openToast({ message: 'No data to send!', type: 'error', autoHideDuration: 3000 }));

      return;
    }

    if (transmitter?.current && quietInstance?.current) {
      console.log('queryId: ', dataToSend); // for debug
      console.log('dataToSend: ', quietInstance?.current.str2ab(dataToSend)); // for debug

      setBtnDisable(true);
      transmitter?.current.transmit(quietInstance?.current.str2ab(dataToSend));
      setTimeout(() => {
        setBtnDisable(false);
      }, 2000);
    } else {
      dispatch(openToast({ message: 'No trasmitter!', type: 'error', autoHideDuration: 3000 }));
    }
  };

  // efffects
  useEffect(() => {
    getAllInfo(user_id_redux?.toString());
  }, []);

  useEffect(() => {
    if (window.Quiet) {
      console.log('init quiet.............');

      window.Quiet.init({
        profilesPrefix: '/',
        memoryInitializerPrefix: '/',
        libfecPrefix: '/',
      });

      console.log('window.Quiet: ', window.Quiet);

      const onTransmitFinish = () => {
        console.log('finnish transmit...');
        dispatch(
          openToast({ message: 'Finish transmission!', type: 'success', autoHideDuration: 3000 })
        );
      };

      const onQuietReady = () => {
        console.log('quite ready...');

        quietInstance.current = window?.Quiet;

        transmitter.current = window.Quiet.transmitter({
          profile: 'audible',
          onFinish: onTransmitFinish,
        });

        dispatch(
          openToast({
            message: 'Tippe Sonic is ready!',
            type: 'success',
            autoHideDuration: 3000,
          })
        );
      };

      const onQuietFail = (reason: any) => {
        console.log(`quiet failed to initialize:  ${reason}`);
      };

      window.Quiet.addReadyCallback(onQuietReady, onQuietFail);
    } else {
      console.log('------------------------no window.quiet');
    }
  }, [window.Quiet]);

  useEffect(() => {
    console.log('quietInstance: ', quietInstance?.current);
  }, [quietInstance?.current]);

  useEffect(() => {
    console.log('transmitterInstance: ', transmitter?.current);
  }, [transmitter?.current]);

  useEffect(() => {
    console.log('local state => dataToSend: ', dataToSend);
  }, [dataToSend]);

  //   useEffect(() => {
  //     if (quietInstance?.current && transmitter?.current && dataToSend) {
  //       setBtnDisable(false);
  //     } else {
  //       setBtnDisable(true);
  //     }
  //   }, [quietInstance?.current, transmitter?.current, dataToSend]);

  useEffect(() => {
    // unmount
    return () => {
      if (quietInstance?.current && transmitter?.current) {
        console.log('sonic disconnect...');
        transmitter?.current?.destroy();
        quietInstance?.current?.disconnect();
      }
    };
  }, []);

  // main return
  return (
    <StyledSonicPay>
      <BalanceComponent />

      <div className="micro_container">
        <div className="circle">
          <div className="circle">
            <div className="circle">
              <div className="micro_container_icon">
                <div className="micro_container_icon_micro">
                  {/* <MicroIconSonic /> */}
                  <VolumeUpIcon fontSize="large" color="inherit" />
                  <div className="micro_container_icon_tick">
                    <TickCircleIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="micro_container_text" color="#6369ff">
          Tippe Sonic is ready!
        </p>

        <p>{dataToSend}</p>
      </div>

      <MainButton text="Pay" type="button" onClick={handlePaySonic} disabled={btnDisable} />
    </StyledSonicPay>
  );
};

const StyledSonicPay = styled.div`
  padding: 23px 16px 0 16px;
  /* margin-bottom: 72px; */

  .micro_container {
    text-align: center;
    margin-top: 21px;

    .circle {
      width: fit-content;
      margin: 0 auto;
      padding: 24px;
      border-radius: 500px;
      border: 1px solid #f3f2f8;
    }

    .micro_container_icon {
      width: fit-content;
      margin: 0 auto;

      .micro_container_icon_micro {
        background: #f3f2f8;
        border-radius: 500px;
        width: fit-content;
        margin: 0 auto;
        padding: 25px;
        position: relative;
        color: #6369ff;

        .micro_container_icon_tick {
          width: fit-content;
          position: absolute;
          right: 0;
        }
      }
    }

    .micro_container_text {
      font-weight: 600;
      font-size: 19px;
      color: #6369ff;
    }
  }
`;
