import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Breadcrumb,
  IconCircleCheck,
  IconPlus,
  IconTrash,
  ModalConfirm,
  Next,
  VerifyPayID,
} from '@components';
import { PATH_ADD_PAY_ID, PATH_VERIFY_PAY_ID } from '@router';
import { useMutationDeletePayId, useQueryGetPayId } from '@/services/pay-id';
import { PayIdTypeEnum } from '@/configs';

export const PayIDPage = () => {
  const navigate = useNavigate();

  const { payIdList } = useQueryGetPayId();

  const mutationDeletePayId = useMutationDeletePayId();

  const renderPayIdType = (
    type: PayIdTypeEnum.PHONE | PayIdTypeEnum.EMAIL | PayIdTypeEnum.ABN_ACN
  ) => {
    switch (type) {
      case PayIdTypeEnum.PHONE:
        return 'Mobile';
      case PayIdTypeEnum.ABN_ACN:
        return 'ABN/ACN';
      case PayIdTypeEnum.EMAIL:
        return 'Email';
      default:
        return '';
    }
  };

  return (
    <StyledPayIDPage>
      <Container>
        <Breadcrumb />
        <div className="top_zone">
          <div className="title">Your PayIDs</div>
          <Button
            variant="contained"
            startIcon={<IconPlus />}
            onClick={() => navigate(`/${PATH_ADD_PAY_ID}`)}
            className="btn_add_pay_id"
          >
            Add PayID
          </Button>
        </div>
        <div className="list_pay_id">
          {payIdList &&
            !!payIdList?.length &&
            payIdList.map((payIDInfo) => (
              <div className="list_item" key={payIDInfo.id}>
                <StyledPayIDCard>
                  <div className="type">{renderPayIdType(payIDInfo?.type)}</div>
                  <div className="info">
                    <div className="value">{payIDInfo?.payid}</div>
                    <div
                      className={classnames({
                        is_verify: true,
                        active: payIDInfo?.is_verify,
                      })}
                    >
                      {payIDInfo?.is_verify ? (
                        <>
                          Verified
                          <div className="icon_wrapper">
                            <IconCircleCheck />
                          </div>
                        </>
                      ) : (
                        'Unverified'
                      )}
                    </div>
                  </div>
                </StyledPayIDCard>
                <ModalConfirm
                  isDone={mutationDeletePayId.isSuccess || mutationDeletePayId.isError}
                  title="Delete PayIDs"
                  triggerButton={
                    <div className="delete_action" role="presentation">
                      <IconTrash />
                    </div>
                  }
                  ModalProps={{
                    maxWidth: 'sm',
                    sx: {
                      maxWidth: '300px',
                      mx: 'auto',
                    },
                  }}
                  confirmButton={
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: '#FF3B30',

                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '13px',
                        lineHeight: '16px',
                        textTransform: 'initial',
                        minWidth: '127px',
                        py: '10px',
                        borderColor: 'transparent',
                        '&:hover': {
                          opacity: 0.8,
                          bgcolor: '#FF3B30',
                        },
                      }}
                      onClick={() => {
                        mutationDeletePayId.mutate(payIDInfo.id);
                      }}
                    >
                      Delete
                    </Button>
                  }
                >
                  <Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      justifyContent="center"
                      sx={{
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '160%',
                      }}
                    >
                      <Box
                        sx={{
                          color: '#171717',
                        }}
                      >
                        Delete PayID:
                      </Box>
                      <Stack
                        direction="row"
                        sx={{
                          fontWeight: '600',
                        }}
                        component="span"
                        title={payIDInfo.payid}
                        spacing={0.5}
                      >
                        <Box component="span">{payIDInfo.payid}</Box>
                        <Stack alignItems="center" justifyContent="center">
                          {payIDInfo.is_verify && <IconCircleCheck />}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </ModalConfirm>
              </div>
            ))}
        </div>
        <div
          className="verify-contact"
          onClick={() => navigate(`/${PATH_VERIFY_PAY_ID}`, { replace: true })}
        >
          <div className="verify-contact-sub">
            <VerifyPayID />
            <p>Verify PayID</p>
          </div>
          <Next />
        </div>
      </Container>
    </StyledPayIDPage>
  );
};

const StyledPayIDPage = styled.div`
  .top_zone {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
    .btn_add_pay_id {
      background-color: #6369ff;
      text-transform: capitalize;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .list_item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .delete_action {
      width: 24px;
      height: 24px;
      background-color: #f3f2f8;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      cursor: pointer;
    }
  }
  .verify-contact {
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 10px;
    margin-top: 24px;
    border: none;
    outline: none;
    width: 100%;
    padding: 5px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .verify-contact-sub {
      display: flex;
      align-items: center;
      p {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        margin-left: 7px;
      }
    }
  }
`;

const StyledPayIDCard = styled.div`
  width: 100%;
  background-color: #f3f2f8;
  padding: 12px;
  border-radius: 8px;
  .type {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.8;
    color: #8f8f8f;
    margin-bottom: 4px;
  }
  .info {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .value {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-right: 12px;
      max-width: calc(100% - 78px);
      overflow: hidden;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .is_verify {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #8f8f8f;
      &.active {
        color: #0a84ff;
        display: flex;
      }
      .icon_wrapper {
        margin-left: 6px;
      }
    }
  }
`;
