import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { IconEyeOff, IconEyeShow } from '@components';
import { TextField } from '@material-ui/core';

type IProps = {
  register?: any;
  name?: string;
  error?: any;
  placeholder?: string;
  label?: string;
  required?: boolean;
  helperText?: string;
  className?: string;
  autoComplete?: string;
  onFocus?(): void;
  maxLength?: number;
};

export function InputPassword(props: IProps) {
  const {
    register,
    name,
    error,
    label,
    helperText,
    className = '',
    onFocus,
    autoComplete = 'off',
    maxLength,
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <StyledInput className={`input-form ${className}`}>
      <div className="password_wrapper">
        <TextField
          {...register}
          id={name}
          type={showPassword ? 'text' : 'password'}
          name={name}
          className={classnames({
            'border-danger': error,
          })}
          // placeholder={placeholder}
          onFocus={onFocus}
          autoComplete={autoComplete}
          variant="outlined"
          label={label}
          inputProps={{ maxLength }}
          inputRef={inputRef}
          onFocusCapture={() => {
            if (inputRef?.current) {
              setTimeout(() => {
                console.log('scroll...'); // for debug
                inputRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest',
                });
              }, 500);
            }
          }}
        />
        <div onClick={() => setShowPassword(!showPassword)} className="password_toggle">
          {showPassword ? <IconEyeOff /> : <IconEyeShow />}
        </div>
      </div>
      {!error && helperText && <div className="helper">{helperText}</div>}
      {error && <div className="err_message">{error?.message}</div>}
    </StyledInput>
  );
}

const StyledInput = styled.div`
  .MuiFormControl-root {
    width: 100%;

    .MuiOutlinedInput-root {
      border-radius: 8px;
    }
  }
  input {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    border-radius: 8px;
    /* padding: 15px 16px; */
    width: 100%;
    outline: none;
    &::placeholder {
      opacity: 0.5;
    }
    &:focus {
      box-shadow: none;
      border-color: #0a84ff;
      opacity: 0.7;
    }
    &.border-danger {
      border-color: #e52525;
    }
  }
  .helper {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #60626b;
    margin-top: 8px;
  }
  .err_message {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #e62525;
    margin-top: 8px;
  }
  .password_wrapper {
    position: relative;
    .password_toggle {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
`;
