import React, { forwardRef, ReactElement, useRef } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { UseFormRegisterReturn, FieldError } from 'react-hook-form';

import { IconClearInput } from '@components';

export type IInputProps = {
  name?: string;
  register?: Partial<UseFormRegisterReturn>;
  error?: FieldError;
  placeholder?: string;
  label?: string;
  required?: boolean;
  type?: 'text' | 'number' | 'tel';
  helperText?: string;
  className?: string;
  onFocus?(): void;
  onPaste?(e: React.ClipboardEvent<HTMLInputElement>): void;
  disabled?: boolean;
  preFix?: string;
  preIcon?: ReactElement;
  sufIcon?: ReactElement;
  onChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  value?: string;
  shrink?: boolean;
  isClearable?: boolean;
  onClearInput?(): void;
  maxLength?: number;
  defaultValue?: string;
};

export const Input = forwardRef<any, IInputProps>((props) => {
  const {
    register,
    name,
    error,
    label,
    helperText,
    className = '',
    type = 'text',
    onChange,
    onFocus,
    onPaste,
    disabled,
    preFix,
    preIcon,
    sufIcon,
    value,
    shrink,
    placeholder,
    isClearable = false,
    onClearInput,
    maxLength,
    defaultValue,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <StyledInput className={`input-form ${className}`}>
      <div className="input-group">
        {preFix && <div className="input-group-text">{preFix}</div>}
        {preIcon && <div className="pre_icon_wrapper">{preIcon}</div>}
        {sufIcon && <div className="suf_icon_wrapper">{sufIcon}</div>}
        <TextField
          onChange={onChange}
          value={value}
          {...register}
          id={name}
          type={type}
          name={name}
          // placeholder={placeholder}
          onFocus={onFocus}
          disabled={disabled}
          onPaste={onPaste}
          label={label}
          variant="outlined"
          InputLabelProps={{ shrink }}
          inputProps={{
            className: classnames({
              'pre_fix_input ': preFix,
              'pre_icon_input ': preIcon,
              'suf_icon_input ': isClearable,
            }),
            maxLength,
            step: 'any',
            lang: 'en',
          }}
          placeholder={placeholder}
          defaultValue={defaultValue}
          inputRef={inputRef}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            console.log('keydown', e.key);
            if (e.keyCode === 13 && inputRef?.current) {
              console.log('key Enter', inputRef.current);
              e.preventDefault();
              inputRef.current.blur();
            }
          }}
          onFocusCapture={() => {
            if (inputRef?.current) {
              setTimeout(() => {
                console.log('scroll...'); // for debug
                inputRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest',
                });
              }, 500);
            }
          }}
        />
        {isClearable && (
          <div className="suf_icon_wrapper" onClick={onClearInput}>
            <IconClearInput />
          </div>
        )}
      </div>
      {!error && helperText && <div className="helper">{helperText}</div>}
      {error && <div className="err_message">{error?.message}</div>}
    </StyledInput>
  );
});

const StyledInput = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiOutlinedInput-input {
    padding: 15px 16px;
    color: #171717;
  }
  .MuiInputBase-input.Mui-disabled {
    background-color: #f3f2f8;
  }
  .MuiInputLabel-outlined {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    transform: translate(14px, 15px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -7px) scale(0.75);
  }
  input {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* border: 1px solid rgba(0, 0, 0, 0.23); */
    border-radius: 12px;
    /* padding: 15px 16px; */
    width: 100%;
    outline: none;
    &::placeholder {
      color: '#171717';
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      opacity: 0.5;
    }
    &:focus {
      box-shadow: none;
      border-color: #0a84ff;
    }
    &.border-danger {
      border-color: #e52525;
    }
    &.pre_fix_input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .input-group {
    position: relative;
    .pre_icon_input {
      padding-left: 56px;
    }
    .suf_icon_input {
      padding-right: 48px;
    }
  }
  .input-group-text {
    display: flex;
    align-items: center;
    border: 1px solid #acacac;
    background-color: #f4f4f4;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 110%;
    color: #acacac;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-right: none;
  }
  .pre_icon_wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    display: flex;
    align-items: center;
  }
  .suf_icon_wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .helper {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #60626b;
    margin-top: 8px;
  }
  .err_message {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #e62525;
    margin-top: 8px;
  }
`;
