import React from 'react';

export function IconClock() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#EA8787" />
      <path
        d="M17.9998 9.6667C13.4082 9.6667 9.6665 13.4084 9.6665 18C9.6665 22.5917 13.4082 26.3334 17.9998 26.3334C22.5915 26.3334 26.3332 22.5917 26.3332 18C26.3332 13.4084 22.5915 9.6667 17.9998 9.6667ZM21.6248 20.975C21.5082 21.175 21.2998 21.2834 21.0832 21.2834C20.9748 21.2834 20.8665 21.2584 20.7665 21.1917L18.1832 19.65C17.5415 19.2667 17.0665 18.425 17.0665 17.6834V14.2667C17.0665 13.925 17.3498 13.6417 17.6915 13.6417C18.0332 13.6417 18.3165 13.925 18.3165 14.2667V17.6834C18.3165 17.9834 18.5665 18.425 18.8248 18.575L21.4082 20.1167C21.7082 20.2917 21.8082 20.675 21.6248 20.975Z"
        fill="white"
      />
    </svg>
  );
}
