import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from '@/libs/helper';
import { useBalance } from '@/hooks';
import { selectUser, useAppSelector } from '@/redux';

export const BalanceComponent = () => {
  const userId = useAppSelector(selectUser)?.user_tele_id;
  const balance = Number(useBalance(userId)?.balance);

  // main return
  return (
    <StyledBalance>
      <div className="balance_container">
        <div className="title">Available Balance</div>
        <div className="balance">AUD: {formatCurrency(Number(balance) || 0)}</div>
      </div>
    </StyledBalance>
  );
};

const StyledBalance = styled.div`
  .balance_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #f3f2f8, #f3f2f8), #2c2c2e;
    border-radius: 12px;
    margin-bottom: 40px;
    padding: 32px 0;
    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0a84ff;
      margin-bottom: 12px;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
    }
  }
`;
