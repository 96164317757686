import React from 'react';

export function Next() {
  return (
    <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.939941 12.28L5.28661 7.9333C5.79994 7.41997 5.79994 6.57997 5.28661 6.06664L0.939941 1.71997"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.939941 12.28L5.28661 7.9333C5.79994 7.41997 5.79994 6.57997 5.28661 6.06664L0.939941 1.71997"
        stroke="#171717"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
