import React from 'react';

export function VerifyContact() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.45 6.93989V9.44989C19.45 10.1599 18.73 10.6199 18.06 10.3699C17.22 10.0599 16.29 9.93989 15.31 10.0399C12.93 10.2999 10.49 12.5899 10.09 14.9599C9.76004 16.9299 10.39 18.7699 11.6 20.0699C12.15 20.6699 11.78 21.6399 10.97 21.7299C10.28 21.8099 9.60004 21.7899 9.22004 21.5099L3.72004 17.3999C3.07004 16.9099 2.54004 15.8499 2.54004 15.0299V6.93989C2.54004 5.80989 3.40004 4.56989 4.45004 4.16989L9.95004 2.10989C10.52 1.89989 11.46 1.89989 12.03 2.10989L17.53 4.16989C18.59 4.56989 19.45 5.80989 19.45 6.93989Z"
        fill="#6369FF"
      />
      <path
        d="M16 11.51C13.52 11.51 11.5 13.53 11.5 16.01C11.5 18.49 13.52 20.51 16 20.51C18.48 20.51 20.5 18.49 20.5 16.01C20.5 13.52 18.48 11.51 16 11.51Z"
        fill="#6369FF"
      />
      <path
        d="M21 21.9999C20.73 21.9999 20.48 21.8899 20.29 21.7099C20.25 21.6599 20.2 21.6099 20.17 21.5499C20.13 21.4999 20.1 21.4399 20.08 21.3799C20.05 21.3199 20.03 21.2599 20.02 21.1999C20.01 21.1299 20 21.0699 20 20.9999C20 20.8699 20.03 20.7399 20.08 20.6199C20.13 20.4899 20.2 20.3899 20.29 20.2899C20.52 20.0599 20.87 19.9499 21.19 20.0199C21.26 20.0299 21.32 20.0499 21.38 20.0799C21.44 20.0999 21.5 20.1299 21.55 20.1699C21.61 20.1999 21.66 20.2499 21.71 20.2899C21.8 20.3899 21.87 20.4899 21.92 20.6199C21.97 20.7399 22 20.8699 22 20.9999C22 21.2599 21.89 21.5199 21.71 21.7099C21.66 21.7499 21.61 21.7899 21.55 21.8299C21.5 21.8699 21.44 21.8999 21.38 21.9199C21.32 21.9499 21.26 21.9699 21.19 21.9799C21.13 21.9899 21.06 21.9999 21 21.9999Z"
        fill="#6369FF"
      />
    </svg>
  );
}
