import { Typography } from '@material-ui/core';

import { Input, InputPassword, MainButton } from '@components';
import { useStyles } from './style';

type IProps = {
  handleNextStep(): void;
  errors: any;
  register: any;
  isActive: boolean;
};

export function CPStepOne(props: IProps) {
  const classes = useStyles();

  const { handleNextStep, register, errors, isActive } = props;

  return (
    <div className={`${classes.step} ${isActive ? 'active' : ''}`}>
      <Typography className={classes.title}>Create a Password</Typography>
      <InputPassword
        label="Enter your password*"
        name="password"
        error={errors.password}
        register={register('password')}
        className={classes.mb_24}
        maxLength={32}
      />
      <InputPassword
        label="Confirm your password*"
        name="confirmPassword"
        error={errors.confirmPassword}
        register={register('confirmPassword')}
        maxLength={32}
      />
      <Typography className={classes.title}>Create a Password hint</Typography>
      <Input
        label="Enter a password hint (optional)"
        name="hint"
        error={errors.hint}
        type="text"
        register={register('hint')}
        maxLength={32}
      />
      <MainButton type="button" text="Continue" onClick={handleNextStep} />
    </div>
  );
}
