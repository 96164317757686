/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { z } from 'zod';

import { Breadcrumb, Form, Input, InputSelect } from '@components';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PayIdTypeEnum } from '@/configs';
import { useFormWithSchema } from '@/hooks';
import {
  useMutationSendOtpPayId,
  useMutationVerifyOtpPayId,
  useQueryGetPayId,
} from '@/services/pay-id';
import { setLoading } from '@/redux';

const otpPhoneSchema = z.object({
  contact: z.string({ required_error: 'The  contact field is required' }),
  otp_code_phone: z
    .string({ required_error: 'The OTP field is required' })
    .min(6, 'The OTP field must contain 6 characters')
    .max(6, 'The OTP field must contain 6 characters')
    .regex(/^[0-9]+$/, 'The OTP field must be only digits'),
});

export function VerifyPayIDPage() {
  const dispatch = useDispatch();
  const { payIdList } = useQueryGetPayId({ params: { is_verify: false } });

  const form = useFormWithSchema({ schema: otpPhoneSchema, options: { mode: 'all' } });
  const { control, formState, register, getValues, trigger, setValue, clearErrors, watch } = form;

  const { errors, isValid } = formState;

  const handleChangePayID = (selectedOption: any) => {
    setPayId(selectedOption.value);
  };

  const [payId, setPayId] = useState('');

  const payIdListEmailAndPhone =
    payIdList?.filter(
      (item) => item.type === PayIdTypeEnum.EMAIL || item.type === PayIdTypeEnum.PHONE
    ) ?? [];

  const payIdListEmailAndPhoneOptions = payIdListEmailAndPhone.map((item) => ({
    label: item.payid,
    value: String(item.id),
    disabled: item.is_verify,
  }));

  const payIdListAbnAcn = payIdList?.filter((item) => item.type === PayIdTypeEnum.ABN_ACN) ?? [];

  const payIdListAbnAcnOptions = payIdListAbnAcn.map((item) => ({
    label: item.payid,
    value: String(item.id),
    disabled: item.is_verify,
  }));

  const mutationSendOtpPayId = useMutationSendOtpPayId();
  const mutationVerifyOtpPayId = useMutationVerifyOtpPayId();

  useEffect(() => {
    // unmount
    return () => {
      dispatch(setLoading(false));
    };
  }, []);

  // main return
  return (
    <StyledVerifyContact>
      <Container>
        <Breadcrumb />
        <Form form={form} className="mt-24">
          <div className="form_title">Email & Mobile Number</div>
          <div className="row mb-24">
            <div className="input_wrapper">
              <Controller
                name="contact"
                control={control}
                render={({ field }) => {
                  return (
                    <InputSelect
                      className="input"
                      placeholder="Select contact"
                      onChange={async (option) => {
                        if (option.value === getValues('contact')) return;

                        await trigger('contact');

                        field.onChange(option.value);

                        mutationSendOtpPayId.reset();

                        setValue('otp_code_phone', '', { shouldValidate: false });

                        clearErrors('otp_code_phone');
                      }}
                      value={payIdListEmailAndPhoneOptions.find(
                        (item) => item.value === field.value
                      )}
                      options={payIdListEmailAndPhoneOptions}
                      error={errors.contact}
                      isOptionDisabled={(opt: any) => {
                        return opt.disabled;
                      }}
                      // formatOptionLabel={(data: any, formatOptionLabelMeta) => {
                      //   console.log('propsFormat: ', formatOptionLabelMeta);
                      //   const valueSelected = formatOptionLabelMeta.selectValue[0];
                      //   console.log('value: ', data);

                      //   return (
                      //     <Stack
                      //       direction="row"
                      //       spacing={0.5}
                      //       sx={{
                      //         color: valueSelected ? '#fff' : '#171717',
                      //         bgcolor: valueSelected ? '#0a84ff' : 'inherit',
                      //       }}
                      //     >
                      //       <Box>{data.label}</Box>
                      //       <Box
                      //         sx={{
                      //           fontWeight: '600',
                      //           fontSize: '14px',
                      //           lineHeight: '18px',
                      //           color: '#0a84ff',
                      //         }}
                      //       >
                      //         {data.disabled && 'Verified'}
                      //       </Box>
                      //     </Stack>
                      //   );
                      // }}
                    />
                  );
                }}
              />
            </div>
            <Button
              variant="contained"
              className="btn-blue"
              type="button"
              onClick={async () => {
                await trigger('contact');
                const idCode = getValues('contact');
                console.log('idCode: ', idCode);
                if (idCode) {
                  console.log('idCode:', idCode);
                  mutationSendOtpPayId.mutate(idCode);
                  setValue('otp_code_phone', '');
                }
              }}
            >
              Send OTP
            </Button>
          </div>
          <div className="row mb-24">
            <div className="input_wrapper">
              <Input
                className="input"
                label="Enter OTP"
                name="otp_code_phone"
                error={errors.otp_code_phone}
                type="number"
                register={register('otp_code_phone')}
                // type="text"
                // onChange={(e) => {
                //   const res = e?.target?.value?.replaceAll('e', '')?.replace(/[^0-9.]/g, '');
                //   setValue('otp_code_phone', res, {
                //     shouldValidate: true,
                //   });
                // }}
                // value={watch('otp_code_phone')}
                disabled={mutationSendOtpPayId.isLoading || !mutationSendOtpPayId.isSuccess}
              />
            </div>
            <Button
              variant="contained"
              className="btn-blue"
              type="button"
              disabled={!mutationSendOtpPayId.isSuccess || !isValid}
              onClick={async () => {
                await trigger('otp_code_phone');

                const idCode = getValues('contact');
                console.log('idCode: ', idCode);

                const code = getValues('otp_code_phone');

                if (!mutationSendOtpPayId.isSuccess) return;

                mutationVerifyOtpPayId.mutate(
                  { code, id_payid: idCode },
                  {
                    onSuccess() {
                      mutationSendOtpPayId.reset();
                    },
                  }
                );
              }}
            >
              Verify
            </Button>
          </div>
        </Form>
        <div className="divide" />
        <form className="mt-24">
          <div className="form_title">ABN/ACN</div>
          <div className="row mb-24">
            <div className="input_wrapper">
              <InputSelect
                className="input"
                placeholder="Select PayID"
                name="pay_id"
                onChange={handleChangePayID}
                value={payIdListAbnAcnOptions?.find((item) => item.value === payId)}
                options={payIdListAbnAcnOptions}
              />
            </div>
            <Button variant="contained" className="btn-blue" type="submit">
              Verify
            </Button>
          </div>
        </form>
      </Container>
    </StyledVerifyContact>
  );
}

const StyledVerifyContact = styled.div`
  form {
    margin-top: 36px;
    &.mt-24 {
      margin-top: 24px;
    }
  }
  .divide {
    height: 1px;
    width: 100%;
    background-color: #eeedf5;
  }
  .form_title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .mb-24 {
    margin-bottom: 24px;
    &.row_item {
      width: 50%;
    }
  }
  .row {
    display: grid;
    /* grid-row-gap: 24px; */
    grid-column-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    .input_wrapper {
      grid-column: span 3 / span 3;
      position: relative;
      .un_verify {
        position: absolute;
        top: 50%;
        right: 16px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #dc0000;
        transform: translateY(-50%);
        background-color: #f3f2f8;
        padding: 4px;
      }
    }
    .MuiButton-label {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
    }
    .MuiButton-contained.Mui-disabled {
      background-color: #8f8f8f !important;
    }
  }
  .verified {
    background-color: rgba(10, 132, 255, 0.11);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    padding-left: 18px;
    margin-bottom: 24px;
    .text {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      span {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #0a84ff;
        margin-left: 6px;
      }
    }
  }
`;
