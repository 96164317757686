import { selectIsVerify, useAppSelector } from '@redux';
import { Navigate, Outlet } from 'react-router-dom';

export type RequireVerifyProps = {
  children?: React.ReactElement;
};

function RequireVerify({ children }: RequireVerifyProps) {
  const isVerify = useAppSelector(selectIsVerify);

  return isVerify ? children ?? <Outlet /> : <Navigate to="/admin" />;
}

export default RequireVerify;
