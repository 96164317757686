import React from 'react';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, MainButton, Input, InputSelect, InputPhoneNumber } from '@components';
import { IPayIDInfo, IOption } from '@interfaces';
import { PAY_ID_TYPE } from '@configs';
import { useAppDispatch, useAppSelector, setLoading, selectUser, openToast } from '@redux';
import { payIdApi } from '@api';
import { useMutationAddPayId } from '@/services/pay-id';

export const AddPayIDPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);

  const phoneRegExp =
    // eslint-disable-next-line no-useless-escape
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

  const abn_acnRedExp = /^\d+$/;

  const payIDSchema = Yup.object().shape({
    type: Yup.string().required('This PayID type field is required'),
    value: Yup.string()
      .trim()
      .when('type', {
        is: PAY_ID_TYPE.email,
        then: Yup.string()
          .email('Please enter valid email')
          .required('This PayID field is required'),
      })
      .when('type', {
        is: PAY_ID_TYPE.phone,
        then: Yup.string()
          .required('This PayID field is required')
          .min(9, 'Please enter valid phone number')
          .transform((value, originalValue) => {
            return originalValue === '' ? '' : `+${value}`;
          })
          .matches(phoneRegExp, 'Please enter valid phone number'),
      })
      .when('type', {
        is: PAY_ID_TYPE.abn_acn,
        then: Yup.string()
          .matches(abn_acnRedExp, 'Please enter valid ABN/ACN')
          .max(20, 'Please enter valid ABN/ACN')
          .min(8, 'Please enter valid ABN/ACN')
          .required('This PayID field is required'),
      }),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<IPayIDInfo>({
    mode: 'onTouched',
    resolver: yupResolver(payIDSchema),
    defaultValues: {},
  });

  const mutationAddPayId = useMutationAddPayId();

  const payIdTypeOptions: Array<IOption> = [
    { value: PAY_ID_TYPE.email, label: 'Email' },
    { value: PAY_ID_TYPE.phone, label: 'Mobile' },
    { value: PAY_ID_TYPE.abn_acn, label: 'ABN/ACN' },
  ];

  const handleChangeType = (selectedOption: any) => {
    setValue('type', selectedOption.value, { shouldValidate: true });
    setValue('value', '');
    clearErrors();
  };

  const checkPayIdNPP = async (type: 'EMAIL' | 'PHONE' | 'ABNACN', value: string) => {
    try {
      dispatch(setLoading(true));
      const res = await payIdApi.verifyPayId(type, value);
      console.log('checkId', res?.data?.data);

      // if (res?.data?.data?.registered) {
      //   return Promise.resolve();
      // }
      // dispatch(setLoading(false));
      // return Promise.reject();

      return Promise.resolve();
    } catch (error: any) {
      console.log(error?.response);

      // return Promise.reject();

      return Promise.resolve();
    }
  };

  const onSubmitPayID = async (formData: IPayIDInfo) => {
    // let nppType = '';
    // switch (formData?.type) {
    //   case PAY_ID_TYPE.phone:
    //     nppType = 'TELI';
    //     break;
    //   case PAY_ID_TYPE.email:
    //     nppType = 'EMAL';
    //     break;
    //   default:
    //     nppType = 'AUBN';
    //     break;
    // }
    // @ts-ignore
    checkPayIdNPP(formData.type, formData?.value?.replaceAll('+', ''))
      .then(async () => {
        mutationAddPayId.mutate({
          type: formData?.type,
          user_tele_id: Number(user?.user_tele_id),
          payid: formData?.value,
        });
      })
      .catch(() => {
        console.log('error');
        dispatch(setLoading(false));
        dispatch(
          openToast({
            message:
              "We're almost there, just a few more steps to verify your PayID, please try again.",
            type: 'error',
          })
        );
      });
  };

  const handleClearPayId = () => {
    setValue('value', '', { shouldValidate: true });
  };

  return (
    <StyledAddPayId>
      <Container>
        <Breadcrumb />
        <div className="top_zone">
          <div className="title">Add PayIDs</div>
        </div>
        <form onSubmit={handleSubmit(onSubmitPayID)}>
          <InputSelect
            className="mb-24"
            options={payIdTypeOptions}
            placeholder="PayID type"
            register={register('type')}
            name="type"
            error={errors.type}
            onChange={handleChangeType}
            value={payIdTypeOptions?.find((type) => type.value === watch('type')) || null}
          />
          {watch('type') !== 'PHONE' ? (
            <Input
              className="mb-24 row_item"
              label="Enter PayID"
              name="value"
              register={register('value')}
              error={errors.value}
              isClearable
              onClearInput={handleClearPayId}
            />
          ) : (
            <InputPhoneNumber
              value={watch('value') || ''}
              onChange={(phone: string) => {
                setValue('value', phone, { shouldValidate: true });
              }}
              error={errors.value}
              specialLabel="Enter PayID"
            />
          )}
          <MainButton text="Add PayIDs" type="submit" />
        </form>
      </Container>
    </StyledAddPayId>
  );
};

const StyledAddPayId = styled.div`
  .top_zone {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    .title {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
  }
  .mb-24 {
    margin-bottom: 24px;
  }
`;
