import { adminApi } from '@api';
import { BasicDatePicker, Breadcrumb, ControlInput, Form, MainButton } from '@components';
import { openToast, selectUser, setLoading, useAppDispatch, useAppSelector } from '@redux';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useFormWithSchema } from 'src/hooks';
import { personalDetailSchema, PersonalDetailType } from 'src/validations';
import styled from 'styled-components';

export function PersonalDetails() {
  const [personalDetail, setPersonalDetail] = useState<PersonalDetailType | null>(null);

  const memoPersonalDetail = useMemo(() => personalDetail, [personalDetail]);

  const form = useFormWithSchema({
    schema: personalDetailSchema,
    options: {
      defaultValues: { date_of_birth: new Date(), ...memoPersonalDetail },
    },
  });

  const { formState, control, reset: resetForm } = form;
  const { errors, isSubmitting } = formState;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { user_tele_id } = useAppSelector(selectUser);

  useEffect(() => {
    if (!user_tele_id) return;

    (async () => {
      try {
        dispatch(setLoading(true));
        const res = await adminApi.getPersonalDetailInfo(String(user_tele_id));

        const data = res?.data?.data;

        setPersonalDetail((prev) => ({
          ...prev,
          first_name: data?.first_name ?? '',
          middle_name: data?.middle_name ?? '',
          last_name: data?.last_name ?? '',
          date_of_birth: data?.date_of_birth ?? new Date(),
        }));
      } catch (error) {
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, [user_tele_id, dispatch]);

  useEffect(() => {
    if (memoPersonalDetail) {
      resetForm({ ...memoPersonalDetail });
    }
  }, [memoPersonalDetail, resetForm]);

  const onSubmit = async (values: PersonalDetailType) => {
    console.log('form values:', values); // for debug
    const dateOfBirthFormat = dayjs(values.date_of_birth).format('YYYY-MM-DD');
    const newValues: PersonalDetailType = { ...values, date_of_birth: dateOfBirthFormat };
    try {
      dispatch(setLoading(true));
      const res = await adminApi.addPersonalDetail(String(user_tele_id), newValues);
      console.log(res?.data?.data);
      dispatch(
        openToast({
          message: 'Your personal details have been saved successfully.',
          type: 'success',
        })
      );
      navigate('/admin');
    } catch (error: any) {
      dispatch(
        openToast({
          message: "We're sorry, an error has occurred. Please try again.",
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <StyledPersonalDetail>
      <Breadcrumb />
      <Form<PersonalDetailType> form={form} onSubmit={onSubmit} className="person_form">
        <ControlInput
          control={control}
          name="first_name"
          inputProps={{
            label: 'First Name',
            className: 'inputField',
            error: errors.first_name,
            shrink: true,
            placeholder: 'Enter first name',
          }}
        />

        <ControlInput
          control={control}
          name="middle_name"
          inputProps={{
            label: 'Middle Name:',
            className: 'inputField',
            placeholder: 'Enter middle name',
            shrink: true,
            error: errors.middle_name,
          }}
        />

        <ControlInput
          control={control}
          name="last_name"
          inputProps={{
            label: 'Last Name',
            className: 'inputField',
            shrink: true,
            placeholder: 'Enter last name',
            error: errors.last_name,
          }}
        />

        <Controller
          name="date_of_birth"
          control={control}
          render={({ field: { onChange, value, ...rest } }) => {
            return (
              <BasicDatePicker
                className="inputField"
                label="Date of Birth"
                handleChange={(date) => {
                  onChange(new Date(date));
                }}
                value={new Date(value)}
                error={errors?.date_of_birth?.message}
                disableFuture
                {...rest}
              />
            );
          }}
        />

        <MainButton text="Save changes" type="submit" disabled={isSubmitting} />
      </Form>
    </StyledPersonalDetail>
  );
}

const StyledPersonalDetail = styled.div`
  padding: 0 16px 0 16px;
  /* min-height: calc(100vh - 24px); */
  margin-bottom: 72px;
  .inputField {
    margin-top: 30px;
  }
`;
