import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogProps } from '@mui/material';
import { useIsOpen } from '@/hooks';
import { useAppSelector, selectApp } from '@/redux';

interface ModalProps {
  triggerButton: React.ReactElement;
  confirmButton: React.ReactElement;
  title: string;
  children?: React.ReactNode;
  isDone?: boolean;
  className?: string;
  onClickTriggerButton?(open: () => void): void;
  onClickCancelButton?(close: () => void): void;
  ModalProps?: Partial<DialogProps>;
  cancelBtnText?: string;
}

export function ModalConfirm({
  triggerButton,
  confirmButton,
  title = '',
  children,
  isDone = false,
  ModalProps,
  className,
  onClickTriggerButton,
  onClickCancelButton,
  cancelBtnText = 'Cancel',
}: ModalProps) {
  const { close, open, isOpen } = useIsOpen();

  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  const trigger = React.cloneElement(triggerButton, {
    onClick: onClickTriggerButton ? () => onClickTriggerButton(open) : open,
  });

  const isLoading = useAppSelector(selectApp).loading;

  if (isLoading) return null;

  return (
    <div className={className}>
      {trigger}
      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            minWidth: '300px',
            borderRadius: '16px',
            pb: '20px',
          },
        }}
        {...ModalProps}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '23px',
            letterSpacing: '0em',
            textAlign: 'center',
            px: '16px',
          }}
        >
          <Box
            sx={{
              borderBottom: '1px solid #F3F2F8',
              pb: '16px',
            }}
          >
            {title}
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">{children}</DialogContentText> */}
          {children}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            py: 0,
          }}
        >
          <Button
            onClick={onClickCancelButton ? () => onClickCancelButton(close) : close}
            variant="outlined"
            sx={{
              bgcolor: '#F4F4F5',
              color: '#8f8f8f',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '13px',
              lineHeight: '16px',
              textTransform: 'initial',
              minWidth: '127px',
              py: '10px',
              borderColor: 'transparent',
              width: '100%',
            }}
          >
            {cancelBtnText}
          </Button>
          {confirmButton}
        </DialogActions>
      </Dialog>
    </div>
  );
}
