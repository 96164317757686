/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';

// declare global {
//   interface Window {
//     Quiet: {
//       ab2str: any;
//       addReadyCallback: any;
//       disconnect: any;
//       emscriptenInitialized: any;
//       emscriptenLocateFile: any;
//       init: any;
//       mergeab: any;
//       receiver: any;
//       str2ab: any;
//       transmitter: any;
//     };
//   }
// }

export function HelpPage() {
  // const quietInstance = useRef<Window['Quiet']>();
  // const transmitter = useRef<any>();
  // const [textToSend, setTextToSend] = useState<string>();
  // const [textReceive, setTextReceive] = useState<string>('Nothing...');
  // const dispatch = useDispatch();

  // // quite js
  // useEffect(() => {
  //   if (window.Quiet) {
  //     console.log('init quiet.............');

  //     window.Quiet.init({
  //       profilesPrefix: '/',
  //       memoryInitializerPrefix: '/',
  //       libfecPrefix: '/',
  //     });

  //     console.log('window.Quiet: ', window.Quiet);

  //     const onTransmitFinish = () => {
  //       console.log('finnish transmit...');
  //       dispatch(openToast({ message: 'done', type: 'success', autoHideDuration: 3000 }));
  //     };

  //     const onQuietReady = () => {
  //       console.log('quite ready...');

  //       quietInstance.current = window?.Quiet;

  //       transmitter.current = window.Quiet.transmitter({
  //         profile: 'audible',
  //         onFinish: onTransmitFinish,
  //       });

  //       dispatch(
  //         openToast({
  //           message: 'init quiet instance and transmitter done',
  //           type: 'success',
  //           autoHideDuration: 3000,
  //         })
  //       );
  //     };

  //     const onQuietFail = (reason: any) => {
  //       console.log(`quiet failed to initialize:  ${reason}`);
  //     };

  //     window.Quiet.addReadyCallback(onQuietReady, onQuietFail);
  //   } else {
  //     console.log('------------------------no window.quiet');
  //   }
  // }, [window.Quiet]);

  // useEffect(() => {
  //   console.log('quietInstance: ', quietInstance?.current);
  // }, [quietInstance?.current]);

  // useEffect(() => {
  //   console.log('transmitterInstance: ', transmitter?.current);
  // }, [transmitter?.current]);

  // useEffect(() => {
  //   // unmount
  //   return () => {
  //     if (quietInstance?.current && transmitter?.current) {
  //       console.log('sonic disconnect...');
  //       transmitter?.current?.destroy();
  //       quietInstance?.current?.disconnect();
  //     }
  //   };
  // }, []);

  // main return
  return (
    <StyledHelpPage>
      <div className="title">How can we help?</div>
      <div className="subtitle">What is Tippe AU Shopper bot?</div>
      <div className="content">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
        consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </div>
      <div className="subtitle">How does it works?</div>
      <div className="content">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
        consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
        minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
        duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </div>
      <div className="subtitle">Contact</div>
      <div className="content">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
        consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
        minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
        duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </div>

      {/* <Input
        placeholder="text to send"
        value={textToSend}
        onChange={(e) => {
          setTextToSend(e?.target?.value);
        }}
      />

      <Button
        disabled={!transmitter?.current || !quietInstance?.current}
        onClick={() => {
          if (!textToSend) return;

          if (transmitter?.current && quietInstance?.current) {
            transmitter?.current.transmit(quietInstance?.current.str2ab(textToSend));
          } else {
            dispatch(
              openToast({ message: 'no trasmitter', type: 'error', autoHideDuration: 3000 })
            );
          }
        }}
      >
        Send
      </Button>

      <Button
        disabled={!quietInstance?.current}
        onClick={() => {
          if (quietInstance?.current) {
            quietInstance?.current?.receiver({
              profile: 'audible',
              onReceive: (recvPayload: any) => {
                let content = new ArrayBuffer(0);
                content = quietInstance?.current?.mergeab(content, recvPayload);
                setTextReceive(quietInstance?.current?.ab2str(content));
              },
              onCreateFail: (reason: any) => {
                console.log('failed to create quiet receiver: ' + reason);
                dispatch(
                  openToast({
                    message: 'failed to create quiet receiver: ',
                    type: 'error',
                    autoHideDuration: 3000,
                  })
                );
              },
              onReceiveFail: (num_fails: any) => {
                dispatch(
                  openToast({
                    message:
                      "We didn't quite get that. It looks like you tried to transmit something. You may need to move the transmitter closer to the receiver and set the volume to 50%.",
                    type: 'error',
                    autoHideDuration: 3000,
                  })
                );
              },
            });
          } else {
            dispatch(
              openToast({ message: 'no quietInstance', type: 'error', autoHideDuration: 3000 })
            );
          }
        }}
      >
        Start Listening
      </Button>

      <div className="content">{textReceive}</div> */}
    </StyledHelpPage>
  );
}

const StyledHelpPage = styled.div`
  padding: 35px 16px 0 16px;
  /* margin-bottom: 500px; */
  .title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
  .subtitle {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    margin-bottom: 16px;
  }
  .content {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    opacity: 0.7;
    margin-bottom: 24px;
  }
`;
