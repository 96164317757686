import React from 'react';

export function AccountContact() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#5FE8BF" />
      <path
        d="M11.525 13.475L10.6 14.4C10.405 14.595 10.095 14.595 9.895 14.405C9.84 14.35 9.785 14.3 9.73 14.245C9.215 13.725 8.75 13.18 8.335 12.61C7.925 12.04 7.595 11.47 7.355 10.905C7.12 10.335 7 9.79 7 9.27C7 8.93 7.06 8.605 7.18 8.305C7.3 8 7.49 7.72 7.755 7.47C8.075 7.155 8.425 7 8.795 7C8.935 7 9.075 7.03 9.2 7.09C9.33 7.15 9.445 7.24 9.535 7.37L10.695 9.005C10.785 9.13 10.85 9.245 10.895 9.355C10.94 9.46 10.965 9.565 10.965 9.66C10.965 9.78 10.93 9.9 10.86 10.015C10.795 10.13 10.7 10.25 10.58 10.37L10.2 10.765C10.145 10.82 10.12 10.885 10.12 10.965C10.12 11.005 10.125 11.04 10.135 11.08C10.15 11.12 10.165 11.15 10.175 11.18C10.265 11.345 10.42 11.56 10.64 11.82C10.865 12.08 11.105 12.345 11.365 12.61C11.415 12.66 11.47 12.71 11.52 12.76C11.72 12.955 11.725 13.275 11.525 13.475Z"
        fill="white"
      />
      <path
        d="M16.985 15.165C16.985 15.305 16.96 15.45 16.91 15.59C16.895 15.63 16.88 15.67 16.86 15.71C16.775 15.89 16.665 16.06 16.52 16.22C16.275 16.49 16.005 16.685 15.7 16.81C15.695 16.81 15.69 16.815 15.685 16.815C15.39 16.935 15.07 17 14.725 17C14.215 17 13.67 16.88 13.095 16.635C12.52 16.39 11.945 16.06 11.375 15.645C11.18 15.5 10.985 15.355 10.8 15.2L12.435 13.565C12.575 13.67 12.7 13.75 12.805 13.805C12.83 13.815 12.86 13.83 12.895 13.845C12.935 13.86 12.975 13.865 13.02 13.865C13.105 13.865 13.17 13.835 13.225 13.78L13.605 13.405C13.73 13.28 13.85 13.185 13.965 13.125C14.08 13.055 14.195 13.02 14.32 13.02C14.415 13.02 14.515 13.04 14.625 13.085C14.735 13.13 14.85 13.195 14.975 13.28L16.63 14.455C16.76 14.545 16.85 14.65 16.905 14.775C16.955 14.9 16.985 15.025 16.985 15.165Z"
        fill="white"
      />
    </svg>
  );
}
