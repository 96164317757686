import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { ArrowBackIosRounded } from '@mui/icons-material';

import { useAppDispatch, setStep, useAppSelector, selectStep } from '@redux';
import {
  authRoutes,
  stepRoutes,
  PATH_ADMIN_HOME,
  PATH_PAY_ID,
  PATH_SETTINGS,
  PATH_RECOVER_ACCOUNT,
  PATH_HELP,
} from '@router';

export function Navbar() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>('');
  const step = useAppSelector(selectStep);

  const titleMap = useMemo(
    () => ({
      [`/${PATH_ADMIN_HOME}`]: 'Admin',
      [`/${PATH_PAY_ID}`]: 'Pay ID',
      [`/${PATH_SETTINGS}`]: 'Settings',
      [`/${PATH_RECOVER_ACCOUNT}`]: 'Recover Account',
      [`/${PATH_HELP}`]: 'Help',
    }),
    []
  );

  useEffect(() => {
    // WHAT: check auth path
    const isAuthRoute = authRoutes.some((item: string) => pathname.includes(item));
    setTitle(isAuthRoute ? '' : titleMap[pathname]);
  }, [pathname, titleMap]);

  // const handleCloseWebApp = () => {
  //   //@ts-ignore
  //   const telegram = window.Telegram.WebApp;
  //   telegram.close();
  // };

  const handleGoBack = () => {
    const isStepRoute = stepRoutes.some((item: string) => pathname.includes(item));
    if (isStepRoute && step > 0) {
      dispatch(setStep(step - 1));
    } else {
      navigate(-1);
    }
  };

  return (
    <StyledNavbar>
      <div className="back_btn" onClick={handleGoBack}>
        <ArrowBackIosRounded />
      </div>
      <div className="title">
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
      </div>
    </StyledNavbar>
  );
}

const StyledNavbar = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .back_btn {
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
