/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import { InputPassword } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';

import { IRecoverInfo } from '@/interfaces';
import { adminApi, appApi } from '@api';
import {
  openToast,
  selectCallbackAfterEnterPin,
  selectRouteAfterEnterPin,
  selectTelegramClient,
  selectUser,
  setCallbackAfterEnterPin,
  setLoading,
  setRecoverEmail,
  setRouteAfterEnterPin,
  setSession,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { PATH_HOME, PATH_VERIFY_CODE } from '@router';
import { useNavigate } from 'react-router-dom';
import { Api } from 'telegram';
import { computeCheck } from 'telegram/Password';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
    marginBottom: '36px',
    textAlign: 'center',
  },
  sendButton: {
    width: '100%',
    backgroundColor: '#0A84FF',
    borderRadius: '10px',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&.MuiButton-containedSizeLarge': {
      padding: '13px',
    },
    '&.MuiButton-root': {
      textTransform: 'capitalize',
    },
  },
  buttonWrapper: {
    left: 0,
    bottom: 0,
    right: 0,
    position: 'fixed',
    backgroundColor: '#F6F6F6',
    boxShadow: '0px -0.33px 0px #D8D8D8',
    padding: '8px',
  },
  mb_24: {
    marginBottom: '24px',
  },
  hint: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#8d8d8d',
  },
}));

const styleModal = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 10,
  overflow: 'hidden',
  // padding: '10px 20px',

  content: {
    textAlign: 'center',
    padding: '20px 20px',
  },

  btn: {
    width: '100%',
    display: 'flex',
  },
};

export function EnterPinPage() {
  const classes = useStyles();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // @ts-ignore
  const telegram = window?.Telegram?.WebApp;
  const query_id = telegram?.initDataUnsafe?.query_id;

  // local states
  const [hint, setHint] = useState<string | undefined>(undefined);
  const [showModalDisconnected, setShowModalDisconnected] = useState<boolean>(false);
  const [showModalSessionExpired, setShowModalSessionExpired] = useState<boolean>(false);
  const [showModalDisconnectedCheckingSession, setShowModalDisconnectedCheckingSession] =
    useState<boolean>(false);

  // redux state
  const session = useAppSelector(selectUser)?.session;
  const nextRoute = useAppSelector(selectRouteAfterEnterPin);
  const callBack = useAppSelector(selectCallbackAfterEnterPin);
  const client = useAppSelector(selectTelegramClient);
  // const query_id_redux = useAppSelector(selectQueryId);

  const twoFAPasswordSchema = Yup.object().shape({});

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<{ password: string }>({
    mode: 'onChange',
    resolver: yupResolver(twoFAPasswordSchema),
  });

  // FUNCTION: submit password
  const submitPin = useCallback(
    async (formData: { password: string }) => {
      dispatch(setLoading(true));
      // WHAT: handle possible entry errors
      try {
        if (!user.session) {
          throw new Error('no session!!!');
        }

        if (!formData.password) {
          throw new Error('No Password!!!');
        }

        if (!client) {
          throw new Error('no tele client!!!');
        }
      } catch (error) {
        console.log('Submit pin entry error: ', error); // for debug
        dispatch(
          openToast({
            message: 'Something went wrong, please try again',
            type: 'error',
          })
        );
        handleBack();

        return;
      }

      console.log('client in enter pin: ', client); // for debug

      // WHAT: check password and then run the below code (check and update user info)
      try {
        const resPass1 = await client.invoke(new Api.account.GetPassword());
        // measure
        const start = Date.now();
        const password1 = await computeCheck(resPass1, formData.password);
        const end = Date.now();
        console.log(`Execution time: ${end - start} ms`);
        console.log({ password1 });

        const result = await client.invoke(
          new Api.auth.CheckPassword({
            password: new Api.InputCheckPasswordSRP({
              srpId: password1.srpId,
              A: password1.A,
              M1: password1.M1,
            }),
          })
        );
        console.log({ result }); // prints the result

        if (nextRoute) navigate(nextRoute, { replace: true });
        if (callBack) callBack();
      } catch (error: any) {
        console.log(error?.message); // for debug

        dispatch(
          openToast({
            message: 'Something went wrong, please try again',
            type: 'error',
          })
        );

        // handle disconnected
        if (
          error?.message === 'Cannot send requests while disconnected. You need to call .connect()'
        ) {
          console.log('disconnected');
          setShowModalDisconnected(true);
        }

        // handle invalid password
        if (error?.message === '400: PASSWORD_HASH_INVALID (caused by auth.CheckPassword)') {
          console.log('invalid password');
          setError('password', {
            type: 'custom',
            message: 'Invalid password! Please try again.',
          });
        }

        dispatch(setLoading(false));

        return;
      }

      // WHAT: update account contact email if user change recover email on telegram app
      try {
        const resPass2 = await client.invoke(new Api.account.GetPassword());
        const password2 = await computeCheck(resPass2, formData.password);

        // this just used for getting email
        const privateSetting = await client.invoke(
          new Api.account.GetPasswordSettings({
            password: new Api.InputCheckPasswordSRP({
              srpId: password2.srpId,
              A: password2.A,
              M1: password2.M1,
            }),
          })
        );
        const email = privateSetting?.email;
        dispatch(setRecoverEmail(email));
        // get full info (exclude email)
        const me = await client.getMe();
        console.log('Me is', me); // for debug
        // payload for api
        const payload: IRecoverInfo = {
          user_tele_id: Number(user?.user_tele_id),
          // @ts-ignore
          phone_number: me?.phone,
          // @ts-ignore
          full_name: `${me?.firstName ? me?.firstName : ''} ${me?.lastName ? me?.lastName : ''}`,
        };
        // @ts-ignore
        if (me?.username) payload.user_name = me?.username;
        // @ts-ignore
        if (email) payload.email = email;
        if (query_id) {
          payload.query_id = query_id;
        }
        console.log('payload: ', payload); // for debug

        // call api
        await adminApi.updateRecoverEmail(payload);

        // if (nextRoute) navigate(nextRoute, { replace: true });
        // if (callBack) callBack();
      } catch (error) {
        console.log(error);
      }
      dispatch(setLoading(false)); // loading will be set to false in callback function
    },
    [client]
  );

  // FUNCTION: check if the session (main) is still valid before doing anything
  const checkSession = useCallback(
    async (session?: string) => {
      try {
        if (!session) {
          dispatch(
            openToast({
              message: 'No session was found, please try again',
              type: 'error',
              autoHideDuration: 3500,
            })
          );
          throw new Error('No session found in enter pin page!');
        }

        if (!client) {
          dispatch(
            openToast({
              message: 'No client was found, please try again',
              type: 'error',
              autoHideDuration: 3500,
            })
          );
          throw new Error('No client found in enter pin page!');
        }

        if (!callBack && !nextRoute) {
          dispatch(
            openToast({
              message: 'No client was found, please try again',
              type: 'error',
              autoHideDuration: 3500,
            })
          );
          throw new Error('No callback and next route found in enter pin page!');
        }

        dispatch(setLoading(true));

        console.log('checking session in enter pin page...'); // for debug

        console.log('client check session', client);
        const result = await client.invoke(new Api.account.GetPassword());
        console.log('result check session (password class)', result); // for debug purposes

        // set hint
        setHint(result?.hint);
        dispatch(setLoading(false));
      } catch (error: any) {
        dispatch(setLoading(false));
        console.log('err while check session in enter pin page: ', error, typeof error);
        // eslint-disable-next-line eqeqeq
        console.log('err mes: ', error?.message);
        console.log('type err: ', typeof error?.message);
        console.log('err code: ', error?.code);

        // handle custom errors
        if (error?.toString()?.includes('found in enter pin page')) {
          handleBack();
        }

        // handle disconnected
        if (
          error?.message ===
            'Cannot send requests while disconnected. You need to call .connect()' ||
          error?.message === 'Error: Disconnect (caused from InvokeWithLayer)'
        ) {
          console.log('disconnected while checking session');
          // alert('Network disconnected!');
          setShowModalDisconnectedCheckingSession(true);
          // navigate(PATH_HOME);
        }

        // hanle session expired
        if (
          error?.message === '401: AUTH_KEY_UNREGISTERED (caused by account.GetPassword)' ||
          error?.message === '401: SESSION_REVOKED (caused by account.GetPassword)'
        ) {
          console.log('session expired');
          // alert('Session expired! Please login again.');
          dispatch(setSession('')); // clear session (main) in cache
          // clear session on server
          if (user.id) {
            await appApi.updateSessionTele('', user.id);
          }
          setShowModalSessionExpired(true);
        }
      }
    },
    [client, callBack, nextRoute]
  );

  const handleBackHome = () => {
    try {
      navigate(PATH_HOME, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBack = () => {
    try {
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModalDisconnected = () => {
    setShowModalDisconnected(false);
  };

  const handleToLoginPage = () => {
    navigate(`/${PATH_VERIFY_CODE}`);
  };

  // effects
  useEffect(() => {
    console.log('session: ', session); // for debug
    console.log('next route: ', nextRoute); // for debug
    console.log('call back: ', callBack); // for debug
    checkSession(session);

    // unmount
    return () => {
      console.log('enter pin unmount...'); // for debug
      dispatch(setLoading(false));
      dispatch(setRouteAfterEnterPin('')); // clear the route after enter pin
      dispatch(setCallbackAfterEnterPin(undefined)); // clear the callback after enter pin
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, client]);

  return (
    <StyledEnterPin>
      <form onSubmit={handleSubmit(submitPin)} autoComplete="off">
        <div className="space" />
        <Typography className={classes.title}>Enter 2FA Password</Typography>
        <InputPassword
          label="Enter your 2FA password"
          name="password"
          error={errors.password}
          register={register('password')}
          autoComplete="off"
          className={classes.mb_24}
          maxLength={32}
        />
        {hint ? <p className={classes.hint}>Password hint: {hint} </p> : null}

        {query_id ? <p className={classes.hint}>{query_id}</p> : null}

        <div className={classes.buttonWrapper}>
          <Button
            className={classes.sendButton}
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!watch('password')}
          >
            Continue
          </Button>
        </div>

        {/* modal disconnected */}
        <Modal open={showModalDisconnected} keepMounted>
          <Box sx={styleModal}>
            <Box sx={styleModal.content}>
              Something went wrong, please check your connection then try again.
            </Box>
            <Box sx={styleModal.btn}>
              <Button
                style={{ width: '100%', background: '#F4F4F5', borderRadius: 0 }}
                onClick={handleBackHome}
              >
                Cancel
              </Button>
              <Button
                style={{ width: '100%', background: '#6369FF', borderRadius: 0, color: '#ffffff' }}
                onClick={handleCloseModalDisconnected}
              >
                Retry
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* modal session expired */}
        <Modal open={showModalSessionExpired} keepMounted>
          <Box sx={styleModal}>
            <Box sx={styleModal.content}>Session expired. Please try login again.</Box>
            <Box sx={styleModal.btn}>
              <Button
                style={{ width: '100%', background: '#6369FF', borderRadius: 0, color: '#ffffff' }}
                onClick={handleToLoginPage}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* modal disconnect while checking session */}
        <Modal open={showModalDisconnectedCheckingSession} keepMounted>
          <Box sx={styleModal}>
            <Box sx={styleModal.content}>
              Something went wrong, please check your connection then try again.
            </Box>
            <Box sx={styleModal.btn}>
              <Button
                style={{ width: '100%', background: '#F4F4F5', borderRadius: 0 }}
                onClick={handleBackHome}
              >
                Cancel
              </Button>
              <Button
                style={{ width: '100%', background: '#6369FF', borderRadius: 0, color: '#ffffff' }}
                onClick={() => {
                  checkSession(session);
                }}
              >
                Retry
              </Button>
            </Box>
          </Box>
        </Modal>
      </form>
    </StyledEnterPin>
  );
}

const StyledEnterPin = styled.div`
  margin-bottom: 80vh;
  padding: 0 16px;
  padding-top: 137px;
`;
