import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Button } from '@material-ui/core';
import styled from 'styled-components';

import { IOTPCodePhone, IOTPCodeEmail, IAccountContact } from '@interfaces';
import { Input, Breadcrumb } from '@components';
import { adminApi } from '@api';
import {
  useAppDispatch,
  setLoading,
  useAppSelector,
  selectUser,
  openToast,
  setIsVerify,
} from '@redux';

export function VerifyContactPage() {
  const dispatch = useAppDispatch();

  const otpPhoneSchema = Yup.object().shape({
    otp_code_phone: Yup.string()
      .trim()
      .required('The OTP field is required')
      .matches(/^[0-9]+$/, 'The OTP field must be only digits')
      .min(6, 'The OTP field must be contain 6 characters')
      .max(6, 'The OTP field must be contain 6 characters'),
  });

  const otpEmailSchema = Yup.object().shape({
    otp_code_email: Yup.string()
      .trim()
      .required('The OTP field is required')
      .matches(/^[0-9]+$/, 'The OTP field must be only digits')
      .min(6, 'The OTP field must be contain 6 characters')
      .max(6, 'The OTP field must be contain 6 characters'),
  });

  const [phone, setPhone] = useState<string>('');

  const [email, setEmail] = useState<string>('');

  const [isVerifyPhone, setIsVerifyPhone] = useState<boolean>(false);

  const [isVerifyEmail, setIsVerifyEmail] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<IOTPCodePhone>({
    mode: 'onTouched',
    resolver: yupResolver(otpPhoneSchema),
  });

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail, isValid: isValidEmail },
    setValue: setValueEmail,
    watch: watchEmail,
  } = useForm<IOTPCodeEmail>({
    mode: 'onTouched',
    resolver: yupResolver(otpEmailSchema),
  });

  const submitOTPPhone = (formData: IOTPCodePhone) => {
    checkVerifyOtp('PHONE', formData.otp_code_phone || '');
  };

  const submitOTPEmail = (formData: IOTPCodeEmail) => {
    checkVerifyOtp('EMAIL', formData.otp_code_email || '');
  };

  // const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPhone(event.target.value);
  // };

  // const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmail(event.target.value);
  // };

  const user = useAppSelector(selectUser);

  const getAccountContact = async () => {
    try {
      dispatch(setLoading(true));
      const res = await adminApi.getListAccountContact(user.user_tele_id);
      console.log(res?.data?.data);
      const listAccountContact: Array<IAccountContact> = res?.data?.data;
      let is_verify = false;
      listAccountContact.forEach((item: IAccountContact) => {
        if (item?.type === 'PHONE') {
          setPhone(item?.contact);
          setIsVerifyPhone(item?.is_verify);
          if (!is_verify && item?.is_verify) is_verify = true;
        } else if (item?.type === 'EMAIL') {
          setEmail(item?.contact);
          setIsVerifyEmail(item?.is_verify);
          if (!is_verify && item?.is_verify) is_verify = true;
        }
      });
      dispatch(setIsVerify(is_verify));
    } catch (error: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getAccountContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSentOtpPhone = async () => {
    try {
      dispatch(setLoading(true));
      const res = await adminApi.sentOtpCode(Number(user?.user_tele_id), 'PHONE');
      dispatch(
        openToast({
          message: res?.data?.data?.message,
          type: 'success',
        })
      );
    } catch (error: any) {
      console.log(error.response);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSentOtpEmail = async () => {
    try {
      dispatch(setLoading(true));
      const res = await adminApi.sentOtpCode(Number(user?.user_tele_id), 'EMAIL');
      dispatch(
        openToast({
          message: res?.data?.data?.message,
          type: 'success',
        })
      );
    } catch (error: any) {
      console.log(error.response);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const checkVerifyOtp = async (type: 'PHONE' | 'EMAIL', code: string) => {
    try {
      dispatch(setLoading(true));
      const res = await adminApi.verifyOtpCode(Number(user?.user_tele_id), type, code);
      console.log(res?.data);
      dispatch(
        openToast({
          message: res?.data?.data?.message,
          type: 'success',
        })
      );
      getAccountContact();
    } catch (error: any) {
      console.log(error.response);
      dispatch(
        openToast({
          message: error?.response?.data?.message,
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <StyledVerifyContact>
      <Container>
        <Breadcrumb />
        <form onSubmit={handleSubmit(submitOTPPhone)}>
          <div className="form_title">Mobile Number</div>
          {!isVerifyPhone ? (
            <>
              <div className="row mb-24">
                <div className="input_wrapper">
                  <Input
                    className="input"
                    label="Your mobile number"
                    name="phone"
                    type="text"
                    value={phone}
                    disabled={!!phone}
                    // onChange={handleChangePhone}
                  />
                  <div className="un_verify">(Unverified)</div>
                </div>
                <Button variant="contained" className="btn-blue" onClick={handleSentOtpPhone}>
                  Send OTP
                </Button>
              </div>
              <div className="row mb-24">
                <div className="input_wrapper">
                  <Input
                    className="input"
                    label="Enter OTP"
                    name="otp_code_phone"
                    error={errors.otp_code_phone}
                    type="number"
                    register={register('otp_code_phone')}
                    // type="text"
                    // onChange={(e) => {
                    //   const res = e?.target?.value?.replaceAll('e', '')?.replace(/[^0-9.]/g, '');
                    //   setValue('otp_code_phone', res, {
                    //     shouldValidate: true,
                    //   });
                    // }}
                    // value={watch('otp_code_phone')}
                  />
                </div>
                <Button variant="contained" className="btn-blue" disabled={!isValid} type="submit">
                  Verify
                </Button>
              </div>
            </>
          ) : (
            <div className="verified">
              <div className="text">
                {phone}
                <span>(Verified)</span>
              </div>
              {/* <IconCircleCheck /> */}
            </div>
          )}
        </form>
        <div className="divide" />
        <form onSubmit={handleSubmitEmail(submitOTPEmail)} className="mt-24">
          <div className="form_title">Email</div>
          {!isVerifyEmail ? (
            <>
              <div className="row mb-24">
                <div className="input_wrapper">
                  <Input
                    className="input"
                    label="Your email address"
                    name="email"
                    type="text"
                    value={email}
                    disabled={!!email}
                    // onChange={handleChangeEmail}
                  />
                  <div className="un_verify">(Unverified)</div>
                </div>
                <Button variant="contained" className="btn-blue" onClick={handleSentOtpEmail}>
                  Send OTP
                </Button>
              </div>
              <div className="row mb-24">
                <div className="input_wrapper">
                  <Input
                    className="input"
                    label="Enter OTP"
                    name="otp_code_email"
                    error={errorsEmail.otp_code_email}
                    type="number"
                    register={registerEmail('otp_code_email')}
                    // type="text"
                    // onChange={(e) => {
                    //   const res = e?.target?.value?.replaceAll('e', '')?.replace(/[^0-9.]/g, '');
                    //   setValueEmail('otp_code_email', res, {
                    //     shouldValidate: true,
                    //   });
                    // }}
                    // value={watchEmail('otp_code_email')}
                  />
                </div>
                <Button
                  variant="contained"
                  className="btn-blue"
                  disabled={!isValidEmail}
                  type="submit"
                >
                  Verify
                </Button>
              </div>
            </>
          ) : (
            <div className="verified">
              <div className="text">
                {email}
                <span>(Verified)</span>
              </div>
            </div>
          )}
        </form>
      </Container>
    </StyledVerifyContact>
  );
}

const StyledVerifyContact = styled.div`
  form {
    margin-top: 36px;
    &.mt-24 {
      margin-top: 24px;
    }
  }
  .divide {
    height: 1px;
    width: 100%;
    background-color: #eeedf5;
  }
  .form_title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .mb-24 {
    margin-bottom: 24px;
    &.row_item {
      width: 50%;
    }
  }
  .row {
    display: grid;
    /* grid-row-gap: 24px; */
    grid-column-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    .input_wrapper {
      grid-column: span 3 / span 3;
      position: relative;
      .un_verify {
        position: absolute;
        top: 50%;
        right: 16px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #dc0000;
        transform: translateY(-50%);
        background-color: #f3f2f8;
        padding: 4px;
      }
    }
    .MuiButton-label {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
    }
    .MuiButton-contained.Mui-disabled {
      background-color: #8f8f8f !important;
    }
  }
  .verified {
    background-color: rgba(10, 132, 255, 0.11);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    padding-left: 18px;
    margin-bottom: 24px;
    .text {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      span {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #0a84ff;
        margin-left: 6px;
      }
    }
  }
`;
