import React from 'react';

export function IconMessage() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#FFCF67" />
      <path
        d="M20.9915 18.3334V21.725C20.9915 22.025 20.9582 22.3084 20.8832 22.5667C20.5748 23.7917 19.5582 24.5584 18.1582 24.5584H15.8915L13.3748 26.2334C12.9998 26.4917 12.4998 26.2167 12.4998 25.7667V24.5584C11.6498 24.5584 10.9415 24.275 10.4498 23.7834C9.94984 23.2834 9.6665 22.575 9.6665 21.725V18.3334C9.6665 16.75 10.6498 15.6584 12.1665 15.5167C12.2748 15.5084 12.3832 15.5 12.4998 15.5H18.1582C19.8582 15.5 20.9915 16.6334 20.9915 18.3334Z"
        fill="white"
      />
      <path
        d="M22.7915 21C23.8498 21 24.7415 20.65 25.3582 20.025C25.9832 19.4084 26.3332 18.5167 26.3332 17.4584V13.2084C26.3332 11.25 24.7498 9.6667 22.7915 9.6667H15.7082C13.7498 9.6667 12.1665 11.25 12.1665 13.2084V13.8334C12.1665 14.0667 12.3498 14.25 12.5832 14.25H18.1582C20.4165 14.25 22.2415 16.075 22.2415 18.3334V20.5834C22.2415 20.8167 22.4248 21 22.6582 21H22.7915Z"
        fill="white"
      />
    </svg>
  );
}
