import axiosClient from './axiosClient';
import { ICreatePayDataRequest, IQRReceiveRequestData } from '@/interfaces';

export const qrCodeApi = {
  createPaymentQRcode: (params: IQRReceiveRequestData) => {
    const url = `/api/payment/create-data-qr-code`;

    return axiosClient.post(url, params);
  },
  getPaymentQRcode: (hashData: string, payer_tele_id: number) => {
    const url = `/api/payment/get-data-qr-code/${hashData}/${payer_tele_id}`;

    return axiosClient.get(url);
  },
  getPaymentQRcodeMerchant: (receiver_trxn_ref: string, payer_tele_id: number) => {
    const url = `/api/payment/get-data-merch-qr-code/${receiver_trxn_ref}/${payer_tele_id}`;

    return axiosClient.get(url);
  },
  createPayDataQRcode: (params: ICreatePayDataRequest) => {
    const url = `/api/payment/create-data-pay-qr-code`;

    return axiosClient.post(url, params);
  },
  createPayDataQRcodeMerchant: (params: ICreatePayDataRequest) => {
    const url = `/api/payment/pay-qr-invoce`;

    return axiosClient.post(url, params);
  },
};
