import React from 'react';

export function IconTransfer() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#8AED88" />
      <path
        d="M21.4915 9.6667H14.5082C11.4748 9.6667 9.6665 11.475 9.6665 14.5084V21.4834C9.6665 24.525 11.4748 26.3334 14.5082 26.3334H21.4832C24.5165 26.3334 26.3248 24.525 26.3248 21.4917V14.5084C26.3332 11.475 24.5248 9.6667 21.4915 9.6667ZM15.1998 18.675L18.8498 15.025H16.8248C16.4832 15.025 16.1998 14.7417 16.1998 14.4C16.1998 14.0584 16.4832 13.775 16.8248 13.775H20.3582C20.4415 13.775 20.5165 13.7917 20.5998 13.825C20.7498 13.8917 20.8748 14.0084 20.9415 14.1667C20.9748 14.2417 20.9915 14.325 20.9915 14.4084V17.9417C20.9915 18.2834 20.7082 18.5667 20.3665 18.5667C20.0248 18.5667 19.7415 18.2834 19.7415 17.9417V15.9167L16.0832 19.5584C15.9582 19.6834 15.7998 19.7417 15.6415 19.7417C15.4832 19.7417 15.3248 19.6834 15.1998 19.5584C14.9582 19.3167 14.9582 18.9167 15.1998 18.675ZM23.1998 22.35C21.5248 22.9084 19.7665 23.1917 17.9998 23.1917C16.2332 23.1917 14.4748 22.9084 12.7998 22.35C12.4748 22.2417 12.2998 21.8834 12.4082 21.5584C12.5165 21.2334 12.8665 21.05 13.1998 21.1667C16.2998 22.2 19.7082 22.2 22.8082 21.1667C23.1332 21.0584 23.4915 21.2334 23.5998 21.5584C23.6998 21.8917 23.5248 22.2417 23.1998 22.35Z"
        fill="white"
      />
    </svg>
  );
}
