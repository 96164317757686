import React from 'react';

export function RecoverAccount() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#5FE8BF" />
      <path
        d="M13.275 17.21C13.11 17.21 12.955 17.1 12.91 16.93C12.86 16.73 12.975 16.525 13.175 16.47C15.205 15.935 16.62 14.095 16.62 11.995C16.62 9.445 14.545 7.37 11.995 7.37C9.83 7.37 8.41 8.635 7.745 9.4H9.215C9.42 9.4 9.59 9.57 9.59 9.775C9.59 9.98 9.43 10.155 9.22 10.155H7.005C6.97 10.155 6.935 10.15 6.9 10.14C6.855 10.125 6.815 10.105 6.78 10.08C6.735 10.05 6.7 10.01 6.675 9.965C6.65 9.915 6.63 9.865 6.625 9.81C6.625 9.795 6.625 9.785 6.625 9.77V7.5C6.625 7.295 6.795 7.125 7 7.125C7.205 7.125 7.375 7.295 7.375 7.5V8.695C8.19 7.82 9.725 6.625 12 6.625C14.965 6.625 17.375 9.035 17.375 12C17.375 14.44 15.73 16.58 13.37 17.2C13.34 17.205 13.305 17.21 13.275 17.21Z"
        fill="white"
      />
      <path
        d="M11.645 17.365C11.635 17.365 11.62 17.365 11.61 17.36C11.075 17.325 10.55 17.205 10.05 17.01C9.905 16.955 9.81 16.81 9.81 16.655C9.81 16.61 9.82 16.565 9.835 16.525C9.91 16.335 10.13 16.235 10.32 16.31C10.75 16.48 11.205 16.58 11.665 16.615H11.67C11.87 16.625 12.02 16.795 12.02 16.995C12.02 17 12.02 17.01 12.02 17.015C12.005 17.21 11.84 17.365 11.645 17.365ZM8.89 16.29C8.805 16.29 8.725 16.265 8.655 16.21C8.235 15.875 7.87 15.48 7.565 15.035C7.52 14.97 7.495 14.9 7.495 14.825C7.495 14.7 7.555 14.585 7.66 14.515C7.825 14.4 8.07 14.445 8.18 14.61C8.445 14.995 8.76 15.335 9.125 15.62C9.21 15.69 9.265 15.795 9.265 15.91C9.265 15.995 9.24 16.08 9.185 16.15C9.115 16.24 9.005 16.29 8.89 16.29ZM7.22 13.85C7.055 13.85 6.91 13.745 6.865 13.59C6.705 13.075 6.625 12.54 6.625 12C6.625 11.795 6.795 11.625 7 11.625C7.205 11.625 7.375 11.795 7.375 12C7.375 12.465 7.445 12.925 7.58 13.365C7.59 13.4 7.595 13.44 7.595 13.48C7.595 13.645 7.49 13.785 7.335 13.835C7.295 13.845 7.26 13.85 7.22 13.85Z"
        fill="white"
      />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="white"
      />
    </svg>
  );
}
