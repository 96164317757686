import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import dayjs from 'dayjs';
import {
  BalanceComponent,
  IconQRPay,
  IconQRReceive,
  IconQRShare,
  ModalConfirm,
} from '@/components';
import { PATH_QR_RECEIVE, PATH_QR_SCANNER, PATH_QR_SHARE } from '@/router';
import { convertError, formatCurrency } from '@/libs/helper';
import {
  useAppSelector,
  useAppDispatch,
  selectUser,
  setLoading,
  openToast,
  selectPaymentInfo,
  setPaymentInfo,
} from '@/redux';
import { qrCodeApi } from '@/api';
import { ICreatePayDataRequest } from '@/interfaces';
import { telegramClient, TypeQrEnum } from '@/configs';
import { useBalance } from '@/hooks';

export function QRCodePage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const paymentInfo = useAppSelector(selectPaymentInfo);

  const trigger = useRef<HTMLButtonElement>(null);

  const qrMenuItems = [
    {
      text: 'QR Pay',
      id: 1,
      path: PATH_QR_SCANNER,
      icon: <IconQRPay />,
    },
    {
      text: 'QR Receive',
      id: 2,
      path: PATH_QR_RECEIVE,
      icon: <IconQRReceive />,
    },
    // {
    //   text: 'QR Share',
    //   id: 3,
    //   path: PATH_QR_SHARE,
    //   icon: <IconQRShare />,
    // },
  ];

  useEffect(() => {
    if (trigger?.current) {
      if (paymentInfo?.amount || paymentInfo?.gross_amount) {
        trigger.current.click();
      }
    }
  }, [paymentInfo]);

  useEffect(() => {
    console.log('redux state => paymentInfo: ', paymentInfo); // for debug
  }, [paymentInfo]);

  const createPayData = async () => {
    const params: ICreatePayDataRequest = {
      receiver_trxn_ref: paymentInfo?.my_trxn_ref || '',
      payer_user_id: Number(user?.user_tele_id),
      auth_date:
        telegramClient?.initDataUnsafe?.auth_date || dayjs(new Date()).valueOf().toString(),
    };
    console.log('params create qr pay data: ', params); // for debug

    try {
      dispatch(setLoading(true));

      if (paymentInfo?.trxn_type === TypeQrEnum?.TRANSFER) {
        const res = await qrCodeApi.createPayDataQRcode(params);
        console.log('create pay response => transfer: ', res?.data);
      }

      if (paymentInfo?.trxn_type === TypeQrEnum?.INVOICE) {
        const res = await qrCodeApi.createPayDataQRcodeMerchant(params);
        console.log('create pay response => transfer: ', res?.data);
      }

      dispatch(openToast({ message: 'Payment completed successfully.', type: 'success' }));
      console.log('getBalanceMethod', getBalance);
      getBalance();
    } catch (error: any) {
      dispatch(openToast({ message: convertError(error?.response?.data), type: 'error' }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const { getBalance } = useBalance(user?.user_tele_id);

  // main return
  return (
    <StyledQRCodePage>
      <div className="qr_code mb-24">QR Code</div>
      <BalanceComponent />
      <div className="qr_menu">
        {qrMenuItems.map((item) => (
          <div className="qr_item" key={item.id} onClick={() => navigate(`/${item.path}`)}>
            {item.icon}
            <div className="qr_item_text">{item.text}</div>
          </div>
        ))}
      </div>
      <ModalConfirm
        isDone={!paymentInfo?.amount}
        className="w-full mr-16"
        // isDone={mutationDeletePayId.isSuccess || mutationDeletePayId.isError}
        title="QR Pay Confirm"
        triggerButton={
          <Button ref={trigger} className="hidden" variant="contained">
            Popup
          </Button>
        }
        onClickCancelButton={(close: any) => {
          close();
          dispatch(setPaymentInfo({}));
        }}
        ModalProps={{ maxWidth: 'md' }}
        confirmButton={
          <Button
            variant="contained"
            sx={{
              bgcolor: '#6369FF',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '13px',
              lineHeight: '16px',
              textTransform: 'initial',
              minWidth: '127px',
              py: '10px',
              borderColor: 'transparent',
              '&:hover': {
                opacity: 0.8,
                bgcolor: '#6369FF',
              },
            }}
            onClick={async () => {
              // need update payment information
              await createPayData();
              dispatch(setPaymentInfo({}));
            }}
          >
            Yes
          </Button>
        }
      >
        <Box sx={{ marginBottom: '4px', textAlign: 'center' }}>
          Pay{' '}
          <Box component="span" sx={{ fontWeight: '600' }}>
            {formatCurrency(Number(paymentInfo?.amount))}{' '}
          </Box>
          {`to ${paymentInfo?.receiver}`}
        </Box>
      </ModalConfirm>
    </StyledQRCodePage>
  );
}

const StyledQRCodePage = styled.div`
  padding: 32px 16px 0 16px;
  .qr_code {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }
  .qr_menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
  }
  .qr_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #e9e9e9;
    cursor: pointer;
    .qr_item_text {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      opacity: 0.8;
      margin-top: 8px;
    }
    &:last-child {
      border: none;
    }
  }
  .w-full {
    width: 100%;
  }
  .mr-16 {
    margin-right: 16px;
  }
  .hidden {
    display: none;
  }
`;
