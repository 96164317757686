/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IContacts } from '@pages';
import Button from '@material-ui/core/Button';
import {
  BasicDatePicker,
  BreadcrumbStep,
  IconCoinNoBackground,
  Input,
  MainButton,
} from '@/components';
import { selectUser, useAppSelector } from '@/redux';
import { useBalance } from '@/hooks';
import { formatCurrency } from '@/libs/helper';

type IProps = {
  errors: any;
  register: any;
  setValue: any;
  pay_by_val?: string;
  amount_val: string;
  description_val: string;
  isActive: boolean;
  contact_name?: string;
  feature: 'RP' | 'PA'; // request payment OR pay anyone
};

// final step

export const StepFour = (props: IProps) => {
  const {
    errors,
    register,
    setValue,
    pay_by_val,
    amount_val,
    description_val,
    isActive,
    contact_name,
    feature,
  } = props;

  useEffect(() => {
    console.log('errors: ', errors); // for debug
  }, [errors]);

  const user = useAppSelector(selectUser);

  const { balance } = useBalance(user?.user_tele_id);

  // main return
  return (
    <div className={isActive ? 'active' : 'inactive'}>
      <BreadcrumbStep />
      {/* <div className="sub_title">Confirm Your Request</div> */}
      <div className="balance_container">
        <div className="title">Available Balance</div>
        <div className="balance">AUD: {formatCurrency(Number(balance) || 0)}</div>
      </div>
      <Input
        className="mb-24"
        label="Amount"
        // name="amount"
        // register={register('amount')}
        error={errors.amount}
        // preIcon={<IconCoinNoBackground />}
        disabled
        value={formatCurrency(Number(amount_val))}
      />

      <Input
        className="mb-24"
        label="Description"
        // name="description"
        // register={register('description')}
        error={errors.description}
        disabled
        value={description_val}
      />
      {feature === 'RP' ? (
        <BasicDatePicker
          className="mb-24"
          label="Pay by"
          name="pay_by"
          // handleChange={(date) => {
          //   const dateChange = date?.format('MM/DD/YYYY');
          //   console.log('date: ', dateChange, typeof dateChange); // for debug
          //   setValue('pay_by', dateChange);
          // }}
          //   value={new Date(watch('pay_by'))}
          value={pay_by_val ? new Date(pay_by_val) : new Date()}
          error={errors.pay_by?.message}
          disablePast
          disable
        />
      ) : null}

      <Input
        className="mb-24"
        label="Payee"
        // name="description"
        // register={register('description')}
        // error={errors.payer_user_id}
        disabled
        value={contact_name}
        shrink
        // value="hieu"
      />

      <Button type="submit" className="w_full btn_submit">
        {feature === 'RP' ? 'Send Request' : 'Pay'}
      </Button>

      {/* <MainButton text="Send" type="submit" /> */}
    </div>
  );
};
