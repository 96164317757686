/* eslint-disable react-hooks/exhaustive-deps */
import { BalanceComponent, MainButton, SeacrhTrxn } from '@components';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PATH_TRXN_HISTORY } from '@/router';
import { openToast, selectUser, setLoading, useAppSelector } from '@/redux';
import { IItemTrxnHistory, IResponseTrxnHistory } from '@/interfaces';
import { adminApi } from '@/api';
import { ItemTrxnHistory } from './widget';

export const TrxnHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux states
  const user_tele_id_redux = useAppSelector(selectUser)?.user_tele_id;

  // local states
  const [listItemsTrxn, setListItemsTrxn] = useState<Array<IItemTrxnHistory>>([]);

  // local states for pagination
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(15);
  const [totalPage, setTotalPage] = useState<number>(0);

  // functions
  const getListTrxnHistory = async (user_tele_id: number | string, page: number, limit: number) => {
    try {
      if (!user_tele_id) throw new Error('No user tele ID was provided!');
      if (!page) throw new Error('No page was provided!');
      if (!limit) throw new Error('No limit was provided!');

      dispatch(setLoading(true));

      const res = await adminApi?.getListTrxnHistory(user_tele_id, page, limit);
      const response: IResponseTrxnHistory = res?.data?.data;

      const items = response?.items;

      const newData = listItemsTrxn.concat(items);
      setListItemsTrxn(newData);
      setTotalPage(response?.meta?.totalPages);

      console.log('res get list order: ', res?.data);
    } catch (error: any) {
      console.log(error);
      dispatch(
        openToast({
          message:
            error?.response?.data?.message ||
            error?.message ||
            'Something went wrong, please try again',
          type: 'error',
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const hanleNext = () => {
    const newPage = page + 1;

    if (newPage <= totalPage) {
      setPage(newPage);
    }
  };

  const handleClickSearch = () => {
    navigate(`/${PATH_TRXN_HISTORY}/search`);
  };

  // effects
  useEffect(() => {
    getListTrxnHistory(user_tele_id_redux, page, limit);
  }, [page, limit]);

  useEffect(() => {
    console.log('local state => listItemsTrxn:', listItemsTrxn); // for debug
  }, [listItemsTrxn]);

  // main return
  return (
    <StyledStartPage>
      <BalanceComponent />

      <div className="sub_title">Trxn History</div>
      <InfiniteScroll
        dataLength={listItemsTrxn?.length}
        next={hanleNext}
        hasMore
        loader={undefined}
      >
        {listItemsTrxn?.length
          ? listItemsTrxn?.map((item) => {
              return <ItemTrxnHistory item={item} key={`${item?.id}-${item?.my_trxn_ref}`} />;
            })
          : null}
      </InfiniteScroll>

      <MainButton
        text="Search Transactions"
        type="button"
        preIcon={<SeacrhTrxn />}
        onClick={handleClickSearch}
      />
    </StyledStartPage>
  );
};

const StyledStartPage = styled.div`
  padding: 23px 16px 0 16px;
  margin-bottom: 72px;

  .sub_title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;
